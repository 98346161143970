import React from 'react'
import FlexView from 'react-flexview/lib'
import { Grow, IconButton } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import NewFillButton from '../Core/NewFillButton'
import { useLocation } from 'react-router-dom'

export const OdometerPermissionError = () => {
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 200)
  }, [])

  return (
    <FlexView column className="priview-background">
      <FlexView className="priview-background2" hAlignContent={'center'}>
        <FlexView column className="preview-content">
          <Grow in={show} style={{ transformOrigin: '0 0 0' }} {...(show ? { timeout: 1000 } : {})}>
            <FlexView hAlignContent={'center'} column vAlignContent={'center'} className="preview-title-top">
              <ErrorOutlineIcon className="white x-large-icon clickable margin-bottom-basic" />
              <div className="text-title white textcenter margin-bottom-basic">
                Please enable the Camera Permission in order to take a picture.
              </div>
              <div className="text-body white textcenter margin-bottom-basic">
                Click the Camera icon next to the address section or through the browser settings.
              </div>
              <div className="text-body white textcenter margin-bottom-basic">Afterward click the refresh button below</div>
              <NewFillButton green onClick={() => window.location.reload(false)}>
                Refresh
              </NewFillButton>
              <br />
              <br />
              <img className="logo-fit" src={process.env.PUBLIC_URL + '/assets/voom_white.png'} />
            </FlexView>
          </Grow>
        </FlexView>
      </FlexView>
    </FlexView>
  )
}
