import React, { useState } from 'react'
import { Grid, InputAdornment, makeStyles, useMediaQuery } from '@material-ui/core'
import FlexView from 'react-flexview'
import { RolloverTextField } from './RolloverTextField'
import { Controller, FormContext, useForm, useFormContext } from 'react-hook-form'
import { FirebaseConfigKey, FirebaseService } from '../../../services/FirebaseService'
import { DatePicker } from '@material-ui/pickers'
import { generalPhoneNumberRegex, isOver18 } from '../../../services/utils'
import EventIcon from '@material-ui/icons/Event'
import LocalizationService from '../../../services/LocalizationService'
import { EMAIL_REGEX } from '../../../Constants'
import ClipboardIcon from '../../../assets/clipboardIcon'
import PhoneIcon from '../../../assets/phoneIcon'
import EmailIcon from '../../../assets/emailIcon'
import ExclamationMarkIcon from '../../../assets/exclamationMark'
import CalendarDateIcon from '../../../assets/calendarDate'
import OtpInput from 'react-otp-input'
import './MarkelLogin.scss'
import './FormsSections.scss'

const PHONE_NUMBER_PATTERN = '(_ _ _) - _ _ _ - _ _ _ _'
const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9]*$/
const useStyles = makeStyles(theme => ({
  root: {
    background: 'white',
    borderRadius: '8px',
    border: '1px',
    borderColor: '#D0D5DD !important',
    color: '#667085',
    fontWeight: 'normal',
    '&:hover': {
      borderColor: '#D0D5DD !important',
    },
    '&$focused': {
      backgroundColor: '#fff',
    },
  },
}))

//login fornm
export const LoginInputForm = ({ onSubmitForm, errorObj, selectedOption }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const form = useForm({ mode: 'onSubmit', defaultValues: {} })

  const onMarkelLogin = data => {
    onSubmitForm(data)
  }

  return (
    <Grid container className=" font-semibold text-sm margin-top-basic">
      {(errorObj.title || errorObj.message) && <ErrorComponent errorObj={errorObj} />}
      <Grid item xs={12} className="margin-bottom-24">
        <FormContext {...form}>
          <form autoComplete="off" onSubmit={form.handleSubmit(onMarkelLogin)}>
            <Grid container>
              <LoginInputsSection selectedOption={selectedOption} />

              <Grid item xs={12}>
                <button className="submit-button font-semibold text-sm clickable" type="submit">
                  Get a Quote
                </button>
              </Grid>
            </Grid>
          </form>
        </FormContext>
      </Grid>
    </Grid>
  )
}

const ErrorComponent = ({ errorObj }) => {
  return (
    <Grid item xs={12}>
      <div className="error-container margin-bottom-24">
        <FlexView className="error-title font-semibold text-sm " hAlignContent="left" vAlignContent="center">
          <ExclamationMarkIcon stroke="#CB4444" strokeWidth="1.25" className="margin-right-6" />{' '}
          {errorObj.title != null ? errorObj.title : 'We couldn’t sign you in'}
        </FlexView>
        {errorObj.message && (
          <FlexView className="text-xs font-normal error-message" hAlignContent="left" vAlignContent="center">
            {errorObj.message}
          </FlexView>
        )}
      </div>
    </Grid>
  )
}

const formatPhoneNumber = phoneNumber => {
  phoneNumber = phoneNumber.replace('+', '')

  let areaCode = phoneNumber.slice(0, 3)
  let restOfNumber = phoneNumber.slice(3)

  return `(${areaCode})-${restOfNumber}`
}

export const VerificationCodeForm = ({ onSubmitForm, errorObj, onGoBack, currPhoneNumber, onResend }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const [otp, setOtp] = useState('')

  const onResendCode = () => {
    onResend({ phone_number: currPhoneNumber })
  }

  const onSubmit = data => {
    onSubmitForm(data)
  }

  return (
    <Grid item xs={12} className="margin-bottom-24">
      <FlexView hAlignContent="left" vAlignContent="top" className=" text-sm padding-bottom-24">
        <div>
          Enter the verification code we sent to {formatPhoneNumber(currPhoneNumber)}. Didn't receive it?{' '}
          <span onClick={onResendCode} className="font-semibold underline-text">
            Click to Resend
          </span>
        </div>
      </FlexView>
      {errorObj && (errorObj.title || errorObj.message) && <ErrorComponent errorObj={errorObj} />}
      <Grid container className="font-normal">
        <Grid item xs={12} className="margin-bottom-16">
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            isInputNum={true}
            inputType="number"
            renderInput={props => <input {...props} />}
            skipDefaultStyles={true}
            inputStyle={{
              borderRadius: '8px',
              border: '1px solid #424242',
              background: '#fff',
              width: '16%',
              height: '50px',
              fontSize: '12px',
              color: '#000',
              fontWeight: '400',
              caretColor: 'transparent',
              textAlign: 'center',
            }}
            containerStyle={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px',
            }}
          />
        </Grid>

        <Grid item xs={12} className="margin-top-8">
          <button className="submit-button font-semibold text-sm clickable" onClick={() => onSubmit({ code: otp })}>
            Continue to Quote
          </button>
        </Grid>
        {!isMobile && (
          <Grid item xs={12} className="margin-top-8">
            <button className="go-back-button font-semibold text-sm clickable" onClick={onGoBack}>
              Go back
            </button>
          </Grid>
        )}
      </Grid>
      {/* </form> */}
    </Grid>
  )
}

const LoginInputsSection = ({ selectedOption }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const methods = useFormContext()
  const classes = useStyles()
  const validatePhoneNumber = phoneNumber => {
    console.log(phoneNumber)
    return !phoneNumber || generalPhoneNumberRegex.test(phoneNumber)
  }

  return (
    <Grid container className="font-normal margin-bottom-8">
      {selectedOption === 'email' && (
        <Grid item xs={12} className="margin-bottom-16">
          <RolloverTextField
            fullWidth
            name="email"
            label={'Email address'}
            register={methods.register}
            errors={methods.errors}
            helperText={!!methods.errors.email && (methods.errors.email.type == 'required' ? 'Required' : 'Invalid email address')}
            inputRef={methods.register({ required: true, pattern: { value: EMAIL_REGEX, message: 'Invalid email address' } })}
            inputlab
            InputProps={{
              classes,
              endAdornment: (
                <InputAdornment position="end">
                  <EmailIcon className="icon-gray" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      )}
      {selectedOption === 'phone' && (
        <Grid item xs={12} className="margin-bottom-16">
          <RolloverTextField
            fullWidth
            placeholder={PHONE_NUMBER_PATTERN}
            name="phone_number"
            label={'Phone number'}
            register={methods.register}
            errors={methods.errors}
            helperText={
              !!methods.errors.phone_number && (methods.errors.phone_number.type == 'required' ? 'Required' : 'Invalid phone number')
            }
            inputRef={methods.register({ required: true, validate: validatePhoneNumber })}
            inputlab
            InputProps={{
              classes,
              endAdornment: (
                <InputAdornment position="end">
                  <PhoneIcon className="icon-gray" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      )}
      {selectedOption === 'policy' && (
        <Grid item xs={12} className="margin-bottom-16">
          <RolloverTextField
            fullWidth
            name="policy_number"
            label={'Policy number'}
            register={methods.register}
            errors={methods.errors}
            helperText={!!methods.errors.policy_number && 'Please use only letters and numbers'}
            inputRef={methods.register({
              required: true,
              pattern: {
                value: ALPHANUMERIC_REGEX,
                message: 'Please use only letters and numbers',
              },
            })}
            inputlab
            InputProps={{
              classes,
              endAdornment: (
                <InputAdornment position="end">
                  <ClipboardIcon className={'icon-gray'} />
                </InputAdornment>
              ),
            }}
          />
          {!methods.errors.policy_number && <p className="helper">For example MMT00000001111</p>}
        </Grid>
      )}
      {selectedOption !== 'phone' && (
        <>
          <Grid item xs={12} className="margin-bottom-16">
            <RolloverTextField
              fullWidth
              name="last_name"
              label={'Last Name'}
              register={methods.register}
              errors={methods.errors}
              helperText={!!methods.errors.last_name && 'Required'}
              inputRef={methods.register({ required: true })}
              inputVariant={'outlined'}
              InputProps={{ classes }}
            />
          </Grid>
          <Grid item xs={12} md={6} className={`margin-bottom-16 ${isMobile ? '' : 'padding-right-16'}`}>
            <Controller
              as={
                <DatePicker
                  disableFuture
                  fullWidth
                  label={'Date of Birth'}
                  keyboardIcon={<EventIcon className="gray" />}
                  openTo="year"
                  views={['year', 'month', 'date']}
                  error={!!methods.errors.date_of_birth}
                  inputVariant="outlined"
                  helperText={methods.errors.date_of_birth ? 'Must be 18 or older' : ''}
                  format={FirebaseService.getValue(FirebaseConfigKey.LONG_DATE_FORMAT)}
                />
              }
              onChange={date => {
                if (date && date.length > 0 && date[0] != null) {
                  methods.setValue('date_of_birth', new Date(date[0]))
                  return new Date(date[0])
                } else {
                  methods.setValue('date_of_birth', null)
                  return methods.getValues()['date_of_birth']
                }
              }}
              rules={{ validate: tim => isOver18(new Date(tim)), required: true }}
              name="date_of_birth"
              value={methods.watch('date_of_birth')}
              defaultValue={methods.watch('date_of_birth')}
              control={methods.control}
              InputProps={{
                classes,
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarDateIcon className={'icon-gray'} stroke="#667085" strokeWidth="1.66667" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} className="margin-bottom-16">
            <RolloverTextField
              fullWidth
              name="zip_code"
              label={'Zip code'}
              register={methods.register}
              errors={methods.errors}
              helperText={!!methods.errors.zip_code && `${LocalizationService.STRINGS.zipcode} must be 5 digits`}
              inputRef={methods.register({ required: true })}
              InputProps={{ classes }}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

//missing data form
export const MissingDataForm = ({ onSubmitForm, isEmailMissing, isPhoneNumberMissing, errorObj }) => {
  const form = useForm({ mode: 'onSubmit', defaultValues: {} })
  const classes = useStyles()

  const validatePhoneNumber = phoneNumber => !phoneNumber || generalPhoneNumberRegex.test(phoneNumber)

  const onSubmit = data => {
    onSubmitForm(data)
  }

  return (
    <Grid item xs={12} className="margin-bottom-24">
      {errorObj && (errorObj.title || errorObj.message) && <ErrorComponent errorObj={errorObj} />}
      <form autoComplete="off" onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container className="font-normal">
          {isEmailMissing && (
            <Grid item xs={12} className="margin-bottom-16">
              <RolloverTextField
                fullWidth
                name="email"
                label={'Email address'}
                register={form.register}
                errors={form.errors}
                helperText={!!form.errors.email && (form.errors.email.type == 'required' ? 'Required' : 'Invalid email address')}
                inputRef={form.register({ required: true, pattern: { value: EMAIL_REGEX, message: 'Invalid email address' } })}
                inputlab
                InputProps={{
                  classes,
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmailIcon className="icon-gray" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          {isPhoneNumberMissing && (
            <Grid item xs={12} className="margin-bottom-16">
              <RolloverTextField
                label={'Phone number'}
                fullWidth
                inputRef={form.register({ required: true, validate: validatePhoneNumber })}
                name="phone_number"
                validate={validatePhoneNumber}
                register={form.register}
                errors={form.errors}
                helperText={
                  !!form.errors.phone_number && (form.errors.phone_number.type == 'required' ? 'Required' : 'Invalid phone number')
                }
                inputlab
                InputProps={{
                  classes,
                  endAdornment: (
                    <InputAdornment position="end">
                      <PhoneIcon className="icon-gray" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}

          <Grid item xs={12} className="margin-top-8">
            <button className="submit-button font-semibold text-sm" type="submit">
              Get a Quote
            </button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}
