import ApiService, { HTTPMethod } from './api.core'
import { FirebaseService, FirebaseConfigKey } from '../services/FirebaseService'

class VehiclesApi {
  async addVehicle(values) {
    let isMotorcycleIncludeOdometer = true
    if (values.is_motorcycle_include_odometer != undefined) {
      isMotorcycleIncludeOdometer = values.is_motorcycle_include_odometer === 'yes'
    }

    return ApiService.fetch('users/me/vehicles', HTTPMethod.POST, {
      year: values.new_vehicle_req_details && values.new_vehicle_req_details !== 'otherdefault' ? '' : values.year,
      make: !values.make ? 'makedefault' : values.make,
      model: !values.model ? 'modeldefault' : values.model,
      engine_cc: values.engine_cc,
      zip: values.zip,
      acquired_year: values.acquired_year,
      less_than_year_acquired: values.less_than_year_acquired,
      ownership_period: values.ownership_period,
      accessory_value: values.accessory_value,
      side_car_value: values.side_car_value,
      value: values.value,
      // miles_per_year: values.miles_per_year,
      anti_theft_device: values.anti_theft_device == 'yes',
      trailer_value: values.trailer_value,
      anti_lock_brakes: values.anti_lock_brakes == 'yes',
      lienholder: values.lienholder == 'yes',
      lienholder_name: values.lienholder_name,
      lienholder_address: values.lienholder_address,
      lienholder_city: values.lienholder_city,
      lienholder_state: values.lienholder_state,
      lienholder_zipcode: values.lienholder_zipcode,
      is_new_vehicle: values.new_vehicle_req_details && values.new_vehicle_req_details !== 'otherdefault',
      new_vehicle_req_details: values.new_vehicle_req_details ? values.new_vehicle_req_details : '',
      vin: values.vin ? values.vin.trim() : undefined,
      is_motorcycle_include_odometer: isMotorcycleIncludeOdometer,
      estimated_mileage_value: values.estimated_mileage_value,
    })
  }

  async deleteVehicle(vehicleId) {
    return ApiService.fetch('users/me/vehicles', HTTPMethod.DELETE, {
      user_vehicle_id: vehicleId,
    })
  }

  async updateVehicle(values) {
    let isMotorcycleIncludeOdometer = true
    if (values.is_motorcycle_include_odometer != undefined) {
      isMotorcycleIncludeOdometer = values.is_motorcycle_include_odometer === 'yes'
    }

    return ApiService.fetch(`users/me/vehicles/${values.id}`, HTTPMethod.PUT, {
      year: values.new_vehicle_req_details && values.new_vehicle_req_details !== 'otherdefault' ? '' : values.year,
      model: values.model,
      make: values.make,
      engine_cc: values.engine_cc,
      side_car_value: values.side_car_value,
      zip: values.zip,
      less_than_year_acquired: values.less_than_year_acquired,
      ownership_period: values.ownership_period,
      accessory_value: values.accessory_value,
      value: values.value,
      trailer_value: values.trailer_value,
      anti_theft_device: values.anti_theft_device == 'yes',
      anti_lock_brakes: values.anti_lock_brakes == 'yes',
      lienholder: values.lienholder == 'yes',
      lienholder_name: values.lienholder_name,
      lienholder_address: values.lienholder_address,
      lienholder_city: values.lienholder_city,
      lienholder_state: values.lienholder_state,
      lienholder_zipcode: values.lienholder_zipcode,
      vin: values.vin ? values.vin.trim() : undefined,
      is_new_vehicle: values.new_vehicle_req_details && values.new_vehicle_req_details !== 'otherdefault',
      new_vehicle_req_details: values.new_vehicle_req_details ? values.new_vehicle_req_details : '',
      is_motorcycle_include_odometer: isMotorcycleIncludeOdometer,
      estimated_mileage_value: values.estimated_mileage_value,
    })
  }

  async updateVin(insuranceId, requestId, vin) {
    var form = new FormData()

    form.append('vin', vin)

    return ApiService.fetch(`insurances/${insuranceId}/odometer/${requestId}/vin_upload`, HTTPMethod.POST, form, false)
  }

  async newVehicleRequest(values) {
    return ApiService.fetch('users/me/vehicles/req_new_vehicle', HTTPMethod.POST, {
      vehicle_details: values.new_vehicle_req_details,
    })
  }

  async motorcyclesDetailsNext() {
    return ApiService.fetch('users/me/vehicles/motorcycle_details_next', HTTPMethod.GET)
  }
}

export default new VehiclesApi()
