import React from 'react'
import './DownloadApp.css'

import { downloadBannerImages, bannerLinkForStorageModeIos, bannerLinkForStorageModeAndroid } from '../DownloadBannerIconsUrls'

const DownloadAppWebBanner = () => {
  return (
    <div className="cong_download_modal storage">
      <p>Mark your bike as stored, faster than ever!</p>
      <div className="storage-custom-row">
        <p className="pri_text">Download our new app</p>
        <div className="download-modal-btns">
          <img src={downloadBannerImages.downloadArrow} alt="arrow" className="d_arrow" />
          <img
            onClick={bannerLinkForStorageModeAndroid}
            src={downloadBannerImages.downloadAndroidAppIcon}
            alt="google_play_store"
            className="google_play_store"
          />
          <img
            onClick={bannerLinkForStorageModeIos}
            src={downloadBannerImages.downloadIOAppIcon}
            alt="App_store"
            className="App_store"
          />
        </div>
      </div>
    </div>
  )
}

export default DownloadAppWebBanner
