import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { InsurerFooter } from './components/Footer/InsurerFooter'
import './utilities/cookies'

window.dataLayer = window.dataLayer || []

if (process.env.NODE_ENV !== 'development') {
  // console.log = () => {}
}

ReactDOM.render([<App />], document.getElementById('root'))
