export const USER_POLICY_STATUS = {
  BOUND: 'Bound',
  CANCELED: 'Canceled',
  EXPIRED: 'Expired',
  PENDING: 'Pending',
  SCHEDULED: 'Scheduled',
  FAILED_ACTIVATION: 'Failed Activation',
  PENDING_PAYMENT: 'Pending Payment',
  QUOTED: 'Quoted',
  CREATED: 'Created',
}
