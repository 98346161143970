import React, { Suspense, lazy, useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import './styles/styles.scss'
import './styles/margins.scss'
import './styles/colorstyles.scss'
import './styles/safarioverrides.scss'
import { FirebaseService, FirebaseConfigKey } from './services/FirebaseService'
import SessionContext from './context/SessionContext'
import BuyingInsuranceContext from './context/BuyingInsuranceContext'
import UserProfileContext from './context/UserProfileContext'
import InsurancePurchaseContext from './context/InsuracePurchaseContext'
import { lazyWithPreload } from './utilities/RLazyLoading'
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom'
import { LoginProtectedRoute } from './components/Core/Routes/LoginProtectedRoute'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DayJSUtils from '@date-io/dayjs'
import PolicyManagementContext from './context/PolicyManagementContext'
import { BrokerProtectedRoute } from './components/Core/Routes/BrokerProtectedRoute'
import { EntranceProtectedRoute } from './components/Core/Routes/EntranceProtectedRoute'
import { FlowBrokerProtectedRoute } from './components/Core/Routes/FlowBrokerProtectedRoute'
import { SkywatchThemeSwitcher } from './theme/SkywatchThemeSwitcher'
import { changeFavicon } from './services/utils'
import { SessionService } from './services/SessionService'
import { TokenProtectedRoute } from './components/Core/Routes/TokenProtectedRoutes'
import { InsurerFooter } from './components/Footer/InsurerFooter'
import BrokerConfigService from './services/BrokerConfigService'
import { ServiceWorkerWrapper } from './serviceworker/ServiceWorkerWrapper'
import { OnlyGuestsRoute } from './components/Core/Routes/OnlyGuestsRoute'
import { TempThankyou } from './components/Insurance/TempThankyou'
import { OdometerFlowRoutes } from './components/OdometerFlow/OdometerFlowRoutes'
import { EstimatedMilesFlowRoutes } from './components/EstimatedMilesFlow/EstimatedMilesFlowRoutes'
import { LoggedOutProtectedRoute } from './components/Core/Routes/LoggedOutProtectedRoute'
import PolicyModificationContext from './context/PolicyModificationContext'
import LoadingAnimation from './components/PopUps/LoadingAnimation'
import { StorageModeFlowRoutes } from './components/StorageModeFlow/StorageModeFlowRoutes'
import { OnlineDigitalSignatureRoutes } from './components/OnlineDigitalSignatureDocs/OnlineDigitalSignatureRoutes'
import StatesContext from './context/StatesContext'
import { ReactComponent as WheelSVG } from './assets/wheel.svg'
import { MarkelLogin, MarkelLoginStepTwo } from './components/Login/Markel/MarkelLogin'

const MonthlyInsurance = lazyWithPreload(() =>
  import(/* webpackChunkName: "MonthlyInsurance" */ './components/Insurance/MonthlyInsurance').then(module => ({
    default: module.MonthlyInsurance,
  }))
)
const PolicyManagementLayout = lazyWithPreload(() =>
  import(/* webpackChunkName: "PolicyManagementLayout" */ './components/PolicyManagement/PolicyManagementLayout').then(module => ({
    default: module.PolicyManagementLayout,
  }))
)
const SideMenu = lazyWithPreload(() => import(/* webpackChunkName: "SideMenu" */ './components/SideMenu/SideMenu'))
MonthlyInsurance.preload()
PolicyManagementLayout.preload()
SideMenu.preload()

const BrokerRoutes = lazyWithPreload(() =>
  import(/* webpackChunkName: "BrokerRoutes" */ './components/Broker/BrokerRoutes').then(module => ({ default: module.BrokerRoutes }))
)
const Account = lazyWithPreload(() =>
  import(/* webpackChunkName: "Account" */ './components/Account/Account').then(module => ({ default: module.Account }))
)
const Login = lazyWithPreload(() =>
  import(/* webpackChunkName: "Login" */ './components/Login/Login').then(module => ({ default: module.Login }))
)
const ForgotPassword = lazyWithPreload(() =>
  import(/* webpackChunkName: "ForgotPassword" */ './components/Login/ForgotPassword').then(module => ({ default: module.ForgotPassword }))
)
const ServerResponsePopup = lazy(() =>
  import(/* webpackChunkName: "ServerResponsePopup" */ './components/PopUps/ServerResponsePopup').then(module => ({
    default: module.ServerResponsePopup,
  }))
)
const PaymentLoadingAnimation = lazy(() => import(/* webpackChunkName: "LoadingAnimation" */ './components/PopUps/PaymentLoadingAnimation'))

const ConfirmChangeEmail = lazy(() =>
  import(/* webpackChunkName: "ConfirmChangeEmail" */ './components/Account/ConfirmChangeEmail').then(module => ({
    default: module.ConfirmChangeEmail,
  }))
)

Account.preload()
Login.preload()
BrokerRoutes.preload()

Number.prototype.toLocaleStringToFixed = function () {
  return this.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

const useConstructor = (fn = () => null) => {
  const [beenCalled, setBeenCalled] = useState(false)
  if (beenCalled) return
  fn()
  setBeenCalled(true)
}

export default () => {
  const [initialized, setInitialized] = useState(false)
  const newDesign = isMobile && window.location.pathname == `/insurance/summary` && process.env.REACT_APP_NEW_DESIGN === 'true'

  useConstructor(() => {
    SessionService.resetSessionIfNeeded()
  })

  useEffect(() => {
    FirebaseService.init()
    changeFavicon()
  }, [])

  const getDefaultRoute = () => {
    if (SessionService.isLoggedIn()) {
      if (SessionService.isBroker()) {
        return '/broker/portal'
      }
    } else {
      if (!BrokerConfigService.isSkywatchBroker()) {
        // return "/brokerLogin";
      }
    }
    return '/insurance/profile'
  }

  return (
    <MuiPickersUtilsProvider utils={DayJSUtils}>
      <ServiceWorkerWrapper />
      {/* <SkyCookiesConsent /> */}
      <SkywatchThemeSwitcher>
        <Suspense fallback={<div />}>
          <Router>
            <SessionContext setInitialized={() => setInitialized(true)}>
              {!initialized ? (
                <div
                  style={{
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    animation: 'spin 2s linear infinite',
                  }}
                >
                  <WheelSVG style={{ height: '60px' }} />
                </div>
              ) : (
                <div style={{ ...(newDesign ? { height: '100dvh' } : { minHeight: '100%' }) }}>
                  {window.location.pathname !== '/markel-login' && <SideMenu />}
                  <Switch>
                    <EntranceProtectedRoute path="/entrance"></EntranceProtectedRoute>

                    <FlowBrokerProtectedRoute path="/insurance">
                      <StatesContext>
                        <BuyingInsuranceContext>
                          <InsurancePurchaseContext>
                            <MonthlyInsurance />
                          </InsurancePurchaseContext>
                        </BuyingInsuranceContext>
                      </StatesContext>
                    </FlowBrokerProtectedRoute>

                    <BrokerProtectedRoute path="/(broker|brokerPortal|brokerportal|brokerPanel|brokerpanel)">
                      <StatesContext>
                        <UserProfileContext>
                          <BrokerRoutes />
                        </UserProfileContext>
                      </StatesContext>
                    </BrokerProtectedRoute>

                    <LoggedOutProtectedRoute
                      path={FirebaseService.getBoolean(FirebaseConfigKey.INSURED_LOGIN_AVAILABLE) ? '/(login|brokerLogin)' : '/brokerLogin'}
                    >
                      <Login key={1} />
                    </LoggedOutProtectedRoute>

                    <Route path="/forgot-password" component={ForgotPassword} />

                    <OnlyGuestsRoute path="/insurance/profile">
                      <Login key={4} />
                    </OnlyGuestsRoute>

                    <Route path="/logout">
                      <Login key={3} />
                    </Route>

                    <Route path="/register">
                      <Login key={2} />
                    </Route>

                    <Route path="/thankyou">
                      <TempThankyou />
                    </Route>

                    <LoginProtectedRoute path="/policy-management">
                      <StatesContext>
                        <UserProfileContext>
                          <PolicyManagementContext>
                            <PolicyModificationContext>
                              <BuyingInsuranceContext>
                                <PolicyManagementLayout />
                              </BuyingInsuranceContext>
                            </PolicyModificationContext>
                          </PolicyManagementContext>
                        </UserProfileContext>
                      </StatesContext>
                    </LoginProtectedRoute>

                    <LoginProtectedRoute path="/account">
                      <StatesContext>
                        <BuyingInsuranceContext>
                          <UserProfileContext>
                            <Account />
                          </UserProfileContext>
                        </BuyingInsuranceContext>
                      </StatesContext>
                    </LoginProtectedRoute>

                    <TokenProtectedRoute path="/changeEmail" component={ConfirmChangeEmail} />

                    <Route path="/markel-login">
                      <MarkelLogin />
                    </Route>

                    <Route path="/odometer" component={OdometerFlowRoutes} />

                    <Route path="/estimated-renewal">
                      <StatesContext>
                        <UserProfileContext>
                          <PolicyManagementContext>
                            <EstimatedMilesFlowRoutes />
                          </PolicyManagementContext>
                        </UserProfileContext>
                      </StatesContext>
                    </Route>

                    <LoginProtectedRoute path="/storage_mode">
                      <StorageModeFlowRoutes />
                    </LoginProtectedRoute>

                    <LoginProtectedRoute path="/coverages_docs">
                      <OnlineDigitalSignatureRoutes />
                    </LoginProtectedRoute>

                    <Route>
                      <Redirect
                        to={{
                          pathname: getDefaultRoute(),
                          search: window.location.search,
                        }}
                      />
                    </Route>
                  </Switch>
                  {window.location.pathname !== '/markel-login' && <InsurerFooter />}
                </div>
              )}
            </SessionContext>
          </Router>
        </Suspense>
        <LoadingAnimation />

        <Suspense fallback={<div />}>
          <PaymentLoadingAnimation />
          <ServerResponsePopup />
        </Suspense>
      </SkywatchThemeSwitcher>
    </MuiPickersUtilsProvider>
  )
}
