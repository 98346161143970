import { CountryBasedFirebaseInitialize } from '../../models/services/CountryBasedFirebaseInitialize'
import * as firebase from 'firebase/app'
import 'firebase/remote-config'
import 'firebase/database'
import { FirebaseConfigKey } from '../../services/FirebaseService'
import { THREE_WEEKS_IN_MS } from '../../Constants'
import { TemperatureScaleSign } from '../../models/general/TemperatureScaleSign'
import { LengthMeasurement } from '../../models/general/LengthMeasurement'

export class USFirebaseServiceInitializer extends CountryBasedFirebaseInitialize {
  constructor() {
    super()

    // Initialize Firebase
    let app = firebase.initializeApp({
      apiKey: 'AIzaSyDB6EWp3p73okCY-PqYPJtkhU2XvsNsZ8w',
      authDomain: 'skywatch-4281c.firebaseapp.com',
      databaseURL: 'https://skywatch-4281c-38728.firebaseio.com/',
      projectId: 'skywatch-4281c',
      storageBucket: 'skywatch-4281c.appspot.com',
      messagingSenderId: '734682503233',
      appId: '1:734682503233:web:9a2231e0750cdd52ca0ec0',
      measurementId: 'G-8XFSBMC2FL',
    })
    this.app = app
    this.remoteConfig = firebase.remoteConfig(app)

    firebase.analytics(app).setUserProperties({ selectedCountry: null })
    firebase.analytics(app).setUserProperties(null)

    // send an event, so user properties are uploaded.
    firebase.analytics(app).logEvent('new-skywatch-web-test-loading', { test: true })

    this.remoteConfig.defaultConfig = {
      [FirebaseConfigKey.COMMERCIAL_VIEW_STRINGS_JSON]:
        '{"terms_and_agreements_suffix":"I hereby confirm that I have the required FAA Part 107 certificates for performing commercial UAV operations"}',
      [FirebaseConfigKey.GUIDELINES_STRINGS_JSON]:
        '{"permanentStrings":["Use of any drone under 55 pounds, and is not home built","Fly according to FAA rules and certification requirements"],"monthlyOnlyStrings":["Your coverage starts now and ends in 1 month","Manage subscription from the Policy management tab","Flight planning using the app is not mandatory, you’re covered everywhere within the US","You can use the Skywatch app to access safety features or get on-demand insurance for higher liability limits"],"annualOnlyStrings":["Your coverage starts now and ends in 1 year","Manage subscription from the Policy management tab","Flight planning using the app is not mandatory, you’re covered everywhere within the US","You can use the Skywatch app to access safety features or get on-demand insurance for higher liability limits"]}',
      [FirebaseConfigKey.GUIDELINE_KEY_FACTS_URL_HOURLY]: '',
      [FirebaseConfigKey.GUIDELINE_KEY_FACTS_URL_MONTHLY]: '',
      [FirebaseConfigKey.HULL_INSURANCE_AVAILABLE]: true,
      [FirebaseConfigKey.HULL_ESTIMATED_PREMIUM_FACTOR]: 0.14 / 12,
      [FirebaseConfigKey.HULL_ESTIMATED_DEDUCTIBLE_FACTOR]: 0.14,
      [FirebaseConfigKey.HULL_ESTIMATED_PREMIUM_FACTOR_ANNUAL]: 0.08,
      [FirebaseConfigKey.HULL_ESTIMATED_DEDUCTIBLE_FACTOR_ANNUAL]: 0.14,
      [FirebaseConfigKey.HULL_LIABILITY_MINIMUM]: 1000000,
      [FirebaseConfigKey.TEMPERATURE_SCALE_SIGN]: TemperatureScaleSign.FARENHEIT,
      [FirebaseConfigKey.DIFF_BETWEEN_RATE_APP_REQUEST_MILLISECONDS]: THREE_WEEKS_IN_MS,
      [FirebaseConfigKey.APP_RATING_BLOCKED]: false,
      [FirebaseConfigKey.USER_MUST_BE_COMMERCIAL]: false,
      [FirebaseConfigKey.GDPR_REGISTRATION_CONFIRMATION_TEXT]: '',
      [FirebaseConfigKey.COUNTRY]: 'US',
      [FirebaseConfigKey.GDPR_REQUIRED]: false,
      [FirebaseConfigKey.COUNTRY_AWARENESS_BLOCKED]: true,
      [FirebaseConfigKey.ADDITIONAL_INSURED_NOTICE]: '',
      [FirebaseConfigKey.FAQ_URL]: 'https://faq.voominsurance.com/hc/en-us',
      [FirebaseConfigKey.REFERRAL_PROGRAM_ENABLED]: false,
      [FirebaseConfigKey.IS_INDOOR_COVERAGE_AVAILABLE]: false,
      [FirebaseConfigKey.REFERRAL_PROGRAM_CREDIT_LIMIT]: 30.38,
      [FirebaseConfigKey.PERFORMANCE_MONITORING_ENABLED]: true,
      [FirebaseConfigKey.USER_RECORDING_ENABLED]: false,
      [FirebaseConfigKey.LIABILITY_LIMITS]: '[1000000, 2000000, 5000000]',
      [FirebaseConfigKey.SMARTLOOK_KEY]: '58ca0720a0459eb212618f195f04ea807576b9f0',
      [FirebaseConfigKey.CURRENCY_SYMBOL]: '$',
      [FirebaseConfigKey.INSURANCE_COMPANY_NAME]: 'starr',
      [FirebaseConfigKey.POLICY_EXTENSION_AVAILABLE]: true,
      [FirebaseConfigKey.FILE_A_CLAIM_URL]: 'https://portal.skywatch.ai/claim',
      [FirebaseConfigKey.CONTACT_US_URL]: 'https://faq.skywatch.ai/hc/en-us/requests/new',
      [FirebaseConfigKey.AVAILABLE_COUNTRIES]: ['US', 'UK'],
      [FirebaseConfigKey.HULL_INSURANCE_AVAILABLE_FOR_MONTHLY]: true,
      [FirebaseConfigKey.HULL_INSURANCE_AVAILABLE_FOR_ANNUAL]: true,
      [FirebaseConfigKey.SHORT_DATE_FORMAT]: 'mm/dd',
      [FirebaseConfigKey.LONG_DATE_FORMAT]: 'MM/DD/YYYY',
      [FirebaseConfigKey.DATE_TIME_FORMAT]: 'MMM D YYYY, h:mm A',
      [FirebaseConfigKey.MINIMUN_DRONE_VALUE]: '0',
      [FirebaseConfigKey.MAXIMUM_DRONE_VALUE]: '15000',
      [FirebaseConfigKey.MAXIMUM_DRONE_VALUE_FOR_BROKER]: '30000',
      [FirebaseConfigKey.MINIMUN_EQUIPMENT_VALUE]: '0',
      [FirebaseConfigKey.MAXIMUM_EQUIPMENT_VALUE]: '15000',
      [FirebaseConfigKey.MAXIMUM_EQUIPMENT_VALUE_FOR_BROKER]: '30000',
      [FirebaseConfigKey.FTUE]:
        '[{"title":"Drone insurance at your fingertips","subtitle":"","illustration":{"uri":"https://skywatchapp-prod.s3.amazonaws.com/common_files/ftue/Page_01_USA.jpg"}},{"title":"Aviation Liability","subtitle":"By the hour, month or year","illustration":{"uri":"https://skywatchapp-prod.s3.amazonaws.com/common_files/ftue/Page_02_USA.jpg"}},{"title":"Hourly drone insurance","subtitle":"Pay as you fly","illustration":{"uri":"https://skywatchapp-prod.s3.amazonaws.com/common_files/ftue/Page_03_USA.jpg"}},{"title":"Protect Your Drone","subtitle":"Hull coverage (optional)","illustration":{"uri":"https://skywatchapp-prod.s3.amazonaws.com/common_files/ftue/Page_04_USA.jpg"}},{"title":"Instant certificate","subtitle":"Email to you in seconds","illustration":{"uri":"https://skywatchapp-prod.s3.amazonaws.com/common_files/ftue/Page_05_USA.jpg"}}]',
      [FirebaseConfigKey.ADMINISTRATIVE_DIVISION_UNAVAILABLE_TEXT]:
        "Don't worry we're making efforts to be live everywhere. \n\nDrop us a message to support@skywatch.ai and we'll notify you when SkyWatch is available in your state.",
      [FirebaseConfigKey.DEFAULT_LIABILITY]: 1000000,
      [FirebaseConfigKey.INSURANCE_OPTIONS_CONFIGURATION]:
        '{"monthly": {"whatsIncluded": ["Fly anytime, anywhere in the US", "Hull coverage available"], "fromPrice": 42}, "annual": {"whatsIncluded": ["Fly anywhere all year long", "Hull coverage available"], "fromPrice": 500}, "hourly": {"whatsIncluded": ["Pay by the hour", "Pre-booking options available"], "fromPrice": 10}}',
      [FirebaseConfigKey.UNIT_LENGTH]: LengthMeasurement.MILES,
      [FirebaseConfigKey.MIN_SAVINGS_DISPLAY]: 5,
      [FirebaseConfigKey.ZIPCODE_REGEX]: '^[0-9]{5}$',
      [FirebaseConfigKey.LIABILITY_COVERAGE_TEXT]:
        '{"general": ["Personal injuries and invasion of privacy claims","Bodily injury","Medical expenses", "Property damage"],"onlyWithHull": []}',
      [FirebaseConfigKey.RESTRICTED_COUNTRIES]: '[]',
      [FirebaseConfigKey.ANNUAL_AVAILABLE]: true,
      [FirebaseConfigKey.MONTHLY_AVAILABLE]: true,
      [FirebaseConfigKey.LIABILITY_DEDUCTIBLE]: '{"value":0,"factor":"currency"}',
      [FirebaseConfigKey.REGISTRATION_AVAILABLE]: true,
      [FirebaseConfigKey.INSURED_LOGIN_AVAILABLE]: true,
      [FirebaseConfigKey.PAYMENT_REQUIRED_FOR_BROKER]: true,
      [FirebaseConfigKey.DRONE_IMAGE_REQUIRED_FOR_BROKER]: false,
      [FirebaseConfigKey.CLAIM_QUESTION]: 'Have you had any drone claims or incidents in the last 3 years?',
      [FirebaseConfigKey.SHOULD_DISPLAY_HULL_ESTIMATIONS]: true,
      [FirebaseConfigKey.TOTAL_HULL_SUM_INSURED]: 250000,
      [FirebaseConfigKey.LIABILTY_LIMIT_EXTENSION_AVAILABLE]: true,
      [FirebaseConfigKey.PAUSE_POLICY_AVAILABLE]: true,
      [FirebaseConfigKey.DRONE_TAKEOFF_WEIGHTS]:
        '["<0.25Kg","0.25Kg - 4.9Kg","5Kg - 9.9Kg","10Kg - 14.9Kg","15Kg - 19.9Kg","20Kg - 24.9Kg","25Kg - 29.9Kg","30Kg+"]',
      [FirebaseConfigKey.IS_HULL_EDITABLE]: true,
      [FirebaseConfigKey.WHATS_COVERED_AVAILABLE]: true,
      [FirebaseConfigKey.OPERATOR_EDITING_AVAILABLE]: false,
      [FirebaseConfigKey.HULL_HEADER_TEXT]: 'Drones & Equipment',
      [FirebaseConfigKey.SHOW_HULL_PREMIUMS_ON_POLICY_PAGE]: true,
      [FirebaseConfigKey.FLIGHT_LOGS_URL]: 'https://user.skywatch.ai/insurance-discounts',
      [FirebaseConfigKey.PROPOSAL_LINK_AVAILABLE]: false,
      [FirebaseConfigKey.MEDICAL_EXPENSES]: '[500, 1000, 2000, 5000]',
      [FirebaseConfigKey.PERSONAL_INJURY_COVERAGE]: '[25000, 100000]',
      [FirebaseConfigKey.SUPPORT_PHONE_NUMBER]: '888-871-2421',
      [FirebaseConfigKey.PRETTY_COUNTRY_NAME]: 'the United States',
      [FirebaseConfigKey.WORLD_WIDE_TEXT]:
        'Skywatch can provide a worldwide coverage outside of the US for commercial purposes. For more info',
      [FirebaseConfigKey.ADDITIONAL_COVERAGE_TOOLTIP]:
        '["Add coverage for flying indoors", "SkyWatch.AI policies provide $25,000 in personal injury and $500 in medical expenses.","Upgrade your coverage for an additional premium.","SkyWatch.AI can provide worldwide coverage territory for commercial purposes upon request.","Contact us to learn more."]',
      [FirebaseConfigKey.MINIMUM_HULL_VALUE_FOR_IMAGE_MANDATORY]: 1999,
    }
  }
}
