import coveragesApi from '../../../api/coverages.api'
import { flowType, billing_period_options, PAYMENT_FEE } from '../../../Constants'
import { coveragesToIgnore } from '../../../models/insurance-models/CoveragesMapping'

/**
 * @description Combines user vehicle information with vehicle information in an insurance plan
 */
const getPlanVehicleDetails = (plan, userVehicles) =>
  userVehicles.map(userVehicle => ({
    year: userVehicle.year,
    make: userVehicle.make,
    model: userVehicle.model,
    estimatedMileage: userVehicle.estimated_mileage_value,
    fixedPrice: plan.vehicleCoveragePrices[userVehicle.id]?.fixedPrice || 0,
    perMilePrice: plan.vehicleCoveragePrices[userVehicle.id]?.perMilePrice || 0,
  }))

export const getPlanTotalPrice = (plan, userVehicles, offer = {}) => {
  const offerDetails = getOfferDetails(plan, offer)
  const vehiclesList = getPlanVehicleDetails(offerDetails, userVehicles)
  return vehiclesList.reduce((total, vehicle) => total + vehicle.fixedPrice + (vehicle.perMilePrice * vehicle.estimatedMileage) / 12, 0)
}

export const getCoverageTooltip = (coverageName, options) => {
  let value = options[coverageName]
  return value && value.length > 1 ? value[0].tooltip : undefined
}

export const getOfferDetails = (selectedPlan, selectedOffer) => {
  return Object.keys(selectedOffer).length > 0 ? selectedOffer : selectedPlan
}

export const getOfferCoverages = (coverages, vehiclesDetails, addOnCoverages) => {
  const allCoverages = {}
  Object.values(Object.values(coverages)[0].coveragePrices)
    .filter(cov => {
      let needToIgnore = false
      if (cov.coverageName in coveragesToIgnore) {
        needToIgnore = coveragesToIgnore[cov.coverageName].ignoreInCoverageCard
      }
      return cov.type === 'policy' && !needToIgnore
    })
    .forEach(cov => {
      allCoverages[cov.coverageName] = [
        {
          coverageOptionId: cov.coverageOptionId,
          isInclude: cov.isInclude,
          title: cov.title,
          type: cov.type,
          coverageOptionName: cov.coverageOptionName,
        },
      ]
    })

  for (let vehicleId in coverages) {
    Object.values(coverages[vehicleId].coveragePrices)
      .filter(cov => {
        let needToIgnore = false
        if (cov.coverageName in coveragesToIgnore) {
          needToIgnore = coveragesToIgnore[cov.coverageName].ignoreInCoverageCard
        }
        return cov.type === 'vehicle' && !needToIgnore
      })
      .forEach(cov => {
        let vehicle = vehiclesDetails.filter(v => String(v.vehicleId) === vehicleId)[0]
        if (allCoverages.hasOwnProperty(cov.coverageName)) {
          allCoverages[cov.coverageName] = allCoverages[cov.coverageName].concat([
            {
              coverageOptionId: cov.coverageOptionId,
              isInclude: cov.isInclude,
              title: cov.title,
              type: cov.type,
              coverageOptionName: cov.coverageOptionName,
              year: vehicle.year,
              make: vehicle.make,
              model: vehicle.model,
            },
          ])
        } else {
          allCoverages[cov.coverageName] = [
            {
              coverageOptionId: cov.coverageOptionId,
              isInclude: cov.isInclude,
              title: cov.title,
              type: cov.type,
              coverageOptionName: cov.coverageOptionName,
              year: vehicle.year,
              make: vehicle.make,
              model: vehicle.model,
            },
          ]
        }
      })
  }
  Object.values(addOnCoverages)
    .filter(cov => {
      let needToIgnore = false
      if (cov.coverageName in coveragesToIgnore) {
        needToIgnore = coveragesToIgnore[cov.coverageName].ignoreInCoverageCard
      }
      return cov.type === 'policy' && !needToIgnore
    })
    .forEach(cov => {
      allCoverages[cov.coverageName] = [
        {
          coverageOptionId: cov.coverageOptionId,
          isInclude: cov.isIncluded,
          title: cov.title,
          type: cov.type,
          coverageOptionName: cov.coverageOptionName,
        },
      ]
    })
  const unsortedCoverages = Object.entries(allCoverages)

  unsortedCoverages.sort(([keyA, valueA], [keyB, valueB]) => {
    const isIncludeA = valueA.some(obj => obj.isInclude)
    const isIncludeB = valueB.some(obj => obj.isInclude)

    if (isIncludeA === isIncludeB) {
      return 0
    } else if (isIncludeA) {
      return -1
    } else {
      return 1
    }
  })

  const sortedCoverages = Object.fromEntries(unsortedCoverages)
  return sortedCoverages
}

export const getCoverageOptionName = (options, coverageName, coverageOptionId) => {
  const values = options[coverageName]
  if (values) {
    const selectedOption = values.filter(option => option.value === coverageOptionId)[0]
    if (selectedOption && selectedOption.label) {
      return selectedOption.label
    } else if (selectedOption && selectedOption.name) {
      return selectedOption.name
    } else {
      return undefined
    }
  } else {
    return undefined
  }
}

export const createOfferCart = vehicleCoveragePrices => {
  const values = Object.entries(vehicleCoveragePrices)
  let cart = {
    vehicle_requests: {},
  }

  Object.values(values[0][1].coveragePrices)
    .filter(coverage => coverage.type == 'policy')
    .forEach(coverage => {
      cart[coverage.coverageName] = coverage.coverageOptionId
    })
  const vehicleCoverages = {}
  values.forEach(vehicle => {
    Object.values(vehicle[1].coveragePrices)
      .filter(coverage => coverage.type == 'vehicle')
      .forEach(coverage => {
        vehicleCoverages[coverage.coverageName] = coverage.coverageOptionId
      })
    cart.vehicle_requests[vehicle[0]] = {
      is_diminishing_deductible: vehicle[1].isDiminishingDeductible,
      has_lienholder: vehicle[1].haslLienholder,
      coverages: { ...vehicleCoverages },
    }
  })

  return cart
}

export const calcToalAmountBaseOnEstimatedMileage = (vehiclesDetails, pricebreakdown, biliingPeriod, billingFlowType, paymentFee) => {
  if (billingFlowType === flowType.ESTIMATED_MILEAGE) {
    let totalEstimatedMileageCharge = 0
    pricebreakdown.vehiclesDetailsForUi.forEach(v => {
      let vehicle = vehiclesDetails.filter(i => i.id === v.vehicleId)[0]
      if (vehicle) {
        totalEstimatedMileageCharge += (v.perMilePrice * vehicle.estimated_mileage_value) / billing_period_options[biliingPeriod].divider
      }
    })
    let addOnCoverages = 0
    if (pricebreakdown.addOnCoveragePrices) {
      pricebreakdown.addOnCoveragePrices.forEach(coverage => {
        addOnCoverages += coverage.fixedPrice
      })
    }
    const total =
      totalEstimatedMileageCharge +
      paymentFee +
      pricebreakdown.fixedPrice +
      pricebreakdown.additionalFees +
      addOnCoverages +
      pricebreakdown.minimumEarnedPremium
    return total
  } else {
    return pricebreakdown.totalAmount
  }
}
