import React from 'react'
import { downloadBannerImages } from './DownloadBannerIconsUrls'
import './DownloadBanner.css'
import './CongratsDownloadBanner/congratsBanner.css'
const DownloadBannerForMobile = ({ headerText1 = '', headerText2 = '', containerClassName = '', androidURL = '', iosURL = '' }) => {
  return (
    <div className={`cong_download_modal cng-dl-mobile-modal ${containerClassName}`}>
      <p>
        {headerText1}
        <span className="pri_text">{headerText2}</span>
      </p>
      <div className="download-modal-btns">
        <img src={downloadBannerImages.downloadArrow} alt="arrow" className="d_arrow" />
        <div className="banner-app-button">
          <img
            onClick={() => window.open(`${androidURL}`, '_blank')}
            src={downloadBannerImages.downloadAndroidAppIcon}
            alt="google_play_store"
            className="google_play_store"
          />
          <img
            onClick={() => window.open(`${iosURL}`, '_blank')}
            src={downloadBannerImages.downloadIOAppIcon}
            alt="App_store"
            className="App_store"
          />
        </div>
      </div>
    </div>
  )
}

export default DownloadBannerForMobile
