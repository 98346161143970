import React from 'react'
import { TextField, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  inputStyle: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#424242',
        borderWidth: '1px',
      },
      '&:hover fieldset': {
        borderColor: '#424242 !important',
        borderWidth: '1px',
      },
    },
  },
}))

export const RolloverTextField = ({
  onChange,
  onInput,
  value,
  shrink,
  InputProps,
  disabled,
  helperText,
  inputRef,
  register,
  label,
  name,
  control,
  defaultValue,
  errors,
  type,
  InputLabelProps,
  placeholder,
}) => {
  const classes = useStyles()

  return (
    <TextField
      onChange={onChange}
      onInput={onInput}
      disabled={disabled}
      value={value}
      InputProps={InputProps}
      placeholder={placeholder}
      fullWidth
      variant="outlined"
      className={errors ? 'avenir-book' : classes.inputStyle}
      InputLabelProps={{ shrink: shrink, className: `avenir-book ${!errors && 'dark-gray-text'}`, ...InputLabelProps }}
      label={label}
      type={type}
      name={name}
      register={register}
      inputRef={inputRef}
      control={control}
      defaultValue={defaultValue}
      error={!!errors && !!errors[name]}
      helperText={helperText}
      FormHelperTextProps={{
        className: 'avenir-book',
      }}
    />
  )
}
