import React from 'react'
import FlexView from 'react-flexview/lib'
import CheckIcon from '@material-ui/icons/Check'
import { Grow, IconButton } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { reportGTM } from '../Core/Utilities/GTagManger'
import { isMobile } from 'react-device-detect'
import './OdometerFlowCongratz.scss'
import DownloadBannerForMobile from '../DownloadAppBanner/DownloadBannerForMobile.js'

export const OdometerFlowCongratz = () => {
  const [show, setShow] = React.useState(false)
  const [bannerShow, setBannerShow] = React.useState(true)
  const history = useHistory()

  const location = useLocation()
  const params = queryString.parse(location.search)

  React.useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 200)
  }, [])

  const redirectToPolicyManagement = () => {
    reportGTM('', '', {
      event: 'Done-button-odometer-upload',
      at: window.location.pathname,
    })
    history.push('/policy-management')
  }

  return (
    <FlexView column className="priview-background odometer-success-main">
      <FlexView column className="priview-background2" hAlignContent={'center'} vAlignContent={'center'}>
        <FlexView className="logo-on-camera" hAlignContent={'center'} style={{ position: 'absolute', top: 10 }}>
          <img src={`${process.env.PUBLIC_URL}/assets/voom_white.png`} style={{ height: 38 }} />
        </FlexView>
        <FlexView column vAlignContent={'center'} className="preview-content odometer-success-content">
          <Grow in={show} style={{ transformOrigin: '0 0 0' }} {...(show ? { timeout: 1000 } : {})}>
            <FlexView hAlignContent={'center'} column vAlignContent={'center'} className="preview-title-top">
              <img src={process.env.PUBLIC_URL + '/assets/v-sign.png'} className="odometer-success-icon" />
              <div className=" white textcenter margin-bottom-basic odometer-success-text">
                Your odometer <br /> reading was sent <br />
                successfully
              </div>
              {params.IsUpdateReq == 'False' ? (
                params.IsNewFlow == 'True' ? (
                  <div className="odometer-btn">
                    <div onClick={redirectToPolicyManagement} className="odometer-done-text">
                      Ride on!
                    </div>
                  </div>
                ) : (
                  <div className="text-secondary white textcenter margin-bottom-basic">
                    <div>{params.IsCancellationReq == 'True' ? 'Documents and receipt will be' : 'Your policy will be'}</div>
                    <div className="margin-top-3">emailed to you shortly</div>
                  </div>
                )
              ) : (
                <div className="text-secondary white textcenter margin-bottom-basic">
                  <div>Your monthly billing statement </div>
                  <div className="margin-top-3">will be confirmed &amp; emailed shortly</div>
                </div>
              )}
              {isMobile && bannerShow && (
                <DownloadBannerForMobile
                  headerText1="Uploading the odometer picture just got easier!"
                  headerText2=" Download our new app"
                  containerClassName="odometer-modal"
                  androidURL="https://voominsurancpro.page.link/?link=https://play.google.com/store/apps/details?id%3Dcom.voom_motorcycle_app&apn=com.voom_motorcycle_app&utm_campaign=odometer_upload_success&utm_medium=banner&utm_source=google"
                  iosURL="https://voominsurancpro.page.link/?link=https://apps.apple.com/us/app/voom-motorcycle-insurance/id1641498413&isi=1641498413&ibi=com.voominsurance.motorcycles&utm_campaign=odometer_upload_success&utm_medium=banner&utm_source=voom"
                />
              )}
            </FlexView>
          </Grow>
        </FlexView>
      </FlexView>
    </FlexView>
  )
}
