import React from 'react'
import { Button } from '@material-ui/core'
import './PurpleButton.scss'

export const PurpleButton = props => {
  return (
    <Button {...props} variant="contained" disableElevation className={`continue-purple-button ${props.className}`}>
      {props.children}
    </Button>
  )
}
