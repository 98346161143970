import { TextField, withStyles } from '@material-ui/core'
import React from 'react'
import { Controller } from 'react-hook-form'
import { EventEmitterService, EventKey } from '../../../services/EventEmitterService'
// import LanguageService from '../../../services/LanguageService'
import { SignaturePopup } from '../../PopUps/SignaturePopup'
import { isMobile } from 'react-device-detect'
import './ControlledSignature.scss'

export const ControlledSignature = ({ control, errors, setValue, getValues, className }) => {
  const onSignComplete = signature => {
    setValue('signature', signature, { shouldDirty: true, shouldValidate: true })
  }

  const openSignature = () => {
    EventEmitterService.dispatch(EventKey.SHOW_SIGNATURE_POPUP, { signature: getValues()['signature'] })
  }

  return (
    <div className={`markel-signature-input ${className}`}>
      <Controller
        rules={{ required: true }}
        control={control}
        name={'signature'}
        as={
          <>
            {!getValues()['signature'] && (
              <TextField
                onClick={openSignature}
                placeholder={isMobile ? 'Click to sign' : 'Click here to sign'}
                error={!!errors.signature}
                helperText={!!errors.signature && 'required'}
              />
            )}
            {getValues()['signature'] && <img src={getValues()['signature']} onClick={openSignature} className="signature-img" />}
          </>
        }
        className="click-to-sign-button"
      />
      <SignaturePopup onSignComplete={onSignComplete} />
    </div>
  )
}
