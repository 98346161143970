import ApiService, { HTTPMethod } from './api.core'
import { Transformer } from '../models/Transformer'
import { getAiWithoutId } from '../services/utils'

class PolicyManagementApi {
  async getAllInsurances() {
    let res = await ApiService.fetch('insurances/me', HTTPMethod.GET)
    if (res.ok) {
      res.parsedData = Transformer.parseActiveInsuranceModelArray(res.data)
    }
    return res
  }

  async anyRoadSideInsurance() {
    let res = await ApiService.fetch('insurances/any_road_side_insurance', HTTPMethod.GET)
    if (res.ok){
      return res.data
    }
    return true
  }

  async cancel(insuranceId, reason, comment) {
    let res = await ApiService.fetch(`insurances/${insuranceId}/cancel`, HTTPMethod.POST, {
      cancellation_reason: reason,
      cancellation_comment: comment,
    })
    return res
  }

  async cancelPolicyWithoutOdometer(insuranceId, reason, comment) {
    let res = await ApiService.fetch(`insurances/${insuranceId}/cancelImmediately`, HTTPMethod.POST, {
      cancellation_reason: reason,
      cancellation_comment: comment,
    })
    return res
  }

  async cancelPolicyWithoutPayment(insuranceId, reason, comment) {
    let res = await ApiService.fetch(`insurances/${insuranceId}/cancelInsuranceFreeOfCharges`, HTTPMethod.POST, {
      cancellation_reason: reason,
      cancellation_comment: comment,
    })
    return res
  }

  async cancelRenwalPolicy(insuranceId, reason, comment) {
    let res = await ApiService.fetch(`insurances/${insuranceId}/cancelRenewalPolicy`, HTTPMethod.POST, {
      cancellation_reason: reason,
      cancellation_comment: comment,
    })
    return res
  }

  /**
   *
   * @description Cancel an existing policy with given parameters
   *
   */
  async cancelPolicy(data) {
    const { insuranceId, cancelationDate, cancelationReason, cancelationAction, cancelationText, odometerImages, odometerValues } = data
    const formData = new FormData()
    formData.append('CancelDate', cancelationDate.toISOString())
    formData.append('Reason', cancelationReason)
    formData.append('CancelationAction', cancelationAction)
    formData.append('Comment', cancelationText)

    Object.keys(odometerImages).forEach(key => formData.append(`image_${key}`, odometerImages[key], odometerImages[key].name))
    Object.keys(odometerValues).forEach(key => formData.append(`miles_${key}`, odometerValues[key]))
    return await ApiService.fetch(`insurances/${insuranceId}/cancel`, HTTPMethod.POST, formData, false, true)
  }

  /**
   * @description Get the policy refund for cancelation, given cancelation parameters
   */
  async getCanceledPolicyRefund(data) {
    const { insuranceId, cancelationDate, cancelationReason, cancelationAction, cancelationText, odometerImages, odometerValues } = data
    const formData = new FormData()
    formData.append('CancelDate', cancelationDate.toISOString())
    formData.append('Reason', cancelationReason)
    formData.append('CancelationAction', cancelationAction)
    formData.append('Comment', cancelationText)

    Object.keys(odometerImages).forEach(key => formData.append(`image_${key}`, odometerImages[key], odometerImages[key].name))
    Object.keys(odometerValues).forEach(key => formData.append(`miles_${key}`, odometerValues[key]))
    return await ApiService.fetch(`insurances/${insuranceId}/getcancelamount`, HTTPMethod.POST, formData, false, true)
  }
}

export default new PolicyManagementApi()
