import ApiService, { HTTPMethod } from './api.core'

class StorageModeApi {
  async getSigningForm(data) {
    return await ApiService.fetch('storage_mode/get_signing_form', HTTPMethod.POST, data)
  }

  async saveSignedForm(data) {
    return await ApiService.fetch('storage_mode/upload_signed_form', HTTPMethod.POST, data)
  }

  async adminUploadSignedForm(data) {
    return await ApiService.fetch('storage_mode/admin_upload_signed_form', HTTPMethod.POST, data)
  }

  async releaseFromStorageMode(data) {
    return await ApiService.fetch(`storage_mode/cancel_storage_mode`, HTTPMethod.POST, data)
  }
}

export default new StorageModeApi()
