import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { EventEmitterService, EventKey } from '../../services/EventEmitterService'
import { Checkbox, FormHelperText, Paper, withStyles } from '@material-ui/core'
import { FirebaseService, FirebaseConfigKey } from '../../services/FirebaseService'
import FlexView from 'react-flexview/lib'
import { liabilityLimitPretty } from '../../services/utils'
import SignatureCanvas from 'react-signature-canvas'
import NewFillButton from '../Core/NewFillButton'
import { Controller, useForm } from 'react-hook-form'
// import LanguageService from '../../services/LanguageService';

//NOTE: The following component has it's styles overriden so it wouldn't be blocked by
//      the Zendesk's help widgit.
const NewDialog = withStyles({
  root: {
    zIndex: '100000000 !important',
  },
})(Dialog)

export const SignaturePopup = ({ onSignComplete }) => {
  const [open, setOpen] = React.useState(false)
  const { register, getValues, triggerValidation, errors, control, setValue } = useForm({ mode: 'onChange' })
  const canvasRef = React.useRef(null)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const close = () => {
    setOpen(false)
  }

  const handleAgree = async () => {
    let valid = await triggerValidation()

    if (!valid) return

    onSignComplete && onSignComplete(getValues()['signature'])
    close()
  }

  const clear = () => {
    canvasRef.current.clear()
  }

  React.useEffect(() => {
    let id = EventEmitterService.subscribe(EventKey.SHOW_SIGNATURE_POPUP, data => {
      handleClickOpen()
      // if(data && data.signature) {
      //     canvasRef.current.fromDataURL(data.signature)
      // }
    })

    return function cleanup() {
      EventEmitterService.unsubscribe(EventKey.SHOW_SIGNATURE_POPUP, id)
    }
  }, [canvasRef])

  return (
    <NewDialog open={open} onClose={close} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogContent>
        <Paper elevation={3}>
          <Controller
            rules={{ required: true, validate: x => !canvasRef.current.isEmpty() }}
            control={control}
            name={'signature'}
            as={
              <SignatureCanvas
                ref={canvasRef}
                onEnd={() => {
                  setValue('signature', canvasRef.current.getTrimmedCanvas().toDataURL())
                }}
                penColor="black"
                canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
              />
            }
          />
        </Paper>
        {!!errors.signature && <div className="text-body margin-top-basic red">required</div>}
      </DialogContent>
      <FlexView column hAlignContent={'center'}>
        <NewFillButton blue2 flat onClick={handleAgree} className="avenir-book">
          Approve
        </NewFillButton>
        <div onClick={clear} className="text-small clickable blue2 underline avenir-book">
          Clean
        </div>
        <div onClick={close} className="text-small clickable blue2 margin-top-small underline avenir-book">
          Close
        </div>
        <br />
      </FlexView>
    </NewDialog>
  )
}
