import React from 'react'

const ClipboardIcon = props => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.833 3.33333C14.608 3.33333 14.9955 3.33333 15.3134 3.41852C16.1761 3.64969 16.85 4.32356 17.0812 5.18629C17.1663 5.5042 17.1663 5.89169 17.1663 6.66667V14.3333C17.1663 15.7335 17.1663 16.4335 16.8939 16.9683C16.6542 17.4387 16.2717 17.8212 15.8013 18.0609C15.2665 18.3333 14.5665 18.3333 13.1663 18.3333H7.83301C6.43288 18.3333 5.73281 18.3333 5.19803 18.0609C4.72763 17.8212 4.34517 17.4387 4.10549 16.9683C3.83301 16.4335 3.83301 15.7335 3.83301 14.3333V6.66667C3.83301 5.89169 3.83301 5.5042 3.91819 5.18629C4.14936 4.32356 4.82323 3.64969 5.68596 3.41852C6.00388 3.33333 6.39136 3.33333 7.16634 3.33333M8.49967 5H12.4997C12.9664 5 13.1997 5 13.378 4.90917C13.5348 4.82928 13.6623 4.7018 13.7422 4.54499C13.833 4.36673 13.833 4.13338 13.833 3.66667V3C13.833 2.53329 13.833 2.29994 13.7422 2.12168C13.6623 1.96487 13.5348 1.83739 13.378 1.7575C13.1997 1.66667 12.9664 1.66667 12.4997 1.66667H8.49967C8.03296 1.66667 7.79961 1.66667 7.62135 1.7575C7.46455 1.83739 7.33706 1.96487 7.25717 2.12168C7.16634 2.29994 7.16634 2.53329 7.16634 3V3.66667C7.16634 4.13338 7.16634 4.36673 7.25717 4.54499C7.33706 4.7018 7.46455 4.82928 7.62135 4.90917C7.79961 5 8.03296 5 8.49967 5Z"
      stroke="inherit"
      stroke-width="inherit"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export default ClipboardIcon
