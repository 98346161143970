import ApiService, { HTTPMethod } from './api.core'

class AnalyticsApi {
  async addUtmLog(type, cookie_string) {
    const CookieJson = {}
    if (cookie_string != null) {
      const url = new URL(cookie_string);
      url.searchParams.forEach((value, key) => {
        CookieJson[key] = value;
      })
    } 

    return await ApiService.fetch('general/AddUtmLog', HTTPMethod.POST, {
      type,
      payload: CookieJson,
    })
  }
}

export default new AnalyticsApi()
