import React from 'react'
import { Button } from '@material-ui/core'
import './ContinueButton.scss'

export const GreenButton = props => {
  return (
    <Button {...props} variant="contained" disableElevation className={`continue-button ${props.className}`}>
      {props.children}
    </Button>
  )
}
