import React, { useReducer, useEffect, useContext } from 'react'
import { SessionService } from '../services/SessionService'
import { FirebaseService, FirebaseConfigKey } from '../services/FirebaseService'
import { Action } from '../models/Action'
import { EventEmitterService, EventKey } from '../services/EventEmitterService'
import StorageService from '../services/StorageService'
import { awaitWithLoading, isUK, removeDuplicateAdditionalInsured, fixWrongGeneratedGuid } from '../services/utils'
import { BuyingInsuranceContextStore } from './BuyingInsuranceContext'
import { BillingPeriod } from '../models/insurance-models/InsuranceBillingPeriodModel'
import { useHistory, useLocation } from 'react-router-dom'
import { parseQuote } from '../utilities/QuoteParser'
import { UserApi } from '../api/user.api'
import { PaymentMethod } from '../models/user-models/CreditCardModal'

export const INSURANCE_CONTEXT_ACTIONS = {
  SET_PAYMENT_METHOD: 'SET_PAYMENT_METHOD',
  SET_EXTERNAL_USER_ID: 'SET_EXTERNAL_USER_ID',
}

export const ONGOING_PURCHASE = 'purchase_ongoing'

const defaultOption = {
  offerId: '',
  subscriptionPlanId: 1,
  price: 1,
  commercial: false,
  taxes: 1,
  taxesMultiplier: 1,
  purchaseDiscountMultiplier: 1,
  finalPrice: 1,
  priceWithTaxes: 1,
  totalDiscount: 1,
  avgFlightScoreMultiplier: 1,
  totalCoverageHoursMultiplier: 1,
  dronePremium: 1,
  equipmentPremium: 1,
  billingPeriod: 'Year',
  creditUtilization: 1,
  finalCost: 1,
  coverages: {},
  externalUserId: '',
}

const defaultState = {
  selectedOption: defaultOption,
  paymentMethod: PaymentMethod.STRIPE,
  externalUserId: '',
  setSelectedOption: option => {
    'Not Implemented'
  },
  dispatch: (action: Action) => {
    'Not Implemented'
  },
}

const reducer = (state, action: Action) => {
  console.log('InsurancePurchaseContext state', state, action)

  const newStateCalculate = () => {
    switch (action.type) {
      case 'RESET':
        return { ...state }
      case 'OPTION_CHANGED':
        return { ...state, selectedOption: action.data }
      case INSURANCE_CONTEXT_ACTIONS.SET_PAYMENT_METHOD:
        return { ...state, paymentMethod: action.data }
      case INSURANCE_CONTEXT_ACTIONS.SET_EXTERNAL_USER_ID:
        return { ...state, externalUserId: action.data }
      default:
        return state
    }
  }

  const newState = newStateCalculate()

  StorageService.setItem(
    ONGOING_PURCHASE,
    JSON.stringify({
      drones: newState.drones,
      equipment: newState.equipment,
      autoRenew: newState.autoRenew,
      selectedOptionLiability: newState.selectedOption.liabilityLimit,
    })
  )

  return newState
}

export const InsurancePurchaseContextStore = React.createContext(defaultState)

const InsurancePurchaseContext = props => {
  const buyingInsuranceContext = useContext(BuyingInsuranceContextStore)
  const history = useHistory()
  const location = useLocation()

  const [state, dispatch] = useReducer(reducer, defaultState, () => {
    let savedState = {}

    // Initialize from local storage
    if (StorageService.getItem(ONGOING_PURCHASE)) {
      savedState = JSON.parse(StorageService.getItem(ONGOING_PURCHASE))
      savedState.selectedOption = Object.assign({}, defaultOption, { liabilityLimit: Number.parseInt(savedState.selectedOptionLiability) })
      delete savedState.selectedOptionLiability
      // Object.assign(savedState, { startingDate: new Date() < new Date(savedState.startingDate) ? savedState.startingDate : new Date() })
      // if is logged in, remove saved drones and equipment when loading the page (will be fetched)
      if (SessionService.isLoggedIn()) {
        Object.assign(savedState, { drones: [], equipment: [] })
      }
    }

    return Object.assign({}, defaultState, savedState)
  })
  console.log('InsurancePurchaseContextStore:', state)

  // general initializiation
  useEffect(() => {
    // state.equipmentTypes && state.equipmentTypes.length == 0 && fetchData()
    // SessionService.isLoggedIn() && loadUserHull()
    // SessionService.isLoggedIn() && loadAdditionalInsureds();

    let id = EventEmitterService.subscribe(EventKey.FLOW_INSURANCE_PURCHASED_SUCCESS, () => {
      StorageService.removeItem(ONGOING_PURCHASE)
    })

    let id2 = EventEmitterService.subscribe(EventKey.SESSION_USER_LOGOUT, () => {
      StorageService.removeItem(ONGOING_PURCHASE)
      dispatch(new Action('RESET'))
    })

    return function cleanup() {
      EventEmitterService.unsubscribe(EventKey.FLOW_INSURANCE_PURCHASED_SUCCESS, id)
      EventEmitterService.unsubscribe(EventKey.SESSION_USER_LOGOUT, id2)

      //TODO: Deleting the local storage only for broker (?)
      if (SessionService.isBroker()) StorageService.removeItem(ONGOING_PURCHASE)
    }
  }, [])

  useEffect(() => {
    let arr = []
    if (!state.selectedOption.offerId) {
      if (location.pathname.endsWith('monthly')) arr = buyingInsuranceContext.monthlyOffers
      else arr = buyingInsuranceContext.annualOffers
    } else {
      if (state.selectedOption.billingPeriod == BillingPeriod.MONTH) arr = buyingInsuranceContext.monthlyOffers
      else arr = buyingInsuranceContext.annualOffers
    }

    let option = arr.find(option => option.liabilityLimit == state.selectedOption.liabilityLimit)

    option && setSelectedOption(option)
  }, [buyingInsuranceContext.annualOffers, buyingInsuranceContext.monthlyOffers])

  // Incase a quote was parsed (wait for offers to load), quickFlow is True, so skip to the next step of Flow
  useEffect(() => {
    if (state.selectedOption.offerId && location.state && location.state.quickFlow) {
      history.replace()
      EventEmitterService.dispatch(EventKey.FLOW_CUSTOMIZE_FINISH)
    }
  }, [state.selectedOption])

  const setSelectedOption = option => {
    dispatch(new Action('OPTION_CHANGED', option))
  }

  return (
    <InsurancePurchaseContextStore.Provider
      value={{
        ...state,
        dispatch,
        setSelectedOption,
      }}
    >
      {props.children}
    </InsurancePurchaseContextStore.Provider>
  )
}

export default InsurancePurchaseContext
