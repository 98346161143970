import React from 'react'
import { Switch, Route, useRouteMatch, useHistory, useLocation, Redirect } from 'react-router-dom'
import { EventEmitterService, EventKey } from '../../services/EventEmitterService'
import { TakePictureFlow } from '../Camera/TakePictureFlow'
import { OdometerFlowCongratz } from './OdometerFlowCongratz'
import { OdometerFlowFailed } from './OdometerFlowFailed'
import { OdometerPermissionError } from './OdometerPermissionError'
import { OdometerIntro } from './OdometerIntro'
import { BrowserView, MobileView } from 'react-device-detect'
import { DesktopNotAllowed } from '../Camera/DesktopNotAllowed'
import { OdometerFlowCongratzDelayedUpload } from './OdometerFlowCongratsDelayedUpload'
import { OdomterFlowFailedWithNewLink } from './OdomterFlowFailedWithNewLink'

export const OdometerFlowRoutes = () => {
  const { path, url } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()

  // React.useEffect(() => {

  //     EventEmitterService.subscribe(EventKey.CAMERA_PERMISSION_DENIED, () => {
  //         // history.replace('cameraPermissionError')
  //     })
  // }, [])

  return (
    <Switch>
      <Route path={`${path}/pick`}>
        <BrowserView>
          <DesktopNotAllowed />
        </BrowserView>
        <MobileView>
          <OdometerIntro />
        </MobileView>
      </Route>

      <Route path={`${path}/picture`}>
        <BrowserView>
          <DesktopNotAllowed />
        </BrowserView>
        <MobileView style={{ height: '100dvh' }}>
          <TakePictureFlow />
        </MobileView>
      </Route>

      <Route path={`${path}/congrats`}>
        <OdometerFlowCongratz />
      </Route>

      <Route path={`${path}/congratsDelayedUpload`}>
        <OdometerFlowCongratzDelayedUpload />
      </Route>

      <Route path={`${path}/flowFailedWithNewLink`}>
        <OdomterFlowFailedWithNewLink />
      </Route>

      <Route path={`${path}/failed`}>
        <OdometerFlowFailed />
      </Route>
      {/* 
            <Route path={`${path}/cameraPermissionError`}>
                <OdometerPermissionError />
            </Route> */}

      <Route>
        <Redirect
          to={{
            pathname: `${path}/pick`,
            search: window.location.search,
          }}
        />
      </Route>
    </Switch>
  )
}
