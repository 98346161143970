import React from 'react'
import { useLocation } from 'react-router-dom'
import DataLayerService from '../../../services/DataLayerService'
import { reportGTM } from '../Utilities/GTagManger'
import { GreenButton } from './GreenButton'
import { PurpleButton } from './PurpleButton'

export const ContinueButton = ({ onClick, isGreenButton = true, label = 'Continue', hidden = false, className, ...props }) => {
  const location = useLocation()

  const clicked = () => {
    reportGTM(location)
    onClick && onClick()
  }

  return isGreenButton ? (
    <GreenButton className={`poppins-bold ${className}`} style={{ display: hidden ? 'none' : 'block' }} onClick={clicked} {...props}>
      {label}
    </GreenButton>
  ) : (
    <PurpleButton className="poppins-bold" style={{ display: hidden ? 'none' : 'block' }} onClick={clicked} {...props}>
      {label}
    </PurpleButton>
  )
}
