import ApiService, { HTTPMethod } from './api.core'

class BillingApi {
  async getLastPaymentDetails(userId) {
    return await ApiService.fetch(`billing/reports/${userId}/last_payment`, HTTPMethod.GET)
  }

  async getUserAllBillingHistory() {
    return await ApiService.fetch(`billing/reports/all_billing_history`, HTTPMethod.GET)
  }
}

export default new BillingApi()
