import React from 'react'
import { downloadBannerImages, bannerLinkForStorageModeIos, bannerLinkForStorageModeAndroid } from '../DownloadBannerIconsUrls'
import '../DownloadBanner.css'
import './DownloadApp.css'

const DownloadAppMobileBanner = () => {
  return (
    <div className="cong_download_modal storage-mobile">
      <p>
        Mark your bike as stored, faster <br />
        than ever! <span className="pri_text">Download our new app</span>
      </p>
      <div className="download-modal-btns">
        {/* <img src={downloadBannerImages.downloadArrow} alt="arrow" className="d_arrow" /> */}
        <div className="mobile-imgs">
          <img
            onClick={bannerLinkForStorageModeAndroid}
            src={downloadBannerImages.downloadAndroidAppIcon}
            alt="google_play_store"
            className="google_play_store"
          />
          <img onClick={bannerLinkForStorageModeIos} src={downloadBannerImages.downloadIOAppIcon} alt="App_store" className="App_store" />
        </div>
      </div>
    </div>
  )
}

export default DownloadAppMobileBanner
