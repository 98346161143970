import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import loader from '../../assets/loading.json'
import Lottie from 'react-lottie'
import { EventEmitterService, EventKey } from '../../services/EventEmitterService'

class LoadingAnimation extends React.Component {
  props: LoadingAnimationProps

  state = {
    showLoading: false,
  }

  constructor(props) {
    super(props)
    EventEmitterService.subscribe(EventKey.ShowLoader, () => {
      this.setState({ showLoading: true })
    })

    EventEmitterService.subscribe(EventKey.DismissLoader, () => {
      this.setState({ showLoading: false })
    })
  }

  render() {
    let width = window.screen.width < 736 ? '15vw' : '8vw'
    let height = window.screen.width < 736 ? '15vw' : '8vw'
    return (
      <Dialog
        style={{ zIndex: 2000 }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
        BackdropProps={{
          style: {
            backgroundColor: '#ffffff99',
          },
        }}
        open={this.state.showLoading}
      >
        <div style={{ backgroundColor: 'transparent' }}>
          <Lottie
            height={height}
            width={width}
            options={{
              loop: true,
              autoplay: true,
              animationData: loader,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
          />
        </div>
      </Dialog>
    )
  }
}

export default LoadingAnimation
