import React, { useContext, useEffect, useState } from 'react'
import './MarkelLogin.scss'
import { Divider, Grid, useMediaQuery } from '@material-ui/core'
import VoomLogo from '../../../assets/voom_logo.png'
import MarkelLogo from '../../../assets/markel_logo_red.png'
import FlexView from 'react-flexview'
import { LoginInputForm, MissingDataForm, VerificationCodeForm } from './FormsSections'
import { awaitWithLoading } from '../../../services/utils'
import { EventEmitterService, EventKey } from '../../../services/EventEmitterService'
import { SessionContextStore } from '../../../context/SessionContext'
import { ReactComponent as LeftArrow } from '../../../assets/left-arrow.svg'
import { isMobile } from 'react-device-detect'
import analyticsApi from '../../../api/analytics.api'
import { getCookie } from '../../../utilities/cookies'

const missingDataDefaultDate = {
  onSubmitForm: undefined,
  isMissingData: false,
  emailMissing: false,
  phoneMissing: false,
}

const errorDefaultState = {
  title: null,
  message: null,
}

export const MarkelLogin = () => {
  const sessionContext = useContext(SessionContextStore)
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const [error, setError] = useState(errorDefaultState)
  const [missingDataState, setMissingDataState] = useState(missingDataDefaultDate)
  const [openVerificationCode, setOpenVerificationCode] = useState(false)
  const [currPhoneNumber, setCurrPhoneNumber] = useState('')
  const [userData, setUserData] = useState({})
  const [selectedOption, setSelectedOption] = useState('phone')

  useEffect(() => {
    const zendeskIFrame = document.getElementById('launcher')
    if (zendeskIFrame) zendeskIFrame.style = `display: none;`
  }, [])

  const VerificationCodeOnSubmit = async data => {
    const request = {
      phoneNumber: currPhoneNumber,
      code: data.code,
    }
    let res = await awaitWithLoading(sessionContext.markelVerifyCode(request))
    if (res.ok) {
      analyticsApi.addUtmLog('MarkelLogin', getCookie('page_full_url'))
      setError(errorDefaultState)
      setUserData({ code: data.code })
      if (res.parsedData.is_email_missing) {
        setMissingDataState({
          onSubmitForm: 'phoneLoginMissingDataSubmit',
          isMissingData: true,
          emailMissing: res.parsedData.is_email_missing,
          phoneMissing: false,
        })
        setOpenVerificationCode(false)
      } else {
        window.location.href = res.parsedData.deeplink ? res.parsedData.deeplink : '/insurance'
      }
    } else {
      setError({ title: res.parsedData.title, message: res.parsedData.messages && res.parsedData.messages.message })
    }
  }

  const SelectionInput = () => {
    const handleOnClick = updatedSelectedOption => {
      if (updatedSelectedOption !== selectedOption) {
        setSelectedOption(updatedSelectedOption)
        setError(errorDefaultState)
      }
    }

    return (
      <Grid container className="login-options-container">
        <button className={`login-option `} onClick={() => handleOnClick('email')}>
          Email
        </button>
        <button className={`login-option`} onClick={() => handleOnClick('policy')}>
          Policy #
        </button>
        <button className={`login-option`} onClick={() => handleOnClick('phone')}>
          Phone
        </button>
      </Grid>
    )
  }

  const phoneLoginMissingDataSubmit = async data => {
    const request = {
      phoneNumber: currPhoneNumber,
      email: data.email,
      code: userData.code,
    }
    let res = await awaitWithLoading(sessionContext.markelPhoneLoginMissingData(request))
    if (res.ok) {
      window.location.href = res.parsedData.deeplink ? res.parsedData.deeplink : '/insurance'
    }
  }

  const markelPhoneLogin = async data => {
    const request = {
      phoneNumber: data.phone_number.replace(/\D/g, ''),
    }
    let res = await awaitWithLoading(sessionContext.markelPhoneLogin(request))
    if (res.ok) {
      setError(errorDefaultState)
      setOpenVerificationCode(true)
      setCurrPhoneNumber(data.phone_number)
    } else {
      setError({ title: res.parsedData.title, message: res.data.messages && Object.values(res.data.messages)[0] })
    }
  }

  const markelLoginSubmit = async data => {
    if (data.phone_number) {
      markelPhoneLogin(data)
      return
    }
    let cleanedPhoneNumber = data.phone_number && data.phone_number.replace(/[-()\s]/g, '')

    const makelLoginRequest = {
      email: data.email,
      policyNumber: data.policy_number,
      phoneNumber: cleanedPhoneNumber,
      lastName: data.last_name,
      dateOfBirth: data.date_of_birth,
      zipCode: data.zip_code,
    }
    let res = await awaitWithLoading(sessionContext.markelLogin(makelLoginRequest))
    if (res.ok) {
      analyticsApi.addUtmLog('MarkelLogin', getCookie('page_full_url'))
      setError(errorDefaultState)
      //if inforamtion is missing:
      if (res.parsedData.is_email_missing || res.parsedData.is_phone_number_missing) {
        setMissingDataState({
          onSubmitForm: 'missingDataSubmit',
          isMissingData: true,
          emailMissing: res.parsedData.is_email_missing,
          phoneMissing: res.parsedData.is_phone_number_missing,
        })
        setUserData({
          lastName: data.last_name,
          dateOfBirth: data.date_of_birth,
          zipCode: data.zip_code,
        })
      } else {
        window.location.href = res.parsedData.deeplink ? res.parsedData.deeplink : '/insurance'
      }
    } else {
      if (res.status === 400) {
        setError({
          title: res.data.title,
          message: res.data.messages && Object.values(res.data.messages)[0],
        })
      } else {
        EventEmitterService.dispatch(EventKey.ShowError, res)
      }
    }
    console.log(res)
  }

  const missingDataSubmit = async data => {
    let cleanedPhoneNumber = data.phone_number && data.phone_number.replace(/[-()\s]/g, '')
    const makelLoginMissingDataRequest = {
      email: data.email,
      phoneNumber: cleanedPhoneNumber,
      lastName: userData.lastName,
      dateOfBirth: userData.dateOfBirth,
      zipCode: userData.zipCode,
    }
    let res = await awaitWithLoading(sessionContext.markelLoginMissingData(makelLoginMissingDataRequest))
    if (res.ok) {
      setError(errorDefaultState)
      setUserData({})
      window.location.href = res.parsedData.deeplink ? res.parsedData.deeplink : '/insurance'
    } else {
      if (res.status === 400) {
        setError({
          title: res.data.title,
          message: res.data.messages && Object.values(res.data.messages)[0],
        })
      } else {
        EventEmitterService.dispatch(EventKey.ShowError, res)
      }
    }
  }

  return (
    <Grid column={isMobile} className="page" container>
      <Grid item xs={12} md={6} className="backgroundColor  order-1">
        <Grid container className={`info-section info-section-width${isMobile ? '-mobile' : ''}`}>
          <Grid container>
            <Grid item xs={12} className={`flex-end ${isMobile ? 'margin-top-basic margin-bottom-basic' : 'margin-top-xlarge'}`}>
              {!openVerificationCode && (
                <>
                  <LoginHeader />
                  {!missingDataState.isMissingData ? (
                    <>
                      <LoginSubHeader selectedOption={selectedOption} />
                      <LoginInputForm onSubmitForm={markelLoginSubmit} errorObj={error} selectedOption={selectedOption} />
                      <LoginFooter />
                      <TextDivider text="Log in with" />
                      <SelectionInput />
                    </>
                  ) : (
                    <>
                      <LoginSubHeaderMissingData />
                      <MissingDataForm
                        onSubmitForm={
                          missingDataState.onSubmitForm === 'missingDataSubmit' ? missingDataSubmit : phoneLoginMissingDataSubmit
                        }
                        isEmailMissing={missingDataState.emailMissing}
                        isPhoneNumberMissing={missingDataState.phoneMissing}
                        errorObj={error}
                      />
                      <LoginFooter />
                    </>
                  )}
                </>
              )}
              {openVerificationCode && (
                <>
                  {isMobile && (
                    <>
                      <Grid container className="space-between-markel-login">
                        <LeftArrow
                          onClick={() => {
                            setError(errorDefaultState)
                            setOpenVerificationCode(false)
                            setCurrPhoneNumber('')
                          }}
                        />
                        <Grid>
                          <VoomByMarkel />
                        </Grid>
                        <div></div>
                      </Grid>
                      <Divider className="divider" />
                    </>
                  )}
                  <VerificationCodeForm
                    onSubmitForm={VerificationCodeOnSubmit}
                    onResend={markelPhoneLogin}
                    errorObj={error}
                    onGoBack={() => {
                      setError(errorDefaultState)
                      setOpenVerificationCode(false)
                      setCurrPhoneNumber('')
                    }}
                    currPhoneNumber={currPhoneNumber}
                  />
                </>
              )}
            </Grid>
          </Grid>
          {!isMobile && (
            <Grid container className="flex-end justify-content-center">
              {openVerificationCode && <VoomByMarkel className="flex-end" />}
              <Footer className="flex-end padding-bottom-30 padding-top-16 " />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className={` markel-login-bg-image${isMobile ? '-mobile' : ''} background-image${isMobile ? '-mobile' : ''}  order-${
          isMobile ? 1 : 2
        }`}
      ></Grid>
      {isMobile && <Footer className="flex-end order-3 padding-bottom-30 padding-top-16 width-full" />}
    </Grid>
  )
}

const Footer = ({ className }) => {
  return (
    <FlexView column vAlignContent="center" className={`text-xs text-center light-gray footer ${className}`}>
      <div>By logging in, you acknowledge and agree to VOOM’s</div>
      <div>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.voominsurance.com/terms-of-service"
          className="gray underline clickable"
        >
          General Terms Of Use
        </a>{' '}
        and{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.voominsurance.com/privacy-policy"
          className="gray underline clickable"
        >
          Privacy Policy
        </a>
      </div>
    </FlexView>
  )
}
const LoginFooter = () => {
  return (
    <div className="text-sm margin-bottom-24 text-center">
      Need a hand? just give us a call at <br></br>
      <span className="font-semibold">(888)-851-4828</span>
    </div>
  )
}

const LoginHeader = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  return (
    <FlexView column vAlignContent="center" className="margin-bottom-6 text-2xl font-medium">
      <div className="margin-bottom-6">
        Welcome to <img className={`logo${isMobile ? '-mobile' : ''}`} src={VoomLogo} /> {!isMobile && <br />} Motorcycle Insurance backed
        by <img className="logo" src={MarkelLogo} />
      </div>

      <Divider className="divider" />
    </FlexView>
  )
}

const TextDivider = ({ text }) => {
  return (
    <FlexView column vAlignContent="center" className="flex-row-markel-login space-between-markel-login">
      <Divider className="divider width-35" />
      <div className="divider-text">{text}</div>
      <Divider className="divider width-35" />
    </FlexView>
  )
}

const LoginSubHeader = ({ selectedOption }) => {
  const selectedOptionRep = selectedOption === 'phone' ? 'phone number' : selectedOption === 'email' ? 'Email address' : 'policy number'

  return (
    <FlexView column vAlignContent="center">
      <div className="text-base font-semibold">Enter your {selectedOptionRep} and renew your Markel's policy with VOOM</div>
    </FlexView>
  )
}

const VoomByMarkel = ({ className }) => {
  return (
    <FlexView column vAlignContent="center" className={`gap-10 flex-row-markel-login font-medium ${className}`}>
      <div>
        <img className="height-15" src={VoomLogo} />
      </div>
      <div className="text-sm font-bold">by</div>
      <div>
        <img className="height-15" src={MarkelLogo} />
      </div>
    </FlexView>
  )
}

const LoginSubHeaderMissingData = () => {
  return (
    <FlexView column vAlignContent="center" className="margin-bottom-24">
      <div className="text-base font-semibold">Looks like we're missing some info. Share it with us to get your quote!</div>
    </FlexView>
  )
}
