export const InsuranceStatus = {
  ACTIVE: 'Active',
  SUSPENDED: 'Paused',
  SCHEDULED: 'Scheduled',
  PENDING: 'Pending',
  RENEWAL: 'Renewal',
  EXPIRED: 'Expired',
  CANCELED: 'Canceled',
  FAILED_ACTIVATION: 'FailedActivation',
}
