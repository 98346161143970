import ApiService, { HTTPMethod } from './api.core'
import dayjs from 'dayjs'

class LoginModel {
  token: string
  // expiration: Date;

  constructor(json: any) {
    this.token = json.token
    // this.expiration = new Date(json.expiration);
  }
}

export class AuthApi {
  static async login(email, password): Promise<ApiResponseModel> {
    let resp = await ApiService.fetch('auth/token', HTTPMethod.POST, {
      email: email,
      password: password,
    })
    if (resp.ok) {
      resp.parsedData = new LoginModel(resp.data)
    }
    return resp
  }

  static async deeplinkTokenEntrance(profile, token) {
    let resp = await ApiService.fetch(`auth/deeplinkToken?profile=${profile}&token=${token}`, HTTPMethod.GET)
    return resp
  }

  static async passwordlessLogin(email, lastname, dob, zip): Promise<ApiResponseModel> {
    let resp = await ApiService.fetch('auth/passwordless_login', HTTPMethod.POST, {
      email: email,
      last_name: lastname,
      date_of_birth: dayjs(dob).format('YYYY-MM-DD'),
      zip_code: zip,
    })
    if (resp.ok) {
      resp.parsedData = new LoginModel(resp.data)
    }
    return resp
  }

  static async logout() {
    return await ApiService.fetch('auth/logout')
  }

  static async socialLogin(email, token, serviceName) {
    let resp = await ApiService.fetch(`auth/${serviceName}/token`, HTTPMethod.POST, {
      access_token: token,
    })
    resp.parsedData = resp.data
    return resp
  }

  static async register(email, password) {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    let resp = await ApiService.fetch('auth/register', HTTPMethod.POST, {
      email: email,
      password: password,
      utm_campaign: params.utm_campaign,
      utm_medium: params.utm_medium,
      utm_source: params.utm_source,
      quote_external_id: params.lead_id,
    })
    resp.parsedData = resp.data
    return resp
  }

  static async registerPasswordless(email, zipCode, state) {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    let resp = await ApiService.fetch('auth/register-passwordless', HTTPMethod.POST, {
      email: email,
      utm_campaign: params.utm_campaign,
      utm_medium: params.utm_medium,
      utm_source: params.utm_source,
      viral_referral_code: params.referralCode,
      viral_referral_source: params.refSource,
      quote_external_id: params.lead_id,
      zipcode: zipCode,
      administrative_division: state,
    })
    resp.parsedData = resp.data
    return resp
  }

  static async sendForgotPasswordEmail(email) {
    return ApiService.fetch('auth/forgot_password', HTTPMethod.POST, {
      email: email,
    })
  }

  static async checkIfUserExist(userName) {
    return await ApiService.fetch(`auth/${userName}/check_if_user_exist`, HTTPMethod.GET)
  }

  static async markelLogin(loginRequest) {
    let res = await ApiService.fetch('auth/markel_login', HTTPMethod.POST, {
      email: loginRequest.email,
      policy_number: loginRequest.policyNumber,
      phone_number: loginRequest.phoneNumber,
      last_name: loginRequest.lastName,
      date_of_birth: loginRequest.dateOfBirth.toJSON().split('T')[0],
      zip_code: loginRequest.zipCode,
    })
    res.parsedData = res.data
    return res
  }

  static async markelPhoneLogin(phoneLoginRequest) {
    let res = await ApiService.fetch('auth/markel_phone_login', HTTPMethod.POST, {
      phone_number: phoneLoginRequest.phoneNumber,
    })
    res.parsedData = res.data
    return res
  }

  static async markelVerifyCode(verifyCodeRequest) {
    let res = await ApiService.fetch('auth/markel_verify_code_login', HTTPMethod.POST, {
      phone_number: verifyCodeRequest.phoneNumber,
      code: verifyCodeRequest.code,
    })
    res.parsedData = res.data
    return res
  }

  static async markelPhoneLoginMissingData(missingDataLoginRequest) {
    let res = await ApiService.fetch('auth/markel_phone_login_missing_details', HTTPMethod.POST, {
      email: missingDataLoginRequest.email,
      phone_number: missingDataLoginRequest.phoneNumber,
      code: missingDataLoginRequest.code,
    })
    res.parsedData = res.data
    return res
  }

  static async markelLoginMissingData(missingDataLoginRequest) {
    let res = await ApiService.fetch('auth/markel_login_missing_details', HTTPMethod.POST, {
      email: missingDataLoginRequest.email,
      phone_number: missingDataLoginRequest.phoneNumber,
      last_name: missingDataLoginRequest.lastName,
      date_of_birth: missingDataLoginRequest.dateOfBirth.toJSON().split('T')[0],
      zip_code: missingDataLoginRequest.zipCode,
    })

    res.parsedData = res.data
    return res
  }
}
