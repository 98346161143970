import { FACING_MODES } from 'jslib-html5-camera-photo'
import React, { useContext } from 'react'
import FlexView from 'react-flexview/lib'
import { useLocation } from 'react-router-dom'
import { SessionContextStore } from '../../context/SessionContext'
// import Camera , {FACING_MODES} from 'react-html5-camera-photo';
// import 'react-html5-camera-photo/build/css/index.css';
import './CameraWithTemplate.scss'
import NewCamera from './NewCamera'
import queryString from 'query-string'
import { Button, makeStyles, TextField } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import vehiclesApi from '../../api/vehicles.api'
import { awaitWithLoading, vinValidation } from '../../services/utils'
import { EventEmitterService, EventKey } from '../../services/EventEmitterService'
import { GlobalTextField } from '../Core/Input/ControlledTextField'
import { VIN_EXPLANATIONS } from '../../Constants'
const classnames = require('classnames')

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'gray',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'gray',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'gray',
    },
    '& .MuiOutlinedInput-input': {
      color: 'gray',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
      color: 'gray',
    },
  },
})

export const CameraWithTemplate = ({ handleTakePhotoAnimationDone, isVinUploadNeeded = true, ...props }) => {
  const sessionContext = useContext(SessionContextStore)
  const { register, triggerValidation, errors, getValues } = useForm({ mode: 'onChange' })
  const [vinUploaded, setVinUploaded] = React.useState(false)
  const location = useLocation()
  const params = queryString.parse(location.search)
  const classes = useStyles()

  const save = async () => {
    let valid = await triggerValidation()
    if (!valid) return

    let res = await awaitWithLoading(vehiclesApi.updateVin(props.insuranceId, props.requestId, getValues()['vin'].trim()))
    if (res.ok) {
      setVinUploaded(true)
    } else {
      EventEmitterService.dispatch(EventKey.ShowError, res)
    }
  }

  return (
    <>
      {/* <div id="rectangle"></div> */}
      {isVinUploadNeeded == true ? (
        <>
          <FlexView
            hAlignContent={'center'}
            className={`camera_overlay ${!vinUploaded ? 'camera-background' : ''}`}
            vAlignContent={'bottom'}
          >
            <FlexView className="logo-on-camera" hAlignContent={'center'}>
              <img src={`${process.env.PUBLIC_URL}/assets/voom_white.png`} style={{ height: 38 }} />
            </FlexView>
            <br />
            {/* <div className="white textcenter margin-top-basic text-title">Hey {sessionContext.user.firstName ? `${sessionContext.user.firstName}`: 'VOOMer'},</div> */}
            {/* <div className="white textcenter margin-top-basic text-title">Hi there,</div> */}
            <div
              className={classnames(
                `white textcenter subtextoverlay text-title ${!vinUploaded ? 'margin-top-xxlarge' : 'margin-top-xlarge'}`
              )}
            >
              <div className="margin-top-basic">
                {!vinUploaded && (
                  <>
                    <div className="white bold margin-top-large">Upload your VIN number</div>
                    <FlexView className="margin-bottom-large margin-top-large gray">
                      <GlobalTextField
                        className={classes.root}
                        fullWidth
                        inputRef={register({ required: true, maxLength: 17, minLength: 17 })}
                        name="vin"
                        inputProps={{ style: { textTransform: 'uppercase' } }}
                        helperText={errors.vin && (errors.vin.type == 'required' ? 'Required' : 'Vin must contain 17 characters')}
                        shrink={false}
                        label={''}
                        errors={errors}
                        onChange={vinValidation}
                      />
                      <Button className="white bold" onClick={save}>
                        Next
                      </Button>
                    </FlexView>
                    <div className="text-small bold margin-top-xlarge">Where are VINs located?</div>
                    <div className="text-tiny">{VIN_EXPLANATIONS}</div>
                  </>
                )}
              </div>
              `{' '}
              {vinUploaded && (
                <div className="margin-top-medium">
                  <div>Snap a photo of your</div>
                  <div>{params.name}</div>
                  <div>
                    <span className="bold">odometer</span> within the box
                  </div>
                </div>
              )}
            </div>
          </FlexView>
          {vinUploaded && <NewCamera idealFacingMode={FACING_MODES.ENVIRONMENT} onTakePhotoAnimationDone={handleTakePhotoAnimationDone} />}
        </>
      ) : (
        <>
          <FlexView hAlignContent={'center'} className="camera_overlay" vAlignContent={'bottom'}>
            <FlexView className="logo-on-camera" hAlignContent={'center'}>
              <img src={`${process.env.PUBLIC_URL}/assets/voom_white.png`} style={{ height: 38 }} />
            </FlexView>
            <br />
            {/* <div className="white textcenter margin-top-basic text-title">Hey {sessionContext.user.firstName ? `${sessionContext.user.firstName}`: 'VOOMer'},</div> */}
            {/* <div className="white textcenter margin-top-basic text-title">Hi there,</div> */}

            <div className={classnames('white textcenter subtextoverlay text-title ')}>
              <div className="margin-top-medium">
                <div>Snap a photo of your</div>
                <div>{params.name}</div>
                <div>
                  <span className="bold">odometer</span> within the box
                </div>
              </div>
            </div>
          </FlexView>
          {<NewCamera idealFacingMode={FACING_MODES.ENVIRONMENT} onTakePhotoAnimationDone={handleTakePhotoAnimationDone} />}
        </>
      )}
    </>
  )
}
