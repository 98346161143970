import React from 'react'

const PhoneIcon = props => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.48356 7.87769C8.06356 9.08571 8.85422 10.2179 9.85553 11.2192C10.8568 12.2205 11.9891 13.0112 13.1971 13.5912C13.301 13.6411 13.3529 13.666 13.4187 13.6852C13.6523 13.7533 13.9392 13.7044 14.137 13.5627C14.1927 13.5228 14.2403 13.4752 14.3356 13.3799C14.6269 13.0886 14.7726 12.9429 14.9191 12.8477C15.4715 12.4885 16.1837 12.4885 16.7361 12.8477C16.8825 12.9429 17.0282 13.0886 17.3196 13.3799L17.4819 13.5423C17.9248 13.9852 18.1462 14.2066 18.2665 14.4445C18.5058 14.9174 18.5058 15.476 18.2665 15.9489C18.1462 16.1868 17.9248 16.4082 17.4819 16.8511L17.3506 16.9824C16.9092 17.4238 16.6886 17.6445 16.3885 17.813C16.0556 18 15.5385 18.1345 15.1567 18.1333C14.8126 18.1323 14.5774 18.0656 14.107 17.9321C11.5792 17.2146 9.19387 15.8609 7.20388 13.8709C5.2139 11.8809 3.86017 9.49559 3.1427 6.96776C3.00919 6.49739 2.94244 6.2622 2.94141 5.91808C2.94028 5.53623 3.07475 5.01915 3.26176 4.68623C3.4303 4.3862 3.65098 4.16553 4.09233 3.72417L4.22369 3.59281C4.66656 3.14994 4.888 2.92851 5.12581 2.80822C5.59878 2.569 6.15734 2.569 6.6303 2.80822C6.86812 2.92851 7.08955 3.14994 7.53242 3.59281L7.69481 3.7552C7.98615 4.04654 8.13182 4.19221 8.22706 4.33869C8.58622 4.8911 8.58622 5.60325 8.22706 6.15566C8.13182 6.30214 7.98615 6.44781 7.69481 6.73915C7.59955 6.83441 7.55192 6.88204 7.51206 6.93772C7.37038 7.13558 7.32146 7.42246 7.38957 7.65609C7.40873 7.72183 7.43367 7.77378 7.48356 7.87769Z"
      stroke="inherit"
      stroke-width="inherit"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export default PhoneIcon
