import React, { useEffect, useReducer } from 'react'
import statesApi from '../api/states.api'
import { awaitWithLoading } from '../services/utils'

export const ACTIONS = {
  SAVE_STATES_CONFIGURATION: 'SAVE_STATES_CONFIGURATION',
}

const defaultState = {
  dispatch: (action: Action) => {},
  estimated_mileage_first_billing_states: [],
  billing_flow_type_states: {},
  residence_insurance_factor_states: [],
  no_initial_payment_fee: [],
  new_estimated_flow: [],
}

const reducer = (state, action: Action) => {
  let newState = state
  switch (action.type) {
    case ACTIONS.SAVE_STATES_CONFIGURATION:
      {
        newState = { ...state, ...action.data }
      }
      break
    default:
      newState = state
      break
  }

  return newState
}

export const StatesContextStore = React.createContext(defaultState)
StatesContextStore.displayName = 'States ContextStore'

const StatesContext = props => {
  const [state, dispatch] = useReducer(reducer, defaultState)
  const getStateConfiguration = async () => {
    let res = await statesApi.getStatesConfiguration()
    if (res.ok) {
      dispatch({ type: ACTIONS.SAVE_STATES_CONFIGURATION, data: res.data })
    }
  }

  useEffect(() => {
    getStateConfiguration()
  }, [])

  const isEstimatedMileageFirstBillingState = stateCode => {
    return state.estimated_mileage_first_billing_states.includes(stateCode)
  }

  return (
    <StatesContextStore.Provider value={{ ...state, dispatch, isEstimatedMileageFirstBillingState }}>
      {props.children}
    </StatesContextStore.Provider>
  )
}

export default StatesContext
