import { createTheme } from '@material-ui/core/styles'
import BrokerConfigService from '../services/BrokerConfigService'

export function createSkywatchMaterialTheme(theme) {
  return createTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: theme.blue,
        contrastText: '#fff',

        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: theme.secondary,
        // dark: will be calculated from palette.secondary.main,
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
    typography: {
      fontFamily: [
        'Nunito Sans',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          background: '#f5f9fd',
          color: 'black',
          fontWeight: 300,

          '&$focused $notchedOutline': {
            borderColor: theme.blue,
            borderWidth: 1,
          },
          '& fieldset': {
            borderColor: '#dbe5f5',
          },
          '&:hover fieldset': {
            borderColor: `${theme.blue} !important`,
          },
        },
      },
      MuiFormLabel: {
        root: {
          '&$focused': {
            color: theme.blue,
          },
          color: '#8898b5',
          fontWeight: 500,
        },
      },
      MuiInputBase: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },
  })
}
