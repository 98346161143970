export const parseOfferViolations = data => {
  let err = {}
  Object.keys(data.messages).map(vehicleId => {
    err[vehicleId] = {}
    data.messages[vehicleId]
      .map(q => JSON.parse(q))
      .map(q => {
        err[vehicleId][q.coverage_name] = q.error
      })
  })

  const policyLevelCoverages = [
    'bodily_injury_property_damage',
    'bodily_injury_liability',
    'bodily_injury_underinsured',
    'bodily_injury_uninsured',
    'bodily_injury_uninsured_underinsured',
    'medical_payments',
    'personal_injury_protection',
    'uninsured_underinsured_motorist_property_damage',
    'pedestrian_personal_injury_protection',
    'bodily_injury_enhanced_underinsured',
    'enhanced_underinsured_motorist_property_damage',
    'uninsured_motorist_property_damage',
  ]
  let policyLevelErrors = {}
  Object.values(data.messages)
    .flatMap(coverageViolations => coverageViolations)
    .map(coverage => JSON.parse(coverage))
    .filter(coverage => policyLevelCoverages.includes(coverage.coverage_name))
    .map(coverage => {
      policyLevelErrors[coverage.coverage_name] = coverage.error
    })

  err['policy'] = policyLevelErrors

  return err
}
