import React from 'react'
import { Switch, Route, useRouteMatch, useHistory, useLocation, Redirect } from 'react-router-dom'
import { StorageModeCongrats } from './StorageModeCongrats'
import { StorageModeSigningForm } from './StorageModeSigningForm'

export const StorageModeFlowRoutes = () => {
  const { path, url } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()

  return (
    <Switch>
      <Route path={`${path}/signing`}>
        <StorageModeSigningForm />
      </Route>

      <Route path={`${path}/congrats`}>
        <StorageModeCongrats />
      </Route>
      <Route>
        <Redirect
          to={{
            pathname: `${path}/signing`,
            search: window.location.search,
          }}
        />
      </Route>
    </Switch>
  )
}
