import { Grid } from '@material-ui/core'
import React from 'react'
import FlexView from 'react-flexview/lib'
import './OnlineDigitalSiginingForm.scss'
import { ReactComponent as ShieldSvg } from '../../assets/shield_black_purple.svg'

export const OnlineDigitalSigninFormCongrats = () => {
  return (
    <React.Fragment>
      <div className="congrats_page">
        <Grid item container spacing={3} className="margin-top-large">
          <Grid xs={12} className="congrats_title margin-bottom-medium">
            <FlexView vAlignContent="center" hAlignContent="center">
              Form Signed <br />
              And Recieved
            </FlexView>
          </Grid>
          <Grid xs={12} direction={'column'} className="textcenter">
            <FlexView className="margin-bottom-small" vAlignContent="center" hAlignContent="center">
              Thank you!
            </FlexView>
            <FlexView className="margin-bottom-small" vAlignContent="center" hAlignContent="center">
              <div>
                We are here whenever needed <a href="mailto:support@voominsurance.com">support@voominsurance.com</a>
              </div>
            </FlexView>
          </Grid>
          <Grid xs={12}>
            <FlexView vAlignContent="center" hAlignContent="center" className="margin-top-medium">
              <ShieldSvg className="x-large-icon" />
            </FlexView>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}
