export class Resizer {
  static resizeAndRotateImage(image, idealHeight, idealWidth, compressFormat = 'jpeg', quality = 100, rotation = 0) {
    let qualityDecimal = quality / 100
    let canvas = document.createElement('canvas')

    canvas.width = idealWidth
    canvas.height = idealHeight

    let ctx = canvas.getContext('2d')

    if (rotation) {
      ctx.rotate((rotation * Math.PI) / 180)
      if (rotation === 90) {
        ctx.translate(0, -canvas.width)
      } else if (rotation === 180) {
        ctx.translate(-canvas.width, -canvas.height)
      } else if (rotation === 270) {
        ctx.translate(-canvas.height, 0)
      } else if (rotation === 0 || rotation === 360) {
        ctx.translate(0, 0)
      }
    }
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height)

    return canvas.toDataURL(`image/${compressFormat}`, qualityDecimal)
  }

  static b64toBlob(b64Data, contentType = 'image/jpeg') {
    let sliceSize = 512

    let byteCharacters = atob(b64Data.toString().replace(/^data:image\/(png|jpeg|jpg);base64,/, ''))
    let byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize)

      let byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      let byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    let blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  static createResizedImage(file, maxWidth, maxHeight, compressFormat, quality, rotation, outputType = 'base64') {
    return new Promise(resolve => {
      let blob = null
      const reader = new FileReader()
      if (file) {
        reader.readAsDataURL(file)
        reader.onload = () => {
          let image = new Image()
          image.src = reader.result
          image.onload = function () {
            let resizedDataUrl = Resizer.resizeAndRotateImage(image, maxWidth, maxHeight, compressFormat, quality, rotation)
            blob = Resizer.b64toBlob(resizedDataUrl, `image/${compressFormat}`)
            outputType === 'blob' ? resolve(blob) : resolve(resizedDataUrl)
          }
        }
        reader.onerror = error => {
          resolve(error)
        }
      } else {
        resolve('File Not Found')
      }
    })
  }
}
export default {
  imageFileResizer: (file, maxWidth, maxHeight, compressFormat, quality, rotation) => {
    return Resizer.createResizedImage(file, maxWidth, maxHeight, compressFormat, quality, rotation)
  },
}
