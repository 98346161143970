import React from 'react'
import FlexView from 'react-flexview/lib'
import CheckIcon from '@material-ui/icons/Check'
import { Grow, IconButton } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

export const OdometerFlowFailed = () => {
  const [show, setShow] = React.useState(false)

  const location = useLocation()
  const params = queryString.parse(location.search)

  React.useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 200)
  }, [])

  return (
    <FlexView column className="priview-background">
      <FlexView column className="priview-background2" hAlignContent={'center'} vAlignContent={'center'}>
        <FlexView className="logo-on-camera" hAlignContent={'center'} style={{ position: 'absolute', top: 10 }}>
          <img src={`${process.env.PUBLIC_URL}/assets/voom_white.png`} style={{ height: 38 }} />
        </FlexView>
        <FlexView column vAlignContent={'center'} className="preview-content">
          <Grow in={show} style={{ transformOrigin: '0 0 0' }} {...(show ? { timeout: 1000 } : {})}>
            <FlexView hAlignContent={'center'} column vAlignContent={'center'} className="preview-title-top">
              <img style={{ height: 200 }} src={process.env.PUBLIC_URL + '/assets/x-sign.png'} />

              <div className="text-title bolder white textcenter margin-top-basic margin-bottom-basic">
                <div>Upload Failed</div>
                <div className="margin-top-3">{location.error_text}</div>
              </div>
            </FlexView>
          </Grow>
        </FlexView>
      </FlexView>
    </FlexView>
  )
}
