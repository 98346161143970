import React, { useReducer } from 'react'
import { Action } from '../models/Action'
import PolicyManagementApi from '../api/policymanagement.api'
import { INSURANCE_CONTEXT_ACTIONS } from './InsuracePurchaseContext'
import { EventEmitterService, EventKey } from '../services/EventEmitterService'
import { UserApi } from '../api/user.api'
import { removeDuplicateAdditionalInsured } from '../services/utils'
import policymanagementApi from '../api/policymanagement.api'
import { SessionService } from '../services/SessionService'
import BillingApi from '../api/billing.api'
import { InsuranceStatus } from '../models/insurance-models/InsuranceStatus'

export const ACTIONS = {
  LOAD_POLICIES: 'LOAD_POLICIES',
  LOADING_POLICIES: 'LOADING_POLICIES',
  LOAD_BILLING_REPORT: 'LOAD_BILLING_REPORT',
  TOGGLE_AUTO_RENEW: 'SET_AUTO_RENEW',
  EQUIPMENT_TYPES_LOADED: 'EQUIPMENT_TYPES_LOADED',
  UPDATE_INSURANCE: 'UPDATE_INSURANCE',
  SCHEDULED_INSURANCE_CANCELED: 'SCHEDULED_INSURANCE_CANCELED',
  TOTAL_ADDITIONAL_INSUREDS_UPDATED: 'TOTAL_ADDITIONAL_INSUREDS_UPDATED',
}

const defaultState = {
  dispatch: (action: Action) => {},
  getInsurance: insuranceId => {},
  all_policies: [],
  active_policies: [],
  pending_policies: [],
  scheduled_policies: [],
  expired_policies: [],
  renewal_policies: [],
  canceled_policies: [],
  allAdditionalInsuredList: [],
  loading_policies: false,
  billing_reports: [],
}

const reducer = (state, action: Action) => {
  console.log('PolicyManagementContext state', state, action)
  switch (action.type) {
    case ACTIONS.LOAD_POLICIES:
      return {
        ...state,
        all_policies: [
          ...action.data.active_policies,
          ...action.data.pending_policies,
          ...action.data.scheduled_policies,
          ...action.data.expired_policies,
          ...action.data.renewal_policies,
          ...action.data.canceled_policies,
        ],
        active_policies: action.data.active_policies,
        pending_policies: action.data.pending_policies,
        scheduled_policies: action.data.scheduled_policies,
        expired_policies: action.data.expired_policies,
        renewal_policies: action.data.renewal_policies,
        canceled_policies: action.data.canceled_policies,
        loading_policies: false,
      }
    case ACTIONS.LOADING_POLICIES:
      return { ...state, loading_policies: true }
    case ACTIONS.LOAD_BILLING_REPORT:
      return { ...state, billing_reports: action.data.billing_reports }
    case ACTIONS.SCHEDULED_INSURANCE_CANCELED:
      return {
        ...state,
        scheduled_policies: state.scheduled_policies.filter(q => q.insuranceId != action.data.insuranceId),
      }
    case ACTIONS.TOGGLE_AUTO_RENEW:
      return {
        ...state,
        active_policies: state.active_policies.map(i =>
          i.insuranceId == action.data.insuranceId ? Object.assign(i, { autoRenew: !i.autoRenew }) : i
        ),
        scheduled_policies: state.scheduled_policies.map(i =>
          i.insuranceId == action.data.insuranceId ? Object.assign(i, { autoRenew: !i.autoRenew }) : i
        ),
      }
    case ACTIONS.TOTAL_ADDITIONAL_INSUREDS_UPDATED:
      return {
        ...state,
        allAdditionalInsuredList: action.data.additional_insureds,
      }
    case ACTIONS.UPDATE_INSURANCE:
    case INSURANCE_CONTEXT_ACTIONS.EDIT_ADDITIONAL_INSURED:
    case INSURANCE_CONTEXT_ACTIONS.DELETE_ADDITIONAL_INSURED:
    case INSURANCE_CONTEXT_ACTIONS.ADD_ADDITIONAL_INSURED:
    case INSURANCE_CONTEXT_ACTIONS.ACTIVE_ADDITIONAL_INSUREDS_UPDATED:
      if (!action.data.insurance) return state
      return {
        ...state,
        active_policies: state.active_policies.map(i =>
          i.insuranceId == action.data.insurance.insuranceId ? Object.assign(i, action.data.insurance) : i
        ),
        scheduled_policies: state.scheduled_policies.map(i =>
          i.insuranceId == action.data.insurance.insuranceId ? Object.assign(i, action.data.insurance) : i
        ),
      }

    default:
      return state
  }
}

export const PolicyManagementContextStore = React.createContext(defaultState)
PolicyManagementContextStore.displayName = 'Policy Management Context'

const PolicyManagementContext = props => {
  const [state, dispatch] = useReducer(reducer, defaultState)

  const loadPolicies = async () => {
    dispatch(new Action(ACTIONS.LOADING_POLICIES))
    let res = await PolicyManagementApi.getAllInsurances()
    if (res.ok) {
      let dt = new Date()
      let activeInsurances = res.parsedData.filter(item => item.policyStatus == InsuranceStatus.ACTIVE)
      let pendingPolicies = res.parsedData.filter(item => item.policyStatus == InsuranceStatus.PENDING)
      dispatch(
        new Action(ACTIONS.LOAD_POLICIES, {
          active_policies: res.parsedData.filter(item => item.policyStatus == InsuranceStatus.ACTIVE),
          pending_policies: res.parsedData.filter(item => item.policyStatus == InsuranceStatus.PENDING),
          scheduled_policies: res.parsedData.filter(item => item.policyStatus == InsuranceStatus.SCHEDULED && item.tenure == 0),
          expired_policies: res.parsedData.filter(item => item.policyStatus == InsuranceStatus.EXPIRED),
          renewal_policies: res.parsedData.filter(item => item.policyStatus == InsuranceStatus.SCHEDULED && item.tenure > 0),
          canceled_policies: res.parsedData.filter(
            item => item.policyStatus == InsuranceStatus.CANCELED || item.policyStatus == InsuranceStatus.FAILED_ACTIVATION
          ),
        })
      )
      if (activeInsurances && activeInsurances.length > 0) {
        let firstInsurance = activeInsurances[0]
        EventEmitterService.dispatch(EventKey.RECEIVED_ACTIVE_INSURANCE, firstInsurance)
      }
      let response = await BillingApi.getUserAllBillingHistory()
      if (response.ok) {
        let billingReportList = []
        response.data.forEach(billing => {
          billingReportList.push(billing.billing_reports)
        })
        dispatch(
          new Action(ACTIONS.LOAD_BILLING_REPORT, {
            billing_reports: billingReportList,
          })
        )
      }
    }
  }

  const getInsurance = insuranceId => {
    return state.all_policies.find(insurance => insurance.insuranceId == insuranceId)
  }

  const anyRoadSidePolicies = async () => {
    let res = await policymanagementApi.anyRoadSideInsurance()
    return res
  }

  const cancel = async (insuranceId, reason, comment) => {
    let res = await policymanagementApi.cancel(insuranceId, reason, comment)
    if (res.ok) {
      loadPolicies()
    }
    return res
  }

  const cancelPolicyWithoutOdometer = async (insuranceId, reason, comment) => {
    let res = await policymanagementApi.cancelPolicyWithoutOdometer(insuranceId, reason, comment)
    if (res.ok) {
      loadPolicies()
    }
    return res
  }

  const cancelPolicyWithoutPayment = async (insuranceId, reason, comment) => {
    let res = await policymanagementApi.cancelPolicyWithoutPayment(insuranceId, reason, comment)
    if (res.ok) {
      loadPolicies()
    }
    return res
  }

  const cancelRenwalPolicy = async (insuranceId, reason, comment) => {
    let res = await policymanagementApi.cancelRenwalPolicy(insuranceId, reason, comment)
    if (res.ok) {
      loadPolicies()
    }
    return res
  }

  React.useEffect(() => {
    loadPolicies()

    let id = EventEmitterService.subscribe(EventKey.SCHEDULED_INSURANCE_CANCELED, insuranceId => {
      dispatch(new Action(ACTIONS.SCHEDULED_INSURANCE_CANCELED, insuranceId))
    })

    return function cleanup() {
      EventEmitterService.unsubscribe(EventKey.SCHEDULED_INSURANCE_CANCELED, id)
    }
  }, [])

  return (
    <PolicyManagementContextStore.Provider
      value={{
        ...state,
        dispatch,
        getInsurance,
        cancel,
        cancelPolicyWithoutOdometer,
        cancelPolicyWithoutPayment,
        cancelRenwalPolicy,
        loadPolicies,
        anyRoadSidePolicies
      }}
    >
      {props.children}
    </PolicyManagementContextStore.Provider>
  )
}

export default PolicyManagementContext
