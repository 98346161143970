import { FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@material-ui/core'
import React from 'react'
import FlexView from 'react-flexview/lib'
import { Controller } from 'react-hook-form'
import { StandaloneDiscountTag } from '../StandaloneDiscountTag'

export const GlobalTextField = ({
  onChange,
  onInput,
  value,
  shrink,
  InputProps,
  disabled,
  helperText,
  inputRef,
  isrequired,
  register,
  label,
  name,
  control,
  defaultValue,
  errors,
  watch,
  tooltip = undefined,
  explainForYes = false,
  discount = false,
  type,
  InputLabelProps,
}) => {
  return (
    <TextField
      onChange={onChange}
      onInput={onInput}
      disabled={disabled}
      value={value}
      InputProps={InputProps}
      fullWidth
      variant="outlined"
      className="avenir-book"
      InputLabelProps={{ shrink: shrink, className: 'avenir-book', ...InputLabelProps }}
      label={label}
      type={type}
      name={name}
      register={register}
      inputRef={inputRef}
      control={control}
      defaultValue={defaultValue}
      error={!!errors && !!errors[name]}
      helperText={helperText}
      FormHelperTextProps={{
        className: 'avenir-book',
      }}
    />
  )
}
