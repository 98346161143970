import { ActiveInsuranceModel } from './insurance-models/ActiveInsuranceModel'
import { InsuranceOfferModel } from './insurance-models/InsuranceOfferModel'
import { InsuranceOffersSummary } from './insurance-models/InsuranceOffersSummary'
import { InsuranceOptionModel } from './insurance-models/InsuranceOptionModel'
import UserProfileModel from './user-models/UserProfileModel'

export class Transformer {
  static toCamel(s) {
    return s.replace(/([-_][a-z])/gi, $1 => {
      return $1.toUpperCase().replace('-', '').replace('_', '')
    })
  }

  static parseISOLocal(s) {
    if (!s) return undefined
    var b = s.split('-')
    return new Date(b[0], b[1] - 1, b[2])
  }

  static initializeFromData(obj, data) {
    Object.keys(data).forEach(key => {
      let clsKey = this.toCamel(key)
      if (key.includes('utc')) {
        if (data[key].endsWith('Z')) obj[clsKey] = new Date(data[key])
        else obj[clsKey] = new Date(data[key] + 'Z')
      } else if (key == 'date_of_birth') {
        obj[clsKey] = this.parseISOLocal(data[key])
      } else if (key.includes('date')) {
        obj[clsKey] = new Date(data[key])
      } else {
        obj[clsKey] = data[key]
      }
    })
    return obj
  }

  static initializeFromDataDeep(obj, data) {
    Object.keys(data).forEach(key => {
      const clsKey = this.toCamel(key)
      if (key.includes('utc')) {
        if (data[key].endsWith('Z')) obj[clsKey] = new Date(data[key])
        else obj[clsKey] = new Date(data[key] + 'Z')
      } else if (key.includes('date')) {
        obj[clsKey] = new Date(data[key])
      } else if (key == 'vehicle_coverages' || key == 'vehicle_requests' || key == 'policy_coverages') {
        obj[clsKey] = data[key]
      } else if (data[key].constructor == Object && !Array.isArray(data[key])) obj[clsKey] = this.initializeFromDataDeep({}, data[key])
      else if (Array.isArray(data[key])) {
        obj[clsKey] = data[key].map(item => Transformer.initializeFromDataDeep({}, item))
      } else {
        obj[clsKey] = data[key]
      }
    })
    return obj
  }

  static parseUserModel(data) {
    let user: UserProfileModel = Transformer.initializeFromDataDeep(new UserProfileModel(), data)
    if (data.full_name) {
      let split = UserProfileModel.fullNameSplitter(data.full_name)
      user.firstName = split[0]
      user.lastName = split[1]
    } else {
      user.firstName = data.first_name
      user.lastName = data.last_name
    }
    return user
  }

  static parseOffers(arrData) {
    let arrOpt = arrData.map(optionData => Transformer.initializeFromData(new InsuranceOptionModel(), optionData))
    let offersLiability = {}
    let limitationOffers = []

    arrOpt.forEach(option => {
      let insur = offersLiability[option.liabilityLimit]
      if (insur == undefined) {
        insur = new InsuranceOfferModel()
        insur.liabilityLimit = option.liabilityLimit
        insur.options = []
        limitationOffers.push(insur)
        offersLiability[option.liabilityLimit] = insur
      }
      insur.options.push(option)
    })

    limitationOffers.forEach(insur => {
      insur.options.sort((a, b) => {
        return a.duration - b.duration
      })
    })

    limitationOffers.sort((a, b) => {
      return a.liabilityLimit - b.liabilityLimit
    })
    arrOpt.sort((a, b) => {
      return a.liabilityLimit - b.liabilityLimit
    })
    return new InsuranceOffersSummary(limitationOffers, arrOpt, false)
  }

  static parseActiveInsuranceModelArray(array) {
    let activeArray = []
    for (let active of array) {
      let activeInsurance = Transformer.parseActiveInsuranceModel(active)
      activeArray.push(activeInsurance)
    }
    return activeArray
  }

  static parseActiveInsuranceModel(active) {
    let activeInsurance: ActiveInsuranceModel = Transformer.initializeFromDataDeep(new ActiveInsuranceModel(), active)
    activeInsurance.insuranceType = active['insurance_period_description']
    activeInsurance.policyNumber = active['policy_number']
    activeInsurance.timeLeftSeconds = active['remaining_seconds']
    activeInsurance.insuranceStart = new Date(`${active['valid_from_utc']}${active['valid_from_utc'].endsWith('Z') ? '' : 'Z'}`)
    activeInsurance.insuredVehicles = active['insured_vehicles']
    activeInsurance.insuranceEnd = new Date(`${active['valid_until_utc']}${active['valid_until_utc'].endsWith('Z') ? '' : 'Z'}`)
    activeInsurance.durationInSeconds = (activeInsurance._insuranceEnd.getTime() - activeInsurance._insuranceStart.getTime()) / 1000
    activeInsurance.additionalInsuredList = active['additional_insureds']
    activeInsurance.perMileUntil = new Date(`${active['per_mile_until']}${active['per_mile_until'].endsWith('Z') ? '' : 'Z'}`)
    activeInsurance.isSuspendable = Boolean(active['suspendable'])
    activeInsurance.isActivated = Boolean(active['is_activated'])
    activeInsurance.suspensionStartTime = active['suspension_start_time'] ? new Date(active['suspension_start_time'] + 'Z') : undefined
    activeInsurance.suspensionEndTime = active['suspension_end_time'] ? new Date(active['suspension_end_time'] + 'Z') : undefined
    return activeInsurance
  }
}
