import ApiService, { HTTPMethod } from './api.core'
import { buildQueryParamsUrl, capitalizeFirstLetter, KPretty } from '../services/utils'
import { Transformer } from '../models/Transformer'
import { ContactSupportOutlined, Http } from '@material-ui/icons'
import dayjs from 'dayjs'

class PolicyModificationApi {
  async requestOffer(configuration) {
    return await ApiService.fetch('policy_modification/offer', HTTPMethod.POST, configuration)
  }

  async getPolicyDetails(insuranceId) {
    return await ApiService.fetch(`policy_modification/policy_details/${insuranceId}`, HTTPMethod.GET)
  }

  async SavePolicyChanges(configuration) {
    return await ApiService.fetch('policy_modification', HTTPMethod.PUT, configuration)
  }

  async getFormsPDF(data) {
    return await ApiService.fetch(`policy_modification/download_required_forms_to_sign`, HTTPMethod.POST, data)
  }

  async getOnlineRequiredSignatureFormsPDF(data) {
    return await ApiService.fetch(`policy_modification/get_online_docs_for_signin`, HTTPMethod.POST, data)
  }

  async signOnlineCoveragesDocs(data) {
    return await ApiService.fetch(`policy_modification/sign_online_coverages_docs`, HTTPMethod.POST, data)
  }

  async validatePolicyRider(data, insuranceId) {
    return await ApiService.fetch(`policy_modification/${insuranceId}/validate_policy_rider`, HTTPMethod.PUT, data)
  }

  async getMvrData(data, insuranceId) {
    return await ApiService.fetch(`policy_modification/${insuranceId}/get_mvr_data`, HTTPMethod.PUT, data)
  }

  async validatePolicyOwnerData(data) {
    return await ApiService.fetch('policy_modification/validate_policy_owner', HTTPMethod.PUT, data)
  }

  async validatePolicyMotorcycleData(data, insuranceId) {
    return await ApiService.fetch(`policy_modification/${insuranceId}/validate_policy_motorcycle`, HTTPMethod.PUT, data)
  }
  async getNewVehicleBasicCoverages() {
    return await ApiService.fetch('policy_modification/get_vehicle_basic_coverages')
  }

  async getVehicleOdometerDetails(data, insuranceId) {
    return await ApiService.fetch(`policy_modification/${insuranceId}/get_vehicle_odometer_details`, HTTPMethod.POST, data)
  }

  async uploadOdometers(formData, insuranceId) {
    return await ApiService.fetch(`policy_modification/${insuranceId}/upload_odometer`, HTTPMethod.POST, formData, false)
  }

  async getMidtermAmount(data, insuranceId) {
    return await ApiService.fetch(`policy_modification/${insuranceId}/midterm_amount`, HTTPMethod.POST, data)
  }
}

export default new PolicyModificationApi()
