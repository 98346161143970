export class InsuranceOptionModel {
  offerId: string
  optionId: string
  duration: number //minutes
  totalDiscount: number
  liabilityLimit: number
  liabilityLimitPremium: number
  liabilityLimitDiscount: number
  liabilityLimitDiscountPercentage: number
  price: number
  billingPeriod: string
  commercial: boolean
  isNightime: boolean
  dronePremium: number
  medicalExpenseLimitPremium: number
  personalInjuryLimitPremium: number
  territorialLimitsPremium: number
  territorialLimits: String
  equipmentPremium: number
  promoCreditsUsed: number
  taxesMultiplier: number
  avgFlightScoreMultiplier: number
  purchaseDiscountMultiplier: number
  hullCoverageDiscountMultiplier: number
  hullCoverageVolumeDiscountMultiplier: number
  OperatorsDiscountMultiplier: number
  drones: DroneModel[]
  equipment: EquipmentModel[]
}

export class DroneModel {
  premium: number
}

export class EquipmentModel {
  premium: number
}
