import React, { useReducer } from 'react'
import { Action } from '../models/Action'

export const ACTIONS = {
  CHANGE_INSURANCE_HISTORY_QUESTION: 'CHANGE_INSURANCE_HISTORY_QUESTION',
  PROFILE_SAVE: 'PROFILE_SAVE',
  SAVE_ADDRESS: 'SAVE_ADDRESS',
  SAVE_USER_INFO: 'SAVE_USER_INFO',
  SAVE_INSURANCE_HISTORY: 'SAVE_INSURANCE_HISTORY',
  SAVE_ESTIMATED_MILAGE: 'SAVE_ESTIMATED_MILAGE',
  SET_USER_MVR_RECORD: 'SET_USER_MVR_RECORD',
  RESET: 'RESET',
}

const defaultState = {
  dispatch: (action: Action) => {},
  address: {},
  user_info: {},
  estimated_milage: '',
  insurance_history: {},
  insurance_history_question: 'Have you had motorcycle insurance in the past 6 months?',
  address_saved: false,
  user_info_saved: false,
  estimated_milage_saved: false,
  insurance_history_saved: false,
  user_mvr_record: [],
}

const reducer = (state, action: Action) => {
  let newState = state
  switch (action.type) {
    case ACTIONS.CHANGE_INSURANCE_HISTORY_QUESTION:
      newState = { ...state, insurance_history_question: action.data }
      break
    case ACTIONS.SAVE_ADDRESS:
      newState = { ...state, address: action.data, address_saved: true }
      break
    case ACTIONS.SAVE_USER_INFO:
      newState = { ...state, user_info: action.data, user_info_saved: true }
      break
    case ACTIONS.SAVE_INSURANCE_HISTORY:
      newState = { ...state, insurance_history: action.data, insurance_history_saved: action.data.saved }
      break
    case ACTIONS.SAVE_ESTIMATED_MILAGE:
      newState = { ...state, estimated_milage: action.data, estimated_milage_saved: true }
      break
    case ACTIONS.SET_USER_MVR_RECORD:
      newState = { ...state, user_mvr_record: action.data }
      break
    case ACTIONS.RESET:
      newState = {
        ...state,
        insurance_history_saved: false,
        user_info_saved: false,
        address_saved: false,
        estimated_milage_saved: false,
        user_mvr_record: [],
      }
      break
    default:
      newState = state
      break
  }

  return newState
}

export const UserProfileContextStore = React.createContext(defaultState)
UserProfileContextStore.displayName = 'User Profile ContextStore'

const UserProfileContext = props => {
  const [state, dispatch] = useReducer(reducer, defaultState)

  return <UserProfileContextStore.Provider value={{ ...state, dispatch }}>{props.children}</UserProfileContextStore.Provider>
}

export default UserProfileContext
