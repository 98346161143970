import React from 'react'
import FlexView from 'react-flexview/lib'
import CheckIcon from '@material-ui/icons/Check'
import { Grow, IconButton } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { reportGTM } from '../Core/Utilities/GTagManger'
import { BrowserView, MobileView, isMobile } from 'react-device-detect'
import '../OdometerFlow/OdometerFlowCongratz.scss'
import DownloadBannerForMobile from '../DownloadAppBanner/DownloadBannerForMobile.js'

export const EstimatedMilesFlowCongrats = () => {
  const [show, setShow] = React.useState(false)
  const history = useHistory()

  const location = useLocation()
  const params = queryString.parse(location.search)

  React.useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 200)
  }, [])

  const redirectToPolicyManagement = () => {
    reportGTM('', '', {
      event: 'Done-button-estimated-miles-update',
      at: window.location.pathname,
    })
    history.push('/policy-management')
  }

  return (
    <><MobileView>
      <FlexView column className="priview-background odometer-success-main" style={{ height: "100vh", width: "100vw" }}>
        <FlexView  className="priview-background2" hAlignContent={'center'} vAlignContent={'center'}  >
          <FlexView hAlignContent={'center'} style={{ position: 'absolute', top: 10  ,alignContent: 'center', justifyContent: 'center', alignItems: 'center', display:'flex' }}>
            <img src={`${process.env.PUBLIC_URL}/assets/voom_white.png`} style={{ height: 38 }} />
          </FlexView>
            <Grow in={show} style={{ transformOrigin: '0 0 0' }} {...(show ? { timeout: 1000 } : {})}>
              <FlexView hAlignContent={'center'} column vAlignContent={'center'} className="preview-title-top">
                <img src={process.env.PUBLIC_URL + '/assets/v-sign.png'} className="odometer-success-icon" />
                <div className=" white textcenter margin-bottom-basic odometer-success-text">
                  Thank you! <br />
                </div>
                <div className="text-secondary white textcenter margin-bottom-basic">
                  <div>Recieved and Updated</div>
                </div>


              </FlexView>
            </Grow>
          </FlexView>
        </FlexView>
    </MobileView><BrowserView>
            <FlexView column vAlignContent={'center'} className="preview-content odometer-success-content">
              <Grow in={show} style={{ transformOrigin: '0 0 0' }} {...(show ? { timeout: 1000 } : {})}>
                <FlexView hAlignContent={'center'} column vAlignContent={'center'} className="preview-title-top">
                <img src={process.env.PUBLIC_URL + '/assets/clock.png'}  />
                <div className=" textcenter margin-bottom-basic odometer-success-text">
                    Thank you! <br />
                  </div>
                  <img src={process.env.PUBLIC_URL + '/assets/v-sign.png'} className="odometer-success-icon" />
                  <div className="text-secondary textcenter margin-bottom-basic">
                    <div>Recieved and Updated</div>
                  </div>


                </FlexView>
              </Grow>
            </FlexView>
      </BrowserView></>
  )
}
