import React from 'react'
import { Switch, Route, useRouteMatch, useHistory, useLocation, Redirect } from 'react-router-dom'
import { EventEmitterService, EventKey } from '../../services/EventEmitterService'
import { EstimatedMilesFlowCongrats } from './EstimatedMilesFlowCongrats'
import {EstimatedMilesFlowAlreadyApproved} from './EstimatedMilesFlowAlreadyApproved'
import {EstimatedMilesFlowUpdate} from './EstimatedMilesFlowUpdate'
import { BrowserView, MobileView } from 'react-device-detect'
import {PolicyManagementContextStore} from '../../context/PolicyManagementContext'


export const EstimatedMilesFlowRoutes = () => {
  const { path, url } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const myPoliciesContext = React.useContext(PolicyManagementContextStore)



  return (
    <Switch>
      <Route path={`${path}/update`}>

        <EstimatedMilesFlowUpdate/>
      </Route>

      <Route path={`${path}/congrats`}>
        <EstimatedMilesFlowCongrats/>
      </Route>

      <Route path={`${path}/approved`}>
          <EstimatedMilesFlowAlreadyApproved/>
      </Route>

      <Route>
        <Redirect
          to={{
            pathname: `${path}/update`,
            search: window.location.search,
          }}
        />
      </Route>
    </Switch>
  )
}
