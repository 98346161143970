import React from 'react'

const ExclamationMarkIcon = props => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.5 5.00006V7.50006M7.5 10.0001H7.50625M13.75 7.50006C13.75 10.9518 10.9518 13.7501 7.5 13.7501C4.04822 13.7501 1.25 10.9518 1.25 7.50006C1.25 4.04828 4.04822 1.25006 7.5 1.25006C10.9518 1.25006 13.75 4.04828 13.75 7.50006Z"
      stroke="inherit"
      stroke-width="inherit"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export default ExclamationMarkIcon
