//@flow

export default class CreditCardModel {
  name: string
  cardNumber: string
  expMonth: string
  expYear: string
  cvc: string
  hasLastFourDigits: boolean
  brand: string
  _lastFourDigits: string

  set lastFourDigits(value: string) {
    this.cardNumber = '**** **** **** ' + value
    this.hasLastFourDigits = true
    this._lastFourDigits = value
  }

  get lastFourDigits() {
    return this._lastFourDigits
  }
}

export const PaymentMethod = {
  STRIPE: 'Stripe',
  BANK_TRANSFER: 'BankTransfer',
}
