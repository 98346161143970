import React from 'react'

const EmailIcon = props => (
  <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.16602 3.83333L7.97012 8.5962C8.52109 8.98189 8.79658 9.17473 9.09624 9.24942C9.36093 9.3154 9.63777 9.3154 9.90246 9.24942C10.2021 9.17473 10.4776 8.98189 11.0286 8.5962L17.8327 3.83333M5.16602 14.6667H13.8327C15.2328 14.6667 15.9329 14.6667 16.4677 14.3942C16.9381 14.1545 17.3205 13.772 17.5602 13.3016C17.8327 12.7669 17.8327 12.0668 17.8327 10.6667V5.33333C17.8327 3.9332 17.8327 3.23314 17.5602 2.69836C17.3205 2.22795 16.9381 1.8455 16.4677 1.60582C15.9329 1.33333 15.2328 1.33333 13.8327 1.33333H5.16602C3.76588 1.33333 3.06582 1.33333 2.53104 1.60582C2.06063 1.8455 1.67818 2.22795 1.4385 2.69836C1.16602 3.23314 1.16602 3.9332 1.16602 5.33333V10.6667C1.16602 12.0668 1.16602 12.7669 1.4385 13.3016C1.67818 13.772 2.06063 14.1545 2.53104 14.3942C3.06582 14.6667 3.76588 14.6667 5.16602 14.6667Z"
      stroke="inherit"
      strokeWidth="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default EmailIcon
