export const downloadBannerImages = {
  downloadArrow: require('../../assets/downloadBanner/download_arrow.png'),
  downloadAndroidAppIcon: require('../../assets/downloadBanner/download_android_app.png'),
  downloadIOAppIcon: require('../../assets/downloadBanner/download_ios_app.png'),
}

export const bannerLinkForCongratsIos = () => {
  window.open('https://voominsurancpro.page.link/?link=https://apps.apple.com/us/app/voom-motorcycle-insurance/id1641498413&isi=1641498413&ibi=com.voominsurance.motorcycles&utm_campaign=congrats_page&utm_medium=banner&utm_source=voom', '_blank')
}

export const bannerLinkForCongratsAndroid = () => {
  window.open('https://voominsurancpro.page.link/?link=https://play.google.com/store/apps/details?id%3Dcom.voom_motorcycle_app&apn=com.voom_motorcycle_app&utm_campaign=congrats_page&utm_medium=banner&utm_source=google', '_blank')
}

export const bannerLinkForPolicyManagementIos = () => {
  window.open('https://voominsurancpro.page.link/?link=https://apps.apple.com/us/app/voom-motorcycle-insurance/id1641498413&isi=1641498413&ibi=com.voominsurance.motorcycles&utm_campaign=policy_management&utm_medium=banner&utm_source=voom', '_blank')
}

export const bannerLinkForPolicyManagementAndroid = () => {
  window.open('https://voominsurancpro.page.link/?link=https://play.google.com/store/apps/details?id%3Dcom.voom_motorcycle_app&apn=com.voom_motorcycle_app&utm_campaign=policy_management&utm_medium=banner&utm_source=google', '_blank')
}

export const bannerLinkForStorageModeIos = () => {
  window.open('https://voominsurancpro.page.link/?link=https://apps.apple.com/us/app/voom-motorcycle-insurance/id1641498413&isi=1641498413&ibi=com.voominsurance.motorcycles&utm_campaign=storage_dec&utm_medium=banner&utm_source=voom', '_blank')
}

export const bannerLinkForStorageModeAndroid = () => {
  window.open('https://voominsurancpro.page.link/?link=https://play.google.com/store/apps/details?id%3Dcom.voom_motorcycle_app&apn=com.voom_motorcycle_app&utm_campaign=storage_mode&utm_medium=banner&utm_source=google', '_blank')
}

export const bannerLinkForPostOdometerIos = () => {
  window.open('https://voominsurancpro.page.link/?link=https://apps.apple.com/us/app/voom-motorcycle-insurance/id1641498413&isi=1641498413&ibi=com.voominsurance.motorcycles&utm_campaign=odometer_upload_success&utm_medium=banner&utm_source=voom', '_blank')
}

export const bannerLinkForPostOdometerAndroid = () => {
  window.open('https://voominsurancpro.page.link/?link=https://play.google.com/store/apps/details?id%3Dcom.voom_motorcycle_app&apn=com.voom_motorcycle_app&utm_campaign=odometer_upload_success&utm_medium=banner&utm_source=google', '_blank')
}