import { Container } from '@material-ui/core'
import React from 'react'
import FlexView from 'react-flexview/lib'
import './TempThankyou.css'

export const TempThankyou = () => {
  return (
    <Container>
      <FlexView column className="margin-top-basic" hAlignContent={'center'}>
        <img src={process.env.PUBLIC_URL + '/assets/thankyou.png'} className="thanks" />
        <br />
        <div className="text-body black margin-top-basic textcenter">Next step: our agents will get back to you for more details soon.</div>
        <div className="text-body black margin-top-small textcenter">
          For any questions, contact us at{' '}
          <a href={'mailto:support@voominsurance.com'} className="blue underline">
            support@voominsurance.com
          </a>
        </div>
      </FlexView>
    </Container>
  )
}
