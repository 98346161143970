import { FirebaseService, FirebaseConfigKey } from '../../services/FirebaseService'
import React from 'react'
import './DiscountTag.scss'
import FlexView from 'react-flexview/lib'

import { ReactComponent as PriceTagSvg } from '../../assets/discounts_tag.svg'

export const StandaloneDiscountTag = () => {
  return (
    <FlexView className="discount-container" vAlignContent={'center'}>
      <FlexView vAlignContent={'center'} className="text-tiny blue">
        <PriceTagSvg className="margin-right-6 blue-svg-stroke" /> Discount
      </FlexView>
    </FlexView>
  )
}
