import React from 'react'

export const BASE_THEME = {
  blue: '#0076ff',
  lightteal: '#88b5dd',
  darkblue: '#003777',
  darkskyblue: '#152442',
  primary: '#0076ff',
  secondary: '#ffffff',
  buttonactive: '#0058da',
  powderblue: '#c2d1ea',
  verylightteal: '#e8f5ff',
  buttonhover: '#4199ff',
  buttonshadow: '#1968fc99',
  bluegrey: '#dbe5f5',
  lightgraybox: '#f5f9fd',
  logoHeight: '40px',
  logoHeightMobile: '20px',
  useColoredBackground: false,
  hamburger: '#ffffff',
}

export const THEMES = {
  skywatch: {
    theme: Object.assign({}, BASE_THEME, {
      logoHeight: '20px',
      useColoredBackground: true,
    }),
  },
  voom: {
    theme: Object.assign({}, BASE_THEME, {
      blue: '#5743AF',
      bluegrey: '#eeecf7',
      lightgraybox: '#eeecf7',
      logoHeight: '50px',
      logoHeightMobile: '40px',
    }),
    redeemCode: 'voominsurance',
  },
  ajg: {
    redeemCode: 'galla2019',
    theme: Object.assign({}, BASE_THEME, {
      blue: '#002a44',
      primary: '#002a44',
      logoHeight: '35px',
      logoHeightMobile: '30px',
      logo: 'ajg_logo.svg',
    }),
  },
}

export const SkywatchThemeContext = React.createContext(THEMES.BASE_THEME)
