import { Container, Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import React from 'react'
import FlexView from 'react-flexview/lib'
import { useHistory, useLocation } from 'react-router-dom'
import NewFillButton from '../Core/NewFillButton'
import queryString from 'query-string'
import './OdometerIntro.scss'
import insuranceApi from '../../api/insurance.api'
import { awaitWithLoading } from '../../services/utils'
import { ReactComponent as PurpleArrow } from '../../assets/purple_arrow.svg'
import VideoApp from './OdometerVideo'

export const OdometerIntro = () => {
  const history = useHistory()
  const location = useLocation()
  const params = queryString.parse(location.search)
  const insuranceId = params.InsuranceId

  const Snap = () => {
    history.push({ pathname: `/odometer/picture`, search: location.search })
  }

  return (
    <div className="odometer-background full-height">
      <FlexView vAlignContent={'center'} hAlignContent={'center'} className="odo-pick-title">
        <img src={`${process.env.PUBLIC_URL}/assets/voom_white.png`} style={{ height: 38, margin: 10 }} />
      </FlexView>
      <FlexView column hAlignContent={'center'}>
        {params.IsUpdateReq == 'True' ? (
          <div className="text-title black bolder margin-top-basic margin-bottom-basic margin-left-basic">Monthly mileage update</div>
        ) : (
          <div className="text-title black bolder margin-top-basic margin-bottom-basic margin-left-basic">
            Motorcycle Pay Per Mile Insurance{' '}
            {params.IsCancellationReq && params.IsCancellationReq == 'True' ? 'Cancellation' : 'Activation'}
          </div>
        )}
        {/* <img src={`${process.env.PUBLIC_URL}/assets/odometer_photo.png`} className='odo-image' /> */}
        <VideoApp autoplay="true" />
        <Container>
          <FlexView hAlignContent={'left'}>
            <NewFillButton onClick={Snap} flat>
              <div className="text-title white">{params.IsVinUploadNeeded == 'True' ? 'Continue' : 'Snap a photo'}</div>
            </NewFillButton>
          </FlexView>
        </Container>
        <List style={{ paddingTop: 0 }}>
          {params.IsVinUploadNeeded == 'True' && (
            <ListItem className="item_list">
              <ListItemIcon>
                <PurpleArrow className="arrow_icon" />
              </ListItemIcon>
              <ListItemText primary="Upload your Vehicle Identification Number (VIN) number" />
            </ListItem>
          )}
          {params.IsVinUploadNeeded == 'True' && <Divider variant="middle" />}
          <ListItem className="item_list">
            <ListItemIcon>
              <PurpleArrow className="arrow_icon" />
            </ListItemIcon>
            <ListItemText primary={`Snap an image of your ${params.IsUpdateReq == 'True' ? 'current' : 'latest'} odometer reading`} />
          </ListItem>
          <Divider variant="middle" />
          <ListItem className="item_list">
            <ListItemIcon>
              <PurpleArrow className="arrow_icon" />
            </ListItemIcon>
            <ListItemText primary="Be sure the photo is clear and the numbers are readable" />
          </ListItem>
          <Divider variant="middle" />
          <ListItem className="item_list">
            <ListItemIcon>
              <PurpleArrow className="arrow_icon" />
            </ListItemIcon>
            <ListItemText
              primary={
                params.IsUpdateReq == 'True'
                  ? "Your monthly billing will be updated upon photo confirmation. You'll pay only for what you rode!"
                  : params.IsCancellationReq == 'True'
                  ? 'Your last payment will be confirmed and your policy will be cancelled'
                  : params.IsNewFlow
                  ? "That's it, you're all set to pay by the mile. Ride on!"
                  : 'After the image is confirmed you will receive your policy document via email'
              }
            />
          </ListItem>
        </List>
        <br />
        <br />
        <br />
      </FlexView>
    </div>
  )
}
