import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import odometerApi from '../../api/odometer.api'
import { awaitWithLoading } from '../../services/utils'
import { CameraWithTemplate } from './CameraWithTemplate'
import { ImagePreview } from './ImagePreview'
import queryString from 'query-string'
import { EventEmitterService, EventKey } from '../../services/EventEmitterService'
import { BrowserView, MobileView } from 'react-device-detect'
import { DesktopNotAllowed } from './DesktopNotAllowed'

export const TakePictureFlow = () => {
  const [dataUri, setDataUri] = React.useState('')
  const history = useHistory()
  const location = useLocation()
  const params = queryString.parse(location.search)
  const [isVinUploadNeeded, setIsVinUploadNeeded] = React.useState(params.IsVinUploadNeeded == 'True' ? true : false)

  const handleTakePhotoAnimationDone = dataUri => {
    setDataUri(dataUri)
  }

  const onAccept = async () => {
    if (!dataUri) return

    EventEmitterService.dispatch(EventKey.ShowLoader)
    // let resp = await odometerApi.getOdometerPredicition(dataUri)
    // console.log(resp)

    // let predicition = null
    // if (resp.ok && resp.prediction.length > 0) {
    //   predicition = resp.prediction[0]
    // }
    const requestId = params.RequestId == null ? params.transactionItemId : params.RequestId
    let res = await odometerApi.uploadOdometer(
      dataUri,
      0,
      params.token,
      params.InsuranceId,
      requestId,
      params.IsCancellationReq,
      params.VehicleId
    )
    EventEmitterService.dispatch(EventKey.DismissLoader)

    if (res.ok) {
      if (res.data && res.data.url) {
        history.push(res.data.url)
      } else {
        history.push({ pathname: '/odometer/congrats', search: location.search })
      }
    } else {
      if (res.data.redirect_to_url) {
        history.push({ pathname: res.data.redirect_to_url, search: location.search, error_data: res.data.latest_odometer_url })
      } else {
        history.push({ pathname: '/odometer/failed', search: location.search, error_text: res.data.error })
      }
    }
  }

  const onCancel = () => {
    setDataUri('')
    setIsVinUploadNeeded(false)
  }

  return (
    <>
      {dataUri ? (
        <ImagePreview dataUri={dataUri} onAccept={onAccept} onCancel={onCancel} />
      ) : (
        <CameraWithTemplate
          handleTakePhotoAnimationDone={handleTakePhotoAnimationDone}
          isVinUploadNeeded={isVinUploadNeeded}
          insuranceId={params.InsuranceId}
          requestId={params.RequestId}
        />
      )}
    </>
  )
}
