export const StorageKey = Object.freeze({
  EMAIL: 'email',
  TOKEN: 'token',
  TOKEN_EXPIRATION: 'token_expiration',
  FLIGHT_ID: 'flight_id',
  NOT_FIRST_USE: 'not_first_use',
  BROKER_USER_ON_BEHALF_TOKEN: 'broker_user_on_behalf_token',
  GET_A_QUOTE_HULL_KEY: 'get_a_quote_hull',
  DISCOUNTS_TAB_STATE: 'discounts_tab_state',

  USER_IS_COMMERCIAL: 'commercial',
  STATE_LIST: 'STATE_LIST',
  ACTIVE_STATE_LIST: 'ACTIVE_STATE_LIST',
  INACTIVE_STATE_LIST: 'INACTIVE_STATE_LIST',
  IS_NEW_MVR_INFORMATION: 'IS_NEW_MVR_INFORMATION',
})

let KeyType = StorageKey.EMAIL | StorageKey.TOKEN | StorageKey.TOKEN_EXPIRATION | StorageKey.FLIGHT_ID | StorageKey.NOT_FIRST_USE

class StorageService {
  getItem(key: KeyType) {
    if (key in localStorage) return localStorage[key]
    else return null
  }

  setItem(key: KeyType, value) {
    try {
      localStorage[key] = value
      localStorage.setItem(key, value)
    } catch (e) {
      return false
    }
    return true
  }

  removeItem(key: KeyType) {
    try {
      localStorage[key] = null
      localStorage.removeItem(key)
    } catch (e) {
      return false
    }
    return true
  }

  setWithExpiry(key, value, ttl) {
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    }
    this.setItem(key, JSON.stringify(item))
  }

  setWithExpiryDate(key, value, dt) {
    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: dt,
    }
    this.setItem(key, JSON.stringify(item))
  }

  getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > new Date(item.expiry)) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key)
      return null
    }
    return item.value
  }
}

export default new StorageService()
