import React, { useContext, useEffect, useReducer, useState } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { Action } from '../models/Action'
import queryString from 'query-string'
import { PolicyManagementContextStore } from './PolicyManagementContext'
import { AccessTimeRounded } from '@material-ui/icons'
import {
  BuyingInsuranceContextStore,
  unbundleBodilyLiabilityToOtherLiabilityCoverages,
  unbundleBodilyLiabilityToOtherLiabilityCoveragesNewFlow,
} from './BuyingInsuranceContext'
import policyModificationApi from '../api/policyModification.api'
import { awaitWithLoading } from '../services/utils'
import { EventEmitterService, EventKey } from '../services/EventEmitterService'
import coveragesApi from '../api/coverages.api'
import { parseOfferViolations } from '../utilities/ParseOfferViolations'
import { UserApi } from '../api/user.api'
import { statesWithLiabilityTripleBundle } from '../Constants'

export const ACTIONS = {
  SAVE_OWNER_DETAILS: 'SAVE_OWNER_DETAILS',
  REMOVE_RIDER_FROM_POLICY: 'REMOVE_RIDER_FROM_POLICY',
  ADD_RIDER_TO_POLICY: 'ADD_RIDER_TO_POLICY',
  EDIT_RIDERS: 'EDIT_RIDERS',
  SAVE_RIDERS_DETAILS: 'SAVE_RIDERS_DETAILS',
  EDIT_MOTORCYCLES: 'EDIT_MOTORCYCLES',
  SAVE_MOTORCYCLES_DETAILS: 'SAVE_MOTORCYCLES_DETAILS',
  SAVE_COVERAGES: 'SAVE_COVERAGES',
  POLICY_COVERAGES_CHANGED: 'POLICY_COVERAGES_CHANGED',
  VEHICLE_COVERAGES_CHANGED: 'VHICLE_COVERAGES_CHANGED',
  BACK_TO_MODIFICATION: 'BACK_TO_MODIFICATION',
  MODIFICATION_FINISHED: 'MODIFICATION_FINISHED',
  PRICE_BREAKDOWN_CHANGED: 'PRICE_BREAKDOWN_CHANGED',
  SET_POLICY_DATA: 'SET_POLICY_DATA',
  SET_COVERAGES_ERROR: 'SET_COVERAGES_ERROR',
  SET_COVERAGES_WITH_SIGNING: 'SET_COVERAGES_WITH_SIGNING',
  RESET: 'RESET',
  SET_BILLING_PERIOD_ENABLED: 'SET_BILLING_PERIOD_ENABLED',
  SET_BILLING_PERIOD: 'SET_BILLING_PERIOD',
  SET_VEHICLE_MAX_ID: 'SET_VEHICLE_MAX_ID',
  SET_RIDER_MAX_ID: 'SET_RIDER_MAX_ID',
  SET_MOTERCYCLES_ODOMETER_DETAILS: 'SET_MOTERCYCLES_ODOMETER_DETAILS',
  SAVE_ODOMETER_DATA: 'SAVE_ODOMETER_DATA',
}

const defaultState = {
  dispatch: (action: Action) => {},
  insuranceId: '',
  owner_details: {},
  rider_details: [],
  motorcycles_details: [],
  policy_coverages: {},
  riderMaxId: 0,
  vehicleMaxId: 0,
  owner_detailes_saved: false,
  rider_details_saved: false,
  motorcycles_details_saved: false,
  coverages_saved: false,
  odometer_uploaded: false,
  is_modify_finish: false,
  offer: {},
  coveragesErrors: {},
  coveragesToSign: {
    coverageSigningInfo: [],
  },
  isUserNeedToSign: false,
  isRenewal: false,
  billingPeriodEnabled: false,
  billingPeriod: 'Monthly',
  motorcycles_odometer_details: [],
}

const reducer = (state, action: Action) => {
  let newState = state
  switch (action.type) {
    case ACTIONS.SAVE_OWNER_DETAILS:
      const riders = [...state.rider_details]
      const owner = riders.filter(rider => rider.is_owner)[0]
      if (owner != undefined) {
        owner.first_name = action.data.first_name
        owner.last_name = action.data.last_name
        owner.date_of_birth = action.data.date_of_birth
      }
      newState = { ...state, rider_details: riders, owner_details: action.data, owner_detailes_saved: true }
      break
    case ACTIONS.REMOVE_RIDER_FROM_POLICY:
      newState = { ...state, rider_details: action.data }
      break
    case ACTIONS.EDIT_RIDERS:
      newState = { ...state, rider_details: action.data }
      break
    case ACTIONS.SAVE_RIDERS_DETAILS:
      newState = { ...state, rider_details_saved: true, owner_details: action.data }
      break
    case ACTIONS.EDIT_MOTORCYCLES:
      newState = { ...state, motorcycles_details: action.data }
      break
    case ACTIONS.SAVE_MOTORCYCLES_DETAILS:
      newState = { ...state, motorcycles_details_saved: true }
      break
    case ACTIONS.SAVE_COVERAGES:
      newState = { ...state, coverages_saved: true }
      break
    case ACTIONS.BACK_TO_MODIFICATION:
      newState = {
        ...state,
        is_modify_finish: false,
        coverages_saved: !state.isRenewal,
        odometer_uploaded: state.isRenewal,
      }
      break
    case ACTIONS.SAVE_ODOMETER_DATA:
      newState = { ...state, odometer_uploaded: true }
      break
    case ACTIONS.MODIFICATION_FINISHED:
      newState = { ...state, is_modify_finish: true }
      break
    case ACTIONS.SET_POLICY_DATA:
      newState = { ...state, ...{ ...action.data, odometer_uploaded: action.data.isRenewal } }
      break
    case ACTIONS.POLICY_COVERAGES_CHANGED: {
      let { newOffer, isNewFlow, options } = action.data
      if (statesWithLiabilityTripleBundle.includes(action.data.administrativeDivision)) {
        unbundleBodilyLiabilityToOtherLiabilityCoverages(newOffer, options)
      } else {
        unbundleBodilyLiabilityToOtherLiabilityCoveragesNewFlow(newOffer, options)
      }
      return { ...state, policy_coverages: newOffer }
    }
    case ACTIONS.VEHICLE_COVERAGES_CHANGED:
      newState = { ...state, motorcycles_details: action.data.newOffer }
      break
    case ACTIONS.PRICE_BREAKDOWN_CHANGED:
      newState = { ...state, offer: action.data }
      break
    case ACTIONS.SET_COVERAGES_ERROR:
      newState = { ...state, coveragesErrors: action.data }
      break
    case ACTIONS.SET_COVERAGES_WITH_SIGNING:
      newState = {
        ...state,
        coveragesToSign: action.data.coveragesToSign,
        isUserNeedToSign: action.data.isUserNeedToSign,
        coveragesErrors: {},
      }
      break
    case ACTIONS.SET_BILLING_PERIOD_ENABLED:
      return { ...state, billingPeriodEnabled: action.data }
    case ACTIONS.SET_BILLING_PERIOD:
      return { ...state, billingPeriod: action.data }
    case ACTIONS.SET_VEHICLE_MAX_ID:
      return { ...state, vehicleMaxId: action.data }
    case ACTIONS.SET_RIDER_MAX_ID:
      return { ...state, riderMaxId: action.data }
    case ACTIONS.SET_MOTERCYCLES_ODOMETER_DETAILS:
      return { ...state, motorcycles_odometer_details: action.data }
    case ACTIONS.RESET:
      newState = defaultState
      newState.insuranceId = state.insuranceId
      newState.isRenewal = state.isRenewal
      newState.billingPeriodEnabled = state.billingPeriodEnabled
      break
    default:
      newState = state
      break
  }

  return newState
}

export const PolicyModificationContextStore = React.createContext(defaultState)
PolicyModificationContextStore.displayName = 'Policy Modification ContextStore'

const PolicyModificationContext = props => {
  const myPoliciesContext = useContext(PolicyManagementContextStore)

  const [state, dispatch] = useReducer(reducer, defaultState)
  console.log('PolicyModificationContext:', state)

  const requestOffer = async () => {
    let res = await awaitWithLoading(
      policyModificationApi.requestOffer({
        insurance_id: state.insuranceId,
        policy_owner_details: state.owner_details,
        insured_vehicles: state.motorcycles_details,
        insured_persons: state.rider_details,
        policy_coverages: state.policy_coverages,
        billing_period: state.billingPeriod,
        is_renewal: state.isRenewal,
      })
    )
    if (res.ok) {
      dispatch({ type: ACTIONS.PRICE_BREAKDOWN_CHANGED, data: res.data })
      dispatch({ type: ACTIONS.MODIFICATION_FINISHED })
    } else {
      EventEmitterService.dispatch(EventKey.ShowError, res)
    }
    return res
  }

  const validateCoverages = async () => {
    let res = await awaitWithLoading(
      coveragesApi.validateCoverages({
        policy_coverages: state.policy_coverages,
        insured_vehicles: state.motorcycles_details,
        insurance_id: state.insuranceId,
      })
    )

    if (res.ok) {
      let respnseData = {
        coveragesToSign: {
          coverageSigningInfo: res.data.coverage_signing_detailes,
          ...res.data.policy_coverages_required_signing,
        },
        isUserNeedToSign: res.data.is_user_need_to_sign,
      }

      dispatch({ type: ACTIONS.SET_COVERAGES_WITH_SIGNING, data: respnseData })
    } else if (res.data && res.data.code == 400) {
      let err = parseOfferViolations(res.data)
      dispatch({ type: ACTIONS.SET_COVERAGES_ERROR, data: err })
    } else {
      EventEmitterService.dispatch(EventKey.ShowError, res)
    }
  }

  const setBillingPeriod = async period => {
    dispatch({ type: ACTIONS.SET_BILLING_PERIOD, data: period })
  }

  useEffect(() => {
    if (
      state.motorcycles_details_saved &&
      state.owner_detailes_saved &&
      state.rider_details_saved &&
      state.coverages_saved &&
      state.odometer_uploaded
    ) {
      requestOffer()
    }
  }, [state.billingPeriod])

  useEffect(() => {
    if (state.motorcycles_details_saved) {
      validateCoverages()
    }
  }, [state.policy_coverages, state.motorcycles_details])

  useEffect(() => {
    if (
      state.motorcycles_details_saved &&
      state.owner_detailes_saved &&
      state.rider_details_saved &&
      state.coverages_saved &&
      state.odometer_uploaded
    ) {
      requestOffer()
    }
  }, [
    state.owner_detailes_saved,
    state.rider_details_saved,
    state.motorcycles_details_saved,
    state.coverages_saved,
    state.odometer_uploaded,
  ])

  useEffect(() => {
    const check = async () => {
      let isPeriodicPaymentEnabled = (await UserApi.isPeriodicPaymentEnabled()).parsedData
      dispatch({ type: ACTIONS.SET_BILLING_PERIOD_ENABLED, data: isPeriodicPaymentEnabled })
    }
    check().catch(console.error)
  }, [])

  return (
    <PolicyModificationContextStore.Provider value={{ ...state, dispatch, validateCoverages, setBillingPeriod }}>
      {props.children}
    </PolicyModificationContextStore.Provider>
  )
}

export default PolicyModificationContext
