import React, { useEffect, useContext } from 'react'
import FlexView from 'react-flexview/lib'
import { Divider, Grow, Grid, MenuItem, TextField, Container } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { BrowserView, MobileView, isMobile } from 'react-device-detect'
import '../OdometerFlow/OdometerFlowCongratz.scss'
import { GreenButton } from '../Core/Buttons/GreenButton'
import { awaitWithLoading, getEstimatedMilesOptions } from '../../services/utils'
import insuranceApi from '../../api/insurance.api'
import { EventEmitterService, EventKey } from '../../services/EventEmitterService'
import '../../components/PolicyManagement/PolicyListCard.scss'

export const EstimatedMilesFlowUpdate = () => {
  const { triggerValidation, register, errors, getValues, control, setValue } = useForm({ mode: 'onChange' })

  const [show, setShow] = React.useState(false)
  const history = useHistory()
  const [details, setDetails] = React.useState()
  const [current, setCurrent] = React.useState(0)

  React.useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 200)
  }, [])

  useEffect(() => {
    const getEstimatedMilesDetails = async () => {
      const result = await awaitWithLoading(insuranceApi.getEstimatedMilesDetails())
      if (result === null) {
        history.push('/estimated-renewal/approved')
      }
      setDetails(result)
    }
    getEstimatedMilesDetails()
  }, [])

  const saveEstimatedMile = async () => {
    let valid = await triggerValidation()
    if (!valid) return

    const estimated_miles = getValues()['estimated_mileage_value']
    let res = undefined
    res = await awaitWithLoading(
      insuranceApi.updateEstimatedMiles(details[current].insurance_id, details[current].vehicle_id, estimated_miles)
    )

    if (res && res.ok) history.push('/estimated-renewal/congrats')
    else EventEmitterService.dispatch(EventKey.ShowError, res)
  }

  const NextVehicle = async () => {
    let valid = await triggerValidation()
    if (!valid) return

    const estimated_miles = getValues()['estimated_mileage_value']
    let res = undefined
    res = await awaitWithLoading(
      insuranceApi.updateEstimatedMiles(details[current].insurance_id, details[current].vehicle_id, estimated_miles)
    )
    if (res && res.ok) {
      if (details && details.length > current + 1) {
        setCurrent(current + 1)
        setValue('estimated_mileage_value', 0)
      } else history.push('/estimated-renewal/congrats')
    } else EventEmitterService.dispatch(EventKey.ShowError, res)
  }

  return (
    <>
      <MobileView>
        <FlexView column className="priview-background odometer-success-main full-height" style={{ height: '100vh', width: '100vw' }}>
          <FlexView className="priview-background2" hAlignContent={'center'} vAlignContent={'center'}>
            <FlexView
              hAlignContent={'center'}
              style={{
                position: 'absolute',
                top: 10,
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <img src={`${process.env.PUBLIC_URL}/assets/voom_white.png`} style={{ height: 38 }} />
            </FlexView>
            <FlexView column vAlignContent={'center'} className="preview-content odometer-success-content">
              <Grow in={show} style={{ transformOrigin: '0 0 0' }} {...(show ? { timeout: 1000 } : {})}>
                <FlexView hAlignContent={'center'} column vAlignContent={'center'} className="preview-title-top">
                  <img src={`${process.env.PUBLIC_URL}/assets/clock.png`} />
                  <div className=" white textcenter margin-bottom-basic odometer-success-text">
                    Annual Mileage <br />
                  </div>
                  <div className="text-secondary white textcenter margin-bottom-basic">
                    Your reported mileage in the past year
                    <br /> for <u>{details && details.length > current ? details[current].vehicle_name : 'N/A'}</u>
                  </div>
                  <div className=" white textcenter margin-bottom-basic odometer-success-text">
                    {details && details.length > current ? details[current].reported_miles : 'N/A'} miles <br />
                  </div>
                  <div className="text-secondary white textcenter margin-bottom-basic" style={{ backgroundColor: '#1E3957' }}>
                    *We count only miles reported by uploading odometer photos
                  </div>
                  <Divider style={{ 'background-color': 'white', width: '100%' }} />

                  <div className="text-secondary white textcenter margin-bottom-basic bold">
                    Your annual estimation
                    <br />
                    for the upcoming year
                    <br />
                    for <u>{details && details.length > current ? details[current].vehicle_name : 'N/A'}</u>
                    <br />
                  </div>

                  {!!errors.estimated_mileage_value && <div className="text-small red">Required</div>}

                  <FlexView vAlignContent={'center'}>
                    <Controller
                      control={control}
                      name="estimated_mileage_value"
                      className="full-width"
                      rules={{ validate: value => value !== 0, required: true }}
                      defaultValue={0}
                      as={
                        <TextField
                          className="text-body  margin-bottom-basic odometer-success-text"
                          InputLabelProps={{ className: 'avenir-book' }}
                          error={!!errors.estimated_mileage_value}
                          variant="outlined"
                          fullWidth
                          select
                          style={{ width: '330px', textAlign: 'center', lineHeight: 'inherit' }}
                        >
                          <MenuItem disabled value={0}>
                            <div className="placeholder-text">Annual miles estimation</div>
                          </MenuItem>
                          {[500].concat(getEstimatedMilesOptions().estimatedMileageOption).map(val => (
                            <MenuItem value={val}>{`${val.toString()} annual miles`}</MenuItem>
                          ))}
                        </TextField>
                      }
                    />
                  </FlexView>
                  <br></br>
                  <Container>
                    <FlexView hAlignContent={'center'}>
                      <GreenButton onClick={saveEstimatedMile} disabled={details && details.length > current + 1} flat>
                        <div className="text-title white">{'Approve'}</div>
                      </GreenButton>
                      {details && details.length > current + 1 && (
                        <div style={{ marginLeft: '10px' }}>
                          <GreenButton onClick={NextVehicle} flat>
                            <div className="text-title white">{'Next vehicle'}</div>
                          </GreenButton>
                        </div>
                      )}
                    </FlexView>
                  </Container>
                </FlexView>
              </Grow>
            </FlexView>
          </FlexView>
        </FlexView>
      </MobileView>
      <BrowserView>
        <FlexView column vAlignContent={'center'} className="preview-content odometer-success-content">
          <Grow in={show} style={{ transformOrigin: '0 0 0' }} {...(show ? { timeout: 1000 } : {})}>
            <FlexView hAlignContent={'center'} column vAlignContent={'center'} className="preview-title-top">
              <img src={`${process.env.PUBLIC_URL}/assets/clock.png`} />
              <div className="  textcenter margin-bottom-basic odometer-success-text">
                Annual Mileage <br />
              </div>
              <div className="text-secondary textcenter margin-bottom-basic">
                Your reported mileage in the past year
                <br /> for <u>{details && details.length > current ? details[current].vehicle_name : 'N/A'}</u>
              </div>
              <div className="  textcenter margin-bottom-basic odometer-success-text">
                {details && details.length > current ? details[current].reported_miles : 'N/A'} miles <br />
              </div>
              <div className="text-secondary textcenter margin-bottom-basic purple-style text-small">
                *We count only miles reported by uploading odometer photos
              </div>
              <div className="text-secondary textcenter margin-bottom-basic ">
                Your annual estimation for the upcoming year
                <br />
                for <u>{details && details.length > current ? details[current].vehicle_name : 'N/A'}</u>
                <br />
              </div>

              {!!errors.estimated_mileage_value && <div className="text-small red">Required</div>}

              <FlexView vAlignContent={'center'}>
                <Controller
                  control={control}
                  name="estimated_mileage_value"
                  className="full-width"
                  rules={{ validate: x => x !== 0, required: true }}
                  defaultValue={0}
                  as={
                    <TextField
                      className="text-body  margin-bottom-basic odometer-success-text"
                      InputLabelProps={{ className: 'avenir-book' }}
                      error={!!errors.estimated_mileage_value}
                      variant="outlined"
                      fullWidth
                      select
                      style={{ width: '330px', textAlign: 'center', lineHeight: 'inherit' }}
                    >
                      <MenuItem disabled value={0}>
                        <div className="placeholder-text">Annual miles estimation</div>
                      </MenuItem>
                      {[500].concat(getEstimatedMilesOptions().estimatedMileageOption).map(val => (
                        <MenuItem value={val}>{`${val.toString()} annual miles`}</MenuItem>
                      ))}
                    </TextField>
                  }
                />
              </FlexView>
              <br></br>
              <Container>
                <FlexView hAlignContent={'center'}>
                  <GreenButton onClick={saveEstimatedMile} disabled={details && details.length > current + 1} flat>
                    <div className="text-title white">{'Approve'}</div>
                  </GreenButton>
                  {details && details.length > current + 1 && (
                    <div style={{ marginLeft: '10px' }}>
                      <GreenButton onClick={NextVehicle} flat>
                        <div className="text-title white">{'Next vehicle'}</div>
                      </GreenButton>
                    </div>
                  )}
                </FlexView>
              </Container>
            </FlexView>
          </Grow>
        </FlexView>
      </BrowserView>
    </>
  )
}
