export const InsuranceTypes = {
  MONTHLY: 'Monthly',
}

export const BillingPeriod = {
  MONTH: 'Month',
  THREEMONTHS: 'ThreeMonths',
  SIXMONTHS: 'SixMonths',
  ANNUAL: 'Annual',
}
