import React from 'react'
import { Transformation, Video } from 'cloudinary-react'
import { isMobile } from 'react-device-detect'
import './OdometerVideo.scss'

export const VideoApp = ({ autoplay }) => {
  if (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'demo') {
    autoplay = false
  }

  return (
    <Video
      autoplay={autoplay}
      loop
      muted
      className={!isMobile ? 'video-wrapper' : 'video-wrapper-mobile'}
      cloudName="dbfql827z"
      publicId={'Odometer-howTo/odometer_video_with_subtitles_ziotyv_zij0tc'} // publicId of version without subtitles: samples/OdometerVideo/VOOM_Product_Video_Final_oe1bbq
      format="mp4"
      controls
    >
      {isMobile ? (
        <Transformation height="320" width="320" quality="auto" />
      ) : (
        <Transformation quality="auto" fetchFormat="auto" crop="scale" />
      )}
    </Video>
  )
}

export default VideoApp
