import React from 'react'
import FlexView from 'react-flexview/lib'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

export const DesktopNotAllowed = () => {
  const location = useLocation()
  const params = queryString.parse(location.search)

  return (
    <FlexView column hAlignContent={'center'} vAlignContent={'center'}>
      <br />
      <br />
      <img className="margin-top-basic" src={process.env.PUBLIC_URL + '/assets/desktop_not_allowed.png'} style={{ height: 300 }} />
      <br />
      <br />
      {params.IsCancellationReq == 'False' ? (
        <>
          <div className="text-title black">
            {params.IsUpdateReq == 'False' ? 'Thanks for joining VOOM Pay Per Mile Motorcycle Insurance!' : 'Monthly mileage update'}
          </div>
          <div className="text-title black">
            To upload your odometer <span className="bold">please use your mobile phone.</span>
          </div>
        </>
      ) : (
        <>
          <div className="text-title black">Odometer update for policy cancellation</div>
          <div className="text-title black">
            {' '}
            <span className="bold">Please open this page through your mobile phone</span> to take a picture of your odometer
          </div>
        </>
      )}
    </FlexView>
  )
}
