import React from 'react'
import ThemeSwitcher from 'react-css-vars'
import BrokerConfigService from '../services/BrokerConfigService'
import { StylesProvider, ThemeProvider } from '@material-ui/core'
import { createSkywatchMaterialTheme } from '../styles/MaterialTheme'
import { SkywatchThemeContext, BASE_THEME } from './Theme'
import { isMobile } from 'react-device-detect'
import DataLayerService from '../services/DataLayerService'
import { reportGTM } from '../components/Core/Utilities/GTagManger'

export const SkywatchThemeSwitcher = props => {
  const [theme, setTheme] = React.useState({
    skyTheme: BrokerConfigService.getCachedThemeOrDefault(),
    materialTheme: createSkywatchMaterialTheme(BrokerConfigService.getDefaultTheme()),
  })
  var zendeskWidgetInterval = undefined

  React.useEffect(() => {
    loadTheme()
  }, [])

  const loadTheme = async () => {
    let remoteTheme = await BrokerConfigService.loadRemoteTheme()
    let skyTheme = Object.assign({}, BASE_THEME, remoteTheme)
    let materialTheme = createSkywatchMaterialTheme(skyTheme)
    let newTheme = { skyTheme: skyTheme, materialTheme: materialTheme }
    setTheme(newTheme)
    setZendeskColor(newTheme)
  }

  const overrideZendeskColors = (theme, zendeskIFrame, zendeskButton) => {
    if (zendeskIFrame) {
      zendeskButton.style = `background-color: ${theme.skyTheme.blue}
           !important; color: ${theme.materialTheme.secondary} !important;`
      var zendeskSvg = zendeskIFrame.contentWindow.document.getElementById('Layer_4')
      if (zendeskSvg && zendeskSvg.children)
        for (var i = 0; i < zendeskSvg.children.length; i++) {
          zendeskSvg.children[i].style = `fill: ${theme.materialTheme.secondary} !important`
        }
    }
  }

  const setZendeskColor = theme => {
    zendeskWidgetInterval = setInterval(() => {
      var zendeskIFrame = document.getElementById('launcher')
      if (zendeskIFrame) {
        // if(isMobile) zendeskIFrame.style = `display: none;`
        var zendeskButton = zendeskIFrame.contentWindow.document.getElementsByClassName('u-userLauncherColor')[0]
        if (zendeskButton) {
          overrideZendeskColors(theme, zendeskIFrame, zendeskButton)
          clearInterval(zendeskWidgetInterval)
          if (zendeskButton.addEventListener)
            zendeskButton.addEventListener('click', () => {
              reportGTM('', '', { event: 'zendesk-support-clicked', at: window.location.href })
            })
        }
      }
    }, 100)
  }

  return (
    <StylesProvider injectFirst>
      <ThemeSwitcher theme={theme.skyTheme}>
        <ThemeProvider theme={theme.materialTheme}>
          <SkywatchThemeContext.Provider value={theme.skyTheme}>{props.children}</SkywatchThemeContext.Provider>
        </ThemeProvider>
      </ThemeSwitcher>
    </StylesProvider>
  )
}
