import QuoteModel, { QuoteStateModel } from '../insurance-models/QuoteModel'

export default class UserProfileModel {
  firstName = ''
  lastName = ''
  operator = ''
  address1 = ''
  address2 = ''
  estimatedMilage = 'default'
  userId: ?Number = -1
  avgFlightScore: ?Number = undefined
  totalCoverageHours: ?Number = 0
  totalScoredFlights: ?Number = 0
  totalDiscount: ?Number = 1
  userRank = { title: 'Silver' }
  discountEligibility: Boolean
  email: String
  city = ''
  state = ''
  zipCode = ''
  dateOfBirth: Date = null
  creditBalance: Number = 0
  commercial = false
  hadInsurance: ?boolean = false
  company = ''
  hadInsuranceDescription: ?string = ''
  hasCreditCard = false
  profilePicture = ''
  phoneNumber = ''
  userVehicles = []
  userInsuredPersons = []
  affiliateCode: ?String = null
  hasPaymentMethod = false
  flightPurpose = 'default'
  latestQuote: ?QuoteModel = undefined
  latestState: ?QuoteStateModel = undefined
  status: ?any = null
  claimCount: Number = 0
  isActiveState: false
  source: String = ''
  declinations = []
  hasData() {
    return !!this.address1
  }

  static fullNameSplitter(fullName) {
    if (!fullName) {
      return ['', '']
    }
    let nameParts = fullName.split(' ')
    if (nameParts.length == 0) {
      return ['', '']
    }
    if (nameParts.length == 1) {
      return [nameParts[0], '']
    }
    return [nameParts[0], nameParts.slice(1, nameParts.length).join(' ')]
  }

  static fullNameCreator(firstName, lastName) {
    let trimedFirstName = firstName.trim()
    let trimedLastName = lastName.trim()
    if (trimedFirstName == '' && trimedLastName == '') {
      return ''
    }
    if (trimedFirstName == '') {
      return trimedLastName
    }
    if (trimedLastName == '') {
      return trimedFirstName
    }
    // trimedFirstName = trimedFirstName.chatAt(0).toUpperCase() + trimedFirstName.slice(1)
    return trimedFirstName + ' ' + trimedLastName
  }
}
