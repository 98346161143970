import { Grid } from '@material-ui/core'
import React from 'react'
import FlexView from 'react-flexview/lib'
import './StorageModeStyle.scss'
import { ReactComponent as ShieldSvg } from '../../assets/shield_black_purple.svg'
import DownloadAppWebBanner from '../DownloadAppBanner/StorageDownloadBanner/DownloadAppWebBanner'
import DownloadAppMobileBanner from '../DownloadAppBanner/StorageDownloadBanner/DownloadAppMobileBanner'
import DownloadBannerForMobile from '../DownloadAppBanner/DownloadBannerForMobile'
import { isMobile } from 'react-device-detect'

export const StorageModeCongrats = () => {
  const [bannerShow, setBannerShow] = React.useState(true)
  return (
    <React.Fragment>
      <div className="congrats_page">
        <Grid item container spacing={3} className="margin-top-large">
          <Grid xs={12} className="congrats_title margin-bottom-medium">
            <FlexView vAlignContent="center" hAlignContent="center">
              Storage Mode
            </FlexView>
          </Grid>
          <Grid xs={12} direction={'column'} className="textcenter">
            <FlexView className="margin-bottom-small" vAlignContent="center" hAlignContent="center">
              Thank you!
            </FlexView>
            <FlexView className="margin-bottom-small" vAlignContent="center" hAlignContent="center">
              Declaration form uploaded successfully, storage mode is currently live.
            </FlexView>
            <FlexView className="margin-bottom-small" vAlignContent="center" hAlignContent="center">
              We are here whenever needed support@voominsurance.com
            </FlexView>
          </Grid>
          <Grid xs={12}>
            <FlexView vAlignContent="center" hAlignContent="center" className="margin-top-medium">
              <ShieldSvg className="x-large-icon" />
            </FlexView>
          </Grid>
        </Grid>
        {bannerShow ? isMobile ? <DownloadAppMobileBanner /> : <DownloadAppWebBanner /> : <></>}
      </div>
    </React.Fragment>
  )
}
