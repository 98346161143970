import { InsuranceStatus } from './InsuranceStatus'
import { Operator } from './Operator'
import { InsuranceTypes } from './InsuranceBillingPeriodModel'
import { Vehicle } from '../motorcycles/Vehicle'
import { result } from 'lodash'

export class ActiveInsuranceModel {
  _certificateUrl: string
  policyUrl: string
  latestEndorsementUrl: string
  insuranceId: string
  summary: ?(string[])

  timeLeftSeconds: number
  autoRenew: boolean
  renewable: boolean
  policyNumber: number
  _liabilityLimit: number
  liabilityLimitPremium: number
  liabilityLimitDiscount: number
  locationDescription: string
  _insuranceEnd: Date
  _insuranceStart: Date
  perMileUntil: Date
  dateAdded: Date
  insuranceType: string
  finalPrice: number
  isSuspended: Boolean
  isSuspendable: Boolean
  isActivated: Beoolean
  suspensionStartTime: Date
  suspensionEndTime: Date
  operators: Operator[]
  insuredVehicles: Vehicle[]
  vehicleCoverages: any[]
  policyCoverages: any[]
  fixedPrice: number
  perMilePrice: number
  estimatedFinalPrice: number
  fees: number
  taxes: number
  taxesMultiplier: number
  policyStatus: string
  tenure: number
  billingPeriod: BillingPeriod

  get certificateUrl() {
    return this.hasLiabilityExtentions() ? this.liabilityLimitExtensions[0].certificateUrl : this._certificateUrl
  }

  set certificateUrl(val) {
    this._certificateUrl = val
  }

  get liabilityLimit() {
    return this.hasLiabilityExtentions() ? this.liabilityLimitExtensions[0].liabilityLimit : this._liabilityLimit
  }

  get totalLiabilityPremium() {
    return (
      this.liabilityLimitPremium +
      (this.hasLiabilityExtentions() ? this.liabilityLimitExtensions.reduce((res, ext) => res + ext.purchaseDetails.price, 0) : 0)
    )
  }

  set liabilityLimit(val) {
    this._liabilityLimit = val
  }

  set insuranceStart(val) {
    this._insuranceStart = val
  }

  get insuranceStart(): Date {
    return this._insuranceStart
    // return this.hasLiabilityExtentions() ? this.liabilityLimitExtensions[0].validFromUtc : this._insuranceStart
  }

  set insuranceEnd(val) {
    this._insuranceEnd = val
  }

  get insuranceEnd(): Date {
    return this._insuranceEnd
    // return this.hasLiabilityExtentions() ? this.liabilityLimitExtensions[0].validUntilUtc : this._insuranceEnd
  }

  isSubscription() {
    return this.insuranceType == InsuranceTypes.MONTHLY || this.insuranceType == InsuranceTypes.YEARLY
  }

  hasLiabilityExtentions() {
    return this.liabilityLimitExtensions && this.liabilityLimitExtensions.length > 0
  }

  isActive() {
    return this.policyStatus == InsuranceStatus.ACTIVE
  }

  isPending() {
    return this.policyStatus == InsuranceStatus.PENDING
  }

  isScheduled() {
    return this.policyStatus == InsuranceStatus.SCHEDULED && this.tenure == 0
  }

  isRenewal() {
    return this.policyStatus == InsuranceStatus.SCHEDULED && this.tenure > 0
  }

  isExpired() {
    return this.policyStatus == InsuranceStatus.EXPIRED
  }

  isCanceled() {
    return this.policyStatus == InsuranceStatus.CANCELED || this.policyStatus == InsuranceStatus.FAILED_ACTIVATION
  }

  isActiveOrSchedule() {
    return this.isActive() || this.isScheduled() || this.isSuspended
  }

  isActiveOrExpired() {
    return this.isActive() || this.isExpired()
  }

  isCanceledOrExpired() {
    return this.isExpired() || this.isCanceled()
  }

  hasPolicy() {
    return this.isActive() || this.isRenewal() || this.isCanceledOrExpired()
  }

  getStatus() {
    if (this.isActive()) return InsuranceStatus.ACTIVE
    else if (this.isPending()) return InsuranceStatus.PENDING
    else if (this.isScheduled()) return InsuranceStatus.SCHEDULED
    else if (this.isRenewal()) return InsuranceStatus.RENEWAL
    else if (this.isSuspended) return InsuranceStatus.SUSPENDED
    else if (this.isExpired()) return InsuranceStatus.EXPIRED
    else if (this.isCanceled()) return InsuranceStatus.CANCELED
    else return InsuranceStatus.ACTIVE
  }
}
