/* DataLayer is data that can be viewed from Google Tag Manager */

import { SessionService } from './SessionService'

class DataLayerService {
  userId = -1

  pushToDataLayer(data) {
    try {
      Object.assign(data, { user_id: this.userId })

      if (window.dataLayer) {
        window.dataLayer.push(data)
      }
    } catch (e) {}
  }
}

export default new DataLayerService()
