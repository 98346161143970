import React from 'react'
import './WhiteFlash.css'

export const WhiteFlash = ({ isShowWhiteFlash }) => {
  const flashDoTransition = isShowWhiteFlash ? 'do-transition' : ''
  const flashClasses = `${flashDoTransition} normal`

  return <div id="white-flash" className={flashClasses}></div>
}

export default WhiteFlash
