export class ApiResponseModel {
  ok
  status
  data
  parsedData

  constructor() {
    this.ok = false
    this.status = 0
    this.data = null
    this.parsedData = null
  }
}
