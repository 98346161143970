import React, { useEffect, useState, useRef } from 'react'

// for debugging with git cloned jslib-html5-camera-photo
// clone jslib-html5-camera-photo inside /src and replace
// from 'jslib-html5-camera-photo' -> from '../../../jslib-html5-camera-photo/src/lib';
import { FACING_MODES, IMAGE_TYPES } from 'jslib-html5-camera-photo'
import socketIOClient from 'socket.io-client'

import { useLibCameraPhoto } from './useLibCameraPhoto'

// import CircleButton from '../CircleButton';
// import WhiteFlash from '../WhiteFlash';
// import DisplayError from '../DisplayError';
import { getShowHideStyle, getVideoStyles, playClickAudio, dataURLtoBlob, blobToDataURL, printCameraInfo } from './helpers'
import './camera.css'
import { CircleButton } from './CircleButton'
import FlexView from 'react-flexview/lib'
import WhiteFlash from './WhiteFlash'
import { OdometerPermissionError } from '../OdometerFlow/OdometerPermissionError'

const Compress = require('client-compress')

let showVideoTimeoutId = null

const compress = new Compress({
  targetSize: 0.9,
  quality: 0.5,
  maxWidth: 800,
  maxHeight: 600,
})

const NewCamera = props => {
  const [dataUri, setDataUri] = useState('')
  const [isShowVideo, setIsShowVideo] = useState(true)
  const [cameraStartDisplayError, setCameraStartDisplayError] = useState('')
  const [showGreen, setShowGreen] = useState(false)
  const [prediction, setPrediction] = useState(0)

  let videoRef = useRef(null)

  const [mediaStream, cameraStartError, cameraStopError, getDataUri] = useLibCameraPhoto(
    videoRef,
    props.idealFacingMode,
    props.idealResolution,
    props.isMaxResolution
  )

  function clearShowVideoTimeout() {
    if (showVideoTimeoutId) {
      clearTimeout(showVideoTimeoutId)
    }
  }

  function handleTakePhoto() {
    const configDataUri = {
      sizeFactor: props.sizeFactor,
      imageType: props.imageType,
      imageCompression: props.imageCompression,
      isImageMirror: props.isImageMirror,
    }

    let dataUri = getDataUri(configDataUri)

    if (!props.isSilentMode) {
      playClickAudio()
    }

    if (typeof props.onTakePhoto === 'function') {
      props.onTakePhoto(dataUri)
    }

    setDataUri(dataUri)
    setIsShowVideo(false)

    clearShowVideoTimeout()
    showVideoTimeoutId = setTimeout(() => {
      setIsShowVideo(true)

      if (typeof props.onTakePhotoAnimationDone === 'function') {
        props.onTakePhotoAnimationDone(dataUri)
      }
    }, 500)
  }

  useEffect(() => {
    if (mediaStream) {
      if (typeof props.onCameraStart === 'function') {
        props.onCameraStart(mediaStream)
      }
    } else {
      if (typeof props.onCameraStop === 'function') {
        props.onCameraStop()
      }
    }
  }, [mediaStream])

  useEffect(() => {
    if (cameraStartError) {
      setCameraStartDisplayError(`${cameraStartError.name} ${cameraStartError.message}`)
      if (typeof props.onCameraError === 'function') {
        props.onCameraError(cameraStartError)
      }
    }
  }, [cameraStartError])

  useEffect(() => {
    if (cameraStopError) {
      printCameraInfo(cameraStopError.message)
    }
  }, [cameraStopError])

  useEffect(() => {
    const socket = socketIOClient(process.env.REACT_APP_AI_SERVER_URL)

    let interval = setInterval(() => {
      if (socket.disconnected) return

      let data = getDataUri({
        sizeFactor: 1,
        imageType: IMAGE_TYPES.JPG,
        imageCompression: 0.7,
        isImageMirror: false,
      })

      if (!data) return

      try {
        var b = dataURLtoBlob(data)

        socket.emit('image', b)
      } catch (e) {}

      // try {
      //   compress.compress([dataURLtoBlob(data)]).then((conversions) => {

      //     // console.log(conversions[0].photo.data)
      //     socket.emit('image', conversions[0].photo.data)

      //     // blobToDataURL(conversions[0].photo.data, (c) => {
      //     //   setDataUri(c)
      //     // })
      //   })
      // }
      // catch(e) {

      // }
    }, 1000)

    socket.on('response', e => {
      // Got prediction
      if (e.length > 0) {
        setPrediction(e[0])
        setShowGreen(true)
      }
    })

    socket.on('img_response', e => {
      setShowGreen(true)
      setDataUri('data:image/jpeg;base64,' + e)
    })

    socket.on('disconnect', () => {
      clearInterval(interval)
    })

    return function cleanup() {
      clearInterval(interval)
    }
  }, [])

  let videoStyles = getVideoStyles(isShowVideo, props.isImageMirror)
  let showHideImgStyle = getShowHideStyle(!isShowVideo)

  let classNameFullscreen = props.isFullscreen ? 'react-html5-camera-photo-fullscreen' : ''
  if (cameraStartDisplayError) return <OdometerPermissionError />
  return (
    <div className={'react-html5-camera-photo ' + classNameFullscreen}>
      {/* <DisplayError
        cssClass={'display-error'}
        isDisplayError={props.isDisplayStartCameraError}
        errorMsg={cameraStartDisplayError}
      />
       */}
      <WhiteFlash isShowWhiteFlash={!isShowVideo} />
      {/* <img
        style={showHideImgStyle}
        alt="camera"
        src={dataUri}
      /> */}
      {/* {prediction > 0 && <div className='pred' style={{position: 'absolute', margin: 20, fontSize:'1.5em', color: '#0ce0a3'}}>{prediction}</div>} */}
      <video style={videoStyles} ref={videoRef} autoPlay={true} muted="muted" playsInline />
      <FlexView hAlignContent={'center'} vAlignContent={'center'} id="container-circles" className="camera-buttons">
        <CircleButton showGreen={showGreen} onClick={handleTakePhoto} />
      </FlexView>
    </div>
  )
}

export default NewCamera
