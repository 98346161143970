import LocalizedStrings from 'react-localization'
import { FirebaseService, FirebaseConfigKey } from './FirebaseService'

class LocalizationService {
  STRINGS = new LocalizedStrings({
    US: {
      state: 'State',
      finalize: 'Finalize',
      customize: 'Customize',
      zipcode: 'Zip code',
      insuredpolicyandcoi: 'Insured Policy and COI',
      tax: 'Taxes',
      hullSectionTitle: 'Add Physical Damage Coverage (Hull)',
      hullSectionTitleMobile: 'Add Hull',
      hullSectionSubtitle: 'Protect your drone and equipment against physical damage, loss or theft',
      aircraftRegistrationCertificate: 'a photocopy of the UAV aircraft registration certificate issued by the FAA',
      part107description: 'a photocopy of your Part 107 certificate issued by the FAA',
    },
    Canada: {
      state: 'Province',
      finalize: 'Finalize',
      customize: 'Customize',
      zipcode: 'Postal Code',
      insuredpolicyandcoi: 'Insured Policy and COI',
      tax: 'Provincial Sales Tax',
      hullSectionTitle: 'Add Physical Damage Coverage (Hull)',
      hullSectionTitleMobile: 'Add Hull',
      hullSectionSubtitle: 'Protect your drone and equipment against physical damage, loss or theft',
      aircraftRegistrationCertificate: 'a photocopy of the UAV aircraft registration certificate issued by Transport Canada',
      part107description: 'a photocopy of your Pilot Certificate issued by Transport Canada',
    },
    UK: {
      state: 'Country',
      finalize: 'Finalise',
      customize: 'Customise',
      zipcode: 'Postal Code',
      insuredpolicyandcoi: 'Insured Policy',
      tax: 'Insurance Premium Tax',
      hullSectionTitle: 'Drones & Equipment',
      hullSectionTitleMobile: 'Drones & Equipment',
      hullSectionSubtitle: 'Add drones, non-detachable payload and spares',
      aircraftRegistrationCertificate: 'a photocopy of the UAV aircraft registration certificate issued by the FAA',
      part107description: 'a photocopy of your Part 107 certificate issued by the FAA',
    },
  })

  constructor() {
    this.setToCountry()
  }

  setToCountry() {
    this.STRINGS.setLanguage(FirebaseService.getValue(FirebaseConfigKey.COUNTRY))
  }
}

export default new LocalizationService()
