import DataLayerService from '../../../services/DataLayerService'

export const reportGTM = (location, buttonType = 'Continue', customEvent) => {
  let event = !customEvent ? { event: `${buttonType}-button-at-${location.pathname.split('/').slice(-1).pop()}` } : customEvent
  DataLayerService.pushToDataLayer(event)
}

export const enhancedCoversionEvent = (email, phone, event) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: event,
      email_payment: email,
      phone_payment: phone,
    })
  }
}
