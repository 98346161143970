export const PolicyCoveragesMapping = {
  bodily_injury_property_damage: {
    title: 'Liability (bodily injury, passenger liability & property damage)',
    shortTitle: 'BIPD',
    gtm: 'Liability-limit',
  },
  bodily_injury_liability: {
    title: 'Liability (bodily injury, property damage & passenger liability)',
    shortTitle: 'Liability',
    gtm: 'Liability-limit',
  },
  bodily_injury_underinsured: {
    title: 'Underinsured Motorist Bodily Injury',
    shortTitle: 'UIM',
    gtm: 'UIM-limit',
  },
  bodily_injury_uninsured: {
    title: 'Uninsured Motorist Bodily Injury',
    shortTitle: 'UM',
    gtm: 'UM-limit',
  },
  bodily_injury_uninsured_underinsured: {
    title: 'Uninsured/Underinsured Motorist Bodily Injury',
    shortTitle: 'UM/UIM',
    gtm: 'UM/UIM-limit',
  },
  personal_injury_protection: {
    title: 'Personal Injury Protection (PIP)',
    shortTitle: 'PED PIP',
    gtm: 'PIP-limit',
  },
  medical_payments: {
    title: 'Medical Payments',
    shortTitle: 'Medical Payments',
    gtm: 'Medpay-limit',
  },
  pedestrian_personal_injury_protection: {
    title: 'Pedestrian Personal Injury Protection (PED PIP)',
    shortTitle: 'PIP',
    gtm: 'PedPip-limit',
  },
  income_loss_benefits: {
    title: 'Income Loss Benefits',
    shortTitle: 'Income Loss Benefits',
    gtm: 'INCMLOS-limit',
  },
  uninsured_underinsured_motorist_property_damage: {
    title: 'Uninsured/Underinsured Property Damage',
    shortTitle: 'UM/UIM PD',
    gtm: 'UM/UIM-property-damage',
  },
  uninsured_motorist_property_damage: {
    title: 'UM Property Damage',
    shortTitle: 'UM PD',
    gtm: 'UM-motorist-limit',
  },
  bodily_injury_enhanced_underinsured: {
    title: 'Enhanced UIM Bodily Injury',
    shortTitle: 'EUIM',
    gtm: 'EUIM-motorist-limit',
  },
  enhanced_underinsured_motorist_property_damage: {
    title: 'Enhanced Underinsured Motorist Property Damage',
    shortTitle: 'EUIMPD',
    gtm: 'EUIMPD-limit',
  },
  roadside_assistance: {
    title: 'Roadside Assistance (Add-on Service)',
    shortTitle: 'Roadside Assistance (Add-on Service)',
    gtm: 'Roadside-Assistance',
  },
}
export const nonRefundableCoverages = ['roadside_assistance']

export const coveragesToIgnore = {
  passenger_liability: { ignoreInCoverageCard: false, ignoreInCustomize: true },
  mechanical_breakdown: { ignoreInCoverageCard: true, ignoreInCustomize: true },
  trailer: { ignoreInCoverageCard: true, ignoreInCustomize: true },
  volunteer_community_service: { ignoreInCoverageCard: true, ignoreInCustomize: true },
  physical_damage_optionsz: { ignoreInCoverageCard: true, ignoreInCustomize: true },
  funeral_expense: { ignoreInCoverageCard: true, ignoreInCustomize: true },
  property_damage: { ignoreInCoverageCard: false, ignoreInCustomize: true },
  towing_and_labor: { ignoreInCoverageCard: true, ignoreInCustomize: true },
  auto_theft_fee: { ignoreInCoverageCard: true, ignoreInCustomize: true },
  underinsured_motorist_property_damage: { ignoreInCoverageCard: true, ignoreInCustomize: true },
}

export const policyCoverageOrder = [
  'bodily_injury_property_damage',
  'passenger_liability',
  'bodily_injury_liability',
  'bodily_injury_underinsured',
  'bodily_injury_uninsured',
  'bodily_injury_uninsured_underinsured',
  'personal_injury_protection',
  'medical_payments',
  'pedestrian_personal_injury_protection',
  'bodily_injury_enhanced_underinsured',
]

export const policyCoverageThatUnderMotorcyclesOrder = [
  'uninsured_underinsured_motorist_property_damage',
  'uninsured_motorist_property_damage',
  'enhanced_underinsured_motorist_property_damage',
  'roadside_assistance',
  'income_loss_benefits',
]

export const MotorcyclesCoveragesMapping = {
  physical_damage_comprehensive: {
    title: 'Comprehensive',
    gtm: 'Comp-limit',
  },
  physical_damage_other_then_collision: {
    title: 'Comprehensive',
    gtm: 'Comp-limit',
  },
  physical_damage_collision: {
    title: 'Collision',
    gtm: 'Coll-limit',
  },
  uninsured_motorist_property_damage: {
    title: 'UM Property Damage',
    gtm: 'UM-motorist-limit',
  },
  pedestrian_first_party_benefits: {
    title: 'Pedestrian First Party Benefits',
    gtm: 'Pedestrian-first-party-benefits',
  },
  rental_reimbursement: {
    title: 'Transportation Expenses',
    shortTitle: 'Transportation Expenses',
    gtm: 'Rent-limit',
  },
}
