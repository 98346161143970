import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { SessionContextStore } from '../../../context/SessionContext'
import { awaitWithLoading } from '../../../services/utils'

export const EntranceProtectedRoute = ({ children, ...rest }) => {
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)

  const sessionContext = React.useContext(SessionContextStore)
  const [ready, setReady] = React.useState(false)
  const [params, setParams] = React.useState('')
  const [redirectUrl, setRedirectUrl] = React.useState('')

  const entries = Object.fromEntries(urlSearchParams.entries())

  const updateParamsPath = async () => {
    urlSearchParams.delete('profile')
    urlSearchParams.delete('deeplinkToken')
    const newParams = urlSearchParams.toString()
    setParams('?' + newParams)
  }

  React.useEffect(() => {
    const getDeeplinkToken = async () => {
      const redirectUrl = await awaitWithLoading(sessionContext.deeplinkTokenEntrance(entries.profile, entries.deeplinkToken))
      await updateParamsPath()
      setRedirectUrl(redirectUrl)
      setReady(true)
    }
    getDeeplinkToken()
  }, [])

  return !ready ? (
    <div></div>
  ) : (
    <Redirect
      push
      to={{
        pathname: redirectUrl,
        search: params,
      }}
    />
  )
}
