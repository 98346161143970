import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { Controller, FormContext, useForm } from 'react-hook-form'
import { ControlledSignature } from '../Core/Input/ControlledSignature'
import FlexView from 'react-flexview/lib'
import { ContinueButton } from '../Core/Buttons/ContinueButton'
import storageModeApi from '../../api/storageMode.api'
import { EventEmitterService, EventKey } from '../../services/EventEmitterService'
import { awaitWithLoading } from '../../services/utils'
import { isMobile } from 'react-device-detect'
import ReactHtmlParser from 'react-html-parser'
import { Button, Dialog } from '@material-ui/core'
import { reportGTM } from '../Core/Utilities/GTagManger'
var classNames = require('classnames')

export const StorageModeSigningForm = () => {
  const [pdf, setPdf] = useState('')
  const form = useForm({ mode: 'onBlur' })
  const history = useHistory()
  const location = useLocation()
  const { InsuranceId, VehicleId, RequestId, IsInStorageMode } = queryString.parse(location.search)

  const saveSigningForm = async data => {
    const res = await awaitWithLoading(
      storageModeApi.saveSignedForm({
        insurance_id: InsuranceId,
        odometer_request_id: RequestId,
        signature: data.signature,
        is_in_storage_mode: IsInStorageMode,
      })
    )

    if (res.ok) {
      if (IsInStorageMode === 'True') {
        reportGTM(location, '', { event: 'stormode_conti_form_signed' })
      } else {
        reportGTM(location, '', { event: 'stormode_ini_form_signed' })
      }

      history.push('/storage_mode/congrats')
    } else {
      EventEmitterService.dispatch(EventKey.ShowError, res)
    }
  }
  useEffect(() => {
    if (IsInStorageMode === 'True') {
      reportGTM(location, '', { event: 'stormode_conti_form_opened' })
    } else {
      reportGTM(location, '', { event: 'stormode_ini_form_opened' })
    }
  }, [])

  const getSigningForm = async () => {
    const res = await awaitWithLoading(
      storageModeApi.getSigningForm({
        insurance_id: InsuranceId,
        vehicle_id: VehicleId,
        is_in_storage_mode: IsInStorageMode,
      })
    )
    if (res.ok) {
      setPdf(res.data.output)
    } else {
      EventEmitterService.dispatch(EventKey.ShowError, res)
    }
  }

  useEffect(() => {
    getSigningForm()
  }, [])

  return (
    <React.Fragment>
      <Dialog open={true} fullWidth={true} fullScreen={true} className="white-background">
        <form onSubmit={form.handleSubmit(saveSigningForm)}>
          <div className="white-background">
            <div id="embedded-html">{ReactHtmlParser(pdf)}</div>
          </div>
          <FlexView vAlignContent="center" hAlignContent="center" className="margin-top-large" column={isMobile}>
            <ControlledSignature control={form.control} errors={form.errors} getValues={form.getValues} setValue={form.setValue} />
            <ContinueButton
              label="Complete"
              type="submit"
              className={classNames({ 'margin-top-medium': isMobile })}
              isGreenButton={false}
            />
          </FlexView>
        </form>
      </Dialog>
    </React.Fragment>
  )
}
